import axios from "axios";

export const settingsRouteApi = {
  getDisabledRoutes: (userId) =>
    axios.post("graphql/", {
      query: `query ($userId: Int) { disabledRoutes (userId: $userId) { routeName, id, pk} }`,
      variables: {
        userId
      }
    })
};
