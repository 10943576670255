<template>
  <div class="root redesign">
    <div class="d-flex flex-wrap align-center justify-space-between">
      <div class="mb-4 activity-header">
        <span class="mr-1 font-weight-medium text-subtitle-1 color--text-primary">{{
          $t("Activity")
        }}</span>
        <Btn
          v-for="(tabItem, tabIndex) in tabs"
          :key="tabIndex"
          class="activity-btn rounded-pill px-4 ml-3 color--text-primary text-subtitle-1"
          :variant="tab === tabIndex ? 'primary' : 'tertiary'"
          @click="tab = tabIndex"
        >
          {{ tabItem.label }}
        </Btn>
      </div>

      <div class="search-container d-flex align-center mb-4 flex-grow-1 justify-space-between">
        <v-text-field
          v-model="searchComments"
          class="search pt-0 mt-0 rounded-lg custom"
          prepend-inner-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          filled
          outlined
          hide-details
          clearable
        />

        <Btn
          v-if="hasUserRole(user, userRoles.Supervisor)"
          icon
        >
          <i
            class="fa fa-pencil"
            aria-hidden="true"
          ></i>
        </Btn>

        <OrderingButton
          :order="direction"
          :show-text="!isSm"
          class="ml-md-6 ml-3"
          @order="sort"
        />
      </div>
    </div>

    <p class="text-body-1 color--text-secondary mb-2">{{ $t("Comment") }}</p>
    <div class="mb-4">
      <TextAreaControl
        class="activity-textarea"
        :files="[file]"
        :accept="uploadFormats"
        :value="comment"
        @change-files="handleChangeFiles"
        @input="handleNewCommentChange"
      />

      <div
        v-if="comment !== ''"
        class="d-flex align-center mt-3"
      >
        <Btn
          large
          variant="primary"
          width="150"
          class="mr-3 comment-btn"
          :loading="savingComment"
          :disabled="comment == ''"
          @click="submitComment"
        >
          {{ $t("Add") }}
        </Btn>

        <Btn
          large
          variant="secondary"
          width="150"
          class="comment-btn"
          :disabled="comment == ''"
          @click="clearComment"
        >
          {{ $t("Cancel") }}
        </Btn>
      </div>

      <div
        v-if="errors && errors.length"
        class="mt-1 mb-4"
      >
        <p
          v-for="error in errors"
          :key="error"
          class="error--text"
        >
          {{ error }}
        </p>
      </div>
    </div>

    <v-data-iterator
      :items="activityPerTab"
      :no-data-text="$t('No data')"
      :items-per-page="itemsPerPage"
      :loading="commentsLoading"
      class="pa-0"
      :hide-default-footer="activityPerTab.length <= itemsPerPage"
    >
      <template #default="{ items }">
        <v-progress-linear
          v-if="commentsLoading"
          indeterminate
        />
        <div
          v-for="(item, index) in items"
          :key="`${index}_${item.type}_${item.date}`"
          class="mb-4"
        >
          <TicketDetailsComment
            v-if="showComments && item.type === ticketActivityType.COMMENT"
            :item="item"
          />
          <TicketDetailsHistory
            v-if="showHistory && item.type === ticketActivityType.HISTORY"
            :item="item"
          />
        </div>
      </template>
      <template #noData>
        {{ $t("No data") }}
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
import moment from "moment";
import sortBy from "lodash/sortBy";
import { mapGetters } from "vuex";

import Btn from "@/ui/Btn";
import OrderingButton from "@/ui/OrderingButton";
import TicketDetailsHistory from "./TicketDetailsHistory.vue";
import TicketDetailsComment from "./TicketDetailsComment.vue";
import { Dispatcher } from "@/utils/eventbus";
import { hasUserRole, userRoles } from "@/utils/user";
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { useTicketWorkFlowConfig } from "../useTicketWorkFlowConfig";
import {
  MACHINE_DELAY_BETWEEN_UPDATE_AND_GET,
  MACHINE_STATUS_CHANGE_EVENT
} from "@/features/machine/constants";
import { UPLOAD_IMAGE_VIDEO_FORMATS } from "@/features/constants";
import { TICKET_ACTIVITY_TYPE, ACTIVITY_TABS } from "../constants";
import TextAreaControl from "@/ui/TextAreaControl/TextAreaControl.vue";

export default {
  name: "TicketDetailsActivity",
  components: {
    TextAreaControl,
    Btn,
    OrderingButton,
    TicketDetailsHistory,
    TicketDetailsComment
  },
  props: {
    filters: {
      type: Object,
      default: undefined
    },
    newCommentParams: {
      type: Object,
      default: undefined
    },
    special: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: false
    },
    tabs: {
      type: Array,
      default: () => []
    },
    ticket: {
      type: Object,
      default: () => {}
    }
  },
  setup() {
    const { isSm } = useDeviceResolution();
    const { getHistoryItems } = useTicketWorkFlowConfig();

    return {
      isSm,
      getHistoryItems
    };
  },
  data() {
    return {
      file: null,
      imageBase64: null,
      video: null,
      comments: [],
      searchComments: "",
      comment: "",
      newCommentSpecial: false,
      savingComment: false,
      commentsLoading: false,
      direction: "DESC",
      tab: ACTIVITY_TABS.ALL,
      userRoles,
      errors: [],
      uploadFormats: UPLOAD_IMAGE_VIDEO_FORMATS,
      ticketActivityType: TICKET_ACTIVITY_TYPE,
      history: [],
      historyFromTicket: [],
      itemsPerPage: 5
    };
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    isDescDirection() {
      return this.direction === "DESC";
    },
    activityPerTab() {
      if (this.tab === ACTIVITY_TABS.ALL) {
        const history = this.history.filter((item) => {
          const text = item.action || item.body;
          return text.includes(this.searchComments);
        });
        let resultingComments = sortBy([...this.comments, ...history], (item) =>
          moment(item.date).valueOf()
        );
        if (this.direction === "DESC") {
          resultingComments = resultingComments.reverse();
        }
        return resultingComments;
      } else if (this.tab === ACTIVITY_TABS.HISTORY) {
        let history = sortBy(this.history, (item) => moment(item.date).valueOf());
        if (this.direction === "DESC") {
          history = history.reverse();
        }
        return history;
      }
      let comments = sortBy(this.comments, (item) => moment(item.date).valueOf());
      if (this.direction === "DESC") {
        comments = comments.reverse();
      }
      return comments;
    },
    showComments() {
      return this.tab === ACTIVITY_TABS.ALL || this.tab === ACTIVITY_TABS.COMMENTS;
    },
    showHistory() {
      return this.tab === ACTIVITY_TABS.ALL || this.tab === ACTIVITY_TABS.HISTORY;
    }
  },
  watch: {
    ticket(value) {
      this.historyFromTicket = this.getHistoryItems({ value }).value;
      this.loadComments();
    },
    reload: function () {
      let loadComments = this.loadComments;
      setTimeout(loadComments, 500);
    },
    filters: {
      deep: true,
      handler: function () {
        this.loadComments();
      }
    },
    direction: function () {
      this.loadComments();
    },
    searchComments: function () {
      if (this.searchDebouncerComments) {
        clearTimeout(this.searchDebouncerComments);
      }
      let load = this.loadComments;
      this.searchDebouncerComments = setTimeout(load, 500);
    },
    video: function (newVal) {
      /*
        The server limit is 20 MB, but base64 encoding increases the file size by up to 33%.
        Therefore, we do not allow users to upload files larger than 15 MB
        (as stated in the error message).
      */
      if (newVal) {
        if (newVal.length > 20 * 1024 * 1024) {
          this.setFileSizeError();
          this.video = null;
        } else {
          this.errors = [];
        }
      }
    }
  },
  created() {
    /* hard fix delay between submit request and date update */
    Dispatcher.$listen(MACHINE_STATUS_CHANGE_EVENT, () =>
      setTimeout(() => this.loadComments(), MACHINE_DELAY_BETWEEN_UPDATE_AND_GET)
    );
  },
  methods: {
    moment,
    hasUserRole,
    handleNewCommentChange(commentText) {
      this.comment = commentText;
    },
    handleChangeFiles(files) {
      if (files[0]) {
        this.selectFile(files[0]);
      } else {
        this.file = null;
        this.imageBase64 = null;
      }
    },
    selectFile(file) {
      this.file = file;
      if (!file) {
        console.error("No file selected.");
        return;
      }

      if (file) {
        const reader = new FileReader();
        reader.onload = () => {
          if (file.type.startsWith("image")) {
            this.imageBase64 = reader.result;
          } else if (file.type.startsWith("video")) {
            this.video = reader.result;
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
        reader.readAsDataURL(file);
      }
    },
    loadComments() {
      const query = `query (
        $filters: GrapheneElasticFilterCommentSearchConnectionBackendFilter!,
        $direction: Direction!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String,
        $search: String){
        comments(
          filter: $filters,
          ordering: {date: $direction},
          after: $after,
          before: $before,
          first: $first,
          last: $last,
          simpleQueryString: $search,
          facets: [user]){
          facets,
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          },
          edges{
            cursor,
            node{
              special,
              userName,
              user,
              date,
              body,
              id,
              hidden
              imageUrl,
              videoUrl,
              type
            }
          }
        }
      }`;
      //TODO switch filter to use globalid instead of PK
      // Check creation too
      let filters = {
        hidden: { exclude: [true] }
      };
      const variables = {
        filters: _.merge(filters, this.filters),
        direction: this.direction,
        search: ""
      };

      if (this.searchComments) {
        variables["search"] = this.searchComments + "*";
      }

      this.comments = [];
      this.commentsLoading = true;
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          const items = res.data.data.comments.edges.map((item) => ({
            time: item.node.date,
            ...item.node
          }));
          const comments = items.filter((item) => item.type === TICKET_ACTIVITY_TYPE.COMMENT);
          const history = items.filter((item) => item.type === TICKET_ACTIVITY_TYPE.HISTORY);
          this.comments = comments;
          this.history = [...this.historyFromTicket, ...history];
        })
        .catch((res) => {
          this.errors = res.errors;
        })
        .finally(() => {
          this.commentsLoading = false;
        });
    },
    clearComment() {
      this.comment = "";
      this.imageBase64 = false;
      this.newCommentSpecial = false;
      this.video = null;
      this.file = null;
      this.errors = [];
    },
    submitComment() {
      this.errors = [];
      this.savingComment = true;
      const query = `mutation ($commentData: CommentInput!){
        createComment(commentData:$commentData) {
          id,
          body,
          image,
          video
        }
      }`;
      let variables = { commentData: _.clone(this.newCommentParams) };
      variables["commentData"]["body"] = this.comment;
      if (this.imageBase64) {
        variables["commentData"]["image"] = this.imageBase64;
      }
      if (this.video) {
        variables["commentData"]["video"] = this.video;
      }
      if (this.newCommentSpecial) {
        variables["commentData"]["special"] = true;
      }
      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          var context = this;
          this.clearComment();
          setTimeout(() => {
            context.savingComment = false;
            //this emitted$ value is not used by all comment feeds (some have websocket updates)
            this.loadComments();
            context.$emit("commentSaved");
          }, 500);
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.setFileSizeError();
          } else {
            this.errors = [this.$t("Something goes wrong. Please retry later.")];
          }
          this.savingComment = false;
        })
        .finally(() => {
          this.savingComment = false;
        });
    },
    setFileSizeError() {
      this.errors = [this.$t("The attached file should not exceed 15 MB.")];
    },
    sort() {
      this.direction = this.isDescDirection ? "ASC" : "DESC";
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/input";

.root {
  max-height: 100%;
  height: 100%;

  ::v-deep .v-data-footer {
    display: flex;
    justify-content: flex-end;
  }
}

.activity-btn {
  min-width: 0 !important;
}

.activity-header {
  flex-grow: 9999;

  ::v-deep .v-btn__content {
    text-transform: none;
    font-weight: 400 !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    flex-grow: 0;
  }
}

.activity-textarea {
  ::v-deep {
    textarea {
      line-height: 36px;
    }

    .v-input__slot {
      padding-top: 0 !important;
    }

    .v-input__append-inner {
      margin-top: 8px !important;
    }
  }
}

.search {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    max-width: 260px;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .comment-btn {
    flex-grow: 1;
  }
}
</style>

<style>
.theme--light .root .v-data-footer {
  background: #f4f4f4;
}
</style>
