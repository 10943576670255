<template>
  <div class="mt-4 mt-sm-5 mb-4">
    <slot name="topSearch"></slot>

    <div class="d-flex align-end justify-space-between">
      <div
        v-if="onInputSearch && isSm"
        class="mb-2"
      >
        <SearchField :on-input-search="onInputSearch" />
      </div>

      <div v-if="!$slots.leftNavigation">
        <btn-toggle :value="activeTab">
          <Btn
            text
            class="text-uppercase text-button type-button"
            :value="MACHINE_TABS.GRID"
            @click="onClickTab(MACHINE_TABS.GRID)"
          >
            {{ $t("Grid") }}
            <v-icon
              size="20"
              class="ml-1 font-weight-bold type-button-icon"
              >mdi-table</v-icon
            >
          </Btn>
          <Btn
            text
            class="text-uppercase text-button type-button"
            :value="MACHINE_TABS.CHARTS"
            @click="onClickTab(MACHINE_TABS.CHARTS)"
          >
            {{ $t("Chart") }}
            <v-icon
              size="20"
              class="ml-1 font-weight-bold"
              >mdi-chart-line</v-icon
            >
          </Btn>
        </btn-toggle>
      </div>

      <div
        v-else
        class="d-flex align-end leftNavigation flex-grow-1 justify-space-between"
      >
        <slot name="leftNavigation"></slot>
      </div>

      <div
        v-if="onInputSearch"
        class="ml-6 mt-2"
      >
        <SearchField
          v-if="!isSm"
          :on-input-search="onInputSearch"
        />
        <Btn
          v-if="isSm"
          large
          :icon="isSm"
          :class="{ 'rounded-lg': isSm }"
        >
          <v-icon
            size="20"
            class="font-weight-bold"
            >mdi-magnify</v-icon
          >
        </Btn>
      </div>

      <div class="d-flex align-center gap-2 leftBlok ml-3 ml-sm-0">
        <slot name="leftBlock"></slot>

        <btn
          v-if="onClickUpdate"
          class="mr-3"
          large
          @click="onClickUpdate"
        >
          <v-icon
            size="20"
            class="mr-1"
            >mdi-update</v-icon
          >{{ $t("Update") }}</btn
        >
        <btn
          v-if="onClickDownload"
          color="primary"
          class="mr-3 d-none d-sm-block"
          large
          @click="onClickDownload"
        >
          <v-icon
            size="20"
            class="mr-1"
            >mdi-file-download-outline</v-icon
          >
          {{ $t("CSV") }}
        </btn>

        <v-badge
          v-if="onClickFilters"
          :content="activeFiltersLength"
          :value="activeFiltersLength"
          overlap
        >
          <btn
            variant="secondary"
            large
            :icon="isSm"
            :class="{ 'rounded-lg': isSm }"
            @click="onClickFilters"
          >
            <v-icon
              size="20"
              class="filter-icon"
              >mdi-filter-variant</v-icon
            >
            <span
              v-if="!isSm"
              class="font-weight-bold ml-sm-1"
              >{{ $t("Filters") }}</span
            >
          </btn>
          <template #badge>
            <span class="badge font-weight-medium color--text-contrast">{{
              activeFiltersLength
            }}</span>
          </template>
        </v-badge>

        <slot name="rightBlock"></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import Btn from "@/ui/Btn/Btn.vue";
import BtnToggle from "@/components/BtnToggle.vue";
import SearchField from "@/ui/Search/SearchField.vue";
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { MACHINE_TABS } from "@/features/machine/constants";

defineProps({
  onClickFilters: { type: Function, default: undefined },
  onClickDownload: { type: Function, default: undefined },
  onClickUpdate: { type: Function, default: undefined },
  onClickTab: { type: Function, default: undefined },
  onInputSearch: { type: Function, default: undefined },
  activeFiltersLength: { type: Number, default: undefined },
  activeTab: { type: String, default: "" }
});

const { isSm } = useDeviceResolution();

defineExpose({
  isSm,
  MACHINE_TABS
});
</script>

<style lang="scss" scoped>
::v-deep {
  .search {
    width: 300px;
  }
}

.badge {
  color: var(--gray-800);
}

.type-button {
  border-bottom: 2px solid transparent;
  color: var(--text-secondary);

  ::v-deep {
    span {
      font-weight: 500;
    }
  }
}

.type-button-icon {
  color: inherit;
}

.type-button-active {
  border-bottom-color: var(--btn-primary);
  color: var(--btn-primary);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .filter-icon {
    font-size: 24px !important;
  }
}
</style>
