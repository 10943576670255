import { AppRoutes } from "@/shared/app-routes";
import Vue from "vue";

export const ticketsMachineRoutes = [
  {
    name: AppRoutes.machineTickets.name,
    path: AppRoutes.machineTickets.url(),
    redirect: () => ({ name: AppRoutes.machineTicketsP911.name }),
    props: true,
    displayName: false
  },

  {
    name: AppRoutes.machineTicketsP911.name,
    path: AppRoutes.machineTicketsP911.url(),
    component: Vue.component(
      AppRoutes.machineTicketsP911.name,
      () => import("./TicketsMachineP911.vue")
    ),
    props: true,
    displayName: false
  },

  {
    name: AppRoutes.machineTicketsLdms.name,
    path: AppRoutes.machineTicketsLdms.url(),
    component: Vue.component(
      AppRoutes.machineTicketsLdms.name,
      () => import("./TicketsMachineLdms.vue")
    ),
    props: true,
    displayName: false
  },

  {
    name: AppRoutes.machineTicketsKpi.name,
    path: AppRoutes.machineTicketsKpi.url(),
    component: Vue.component(
      AppRoutes.machineTicketsKpi.name,
      () => import("./TicketsMachineKpi.vue")
    ),
    props: true,
    displayName: false
  }
];
