<template>
  <div class="realtime_charts">
    <v-btn
      fab
      x-small
      light
      @click="showSearch = !showSearch"
      class="hideDesktop"
    >
      <i
        class="fa fa-filter"
        v-if="!showSearch"
      ></i>
      <i
        class="fa fa-times"
        v-if="showSearch"
      ></i>
    </v-btn>
    <div class="row">
      <div
        class="searchbars"
        v-if="vw > 1200 || showSearch"
      >
        <v-autocomplete
          :placeholder="$t('Zone')"
          :items="Array.from(zoneSet)"
          @change="
            (e) => {
              setFilter('zone', e);
            }
          "
          :value="filters.zone"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <div
          class="clearbutton"
          @click="
            () => {
              setFilter('zone', null);
            }
          "
        >
          <i
            class="fa fa-remove"
            v-if="filters.zone != null"
          />
        </div>
        <v-autocomplete
          :placeholder="$t('Machine')"
          :items="Array.from(machineSet)"
          @change="
            (e) => {
              setFilter('machine', e);
            }
          "
          :value="filters.machine"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <div
          class="clearbutton"
          @click="
            () => {
              setFilter('machine', null);
            }
          "
        >
          <i
            class="fa fa-remove"
            v-if="filters.machine != null"
          />
        </div>
        <v-autocomplete
          :placeholder="$t('Part')"
          :items="Array.from(partSet)"
          @change="
            (e) => {
              setFilter('part', e);
            }
          "
          :value="filters.part"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <div
          class="clearbutton"
          @click="
            () => {
              setFilter('part', null);
            }
          "
        >
          <i
            class="fa fa-remove"
            v-if="filters.part != null"
          />
        </div>
        <v-autocomplete
          :placeholder="$t('Operator')"
          :items="Array.from(operatorSet)"
          @change="
            (e) => {
              setFilter('name', e);
            }
          "
          :value="filters.name"
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <div
          class="clearbutton"
          @click="
            () => {
              setFilter('name', null);
            }
          "
        >
          <i
            class="fa fa-remove"
            v-if="filters.name != null"
          />
        </div>
        <v-autocomplete
          :placeholder="$t('Operator Status')"
          :items="Array.from(operatorStatusSet)"
          @change="
            (e) => {
              setFilter('checked_in', e == null ? null : e == 'Checked-In');
            }
          "
          :value="
            filters.checked_in == null ? null : filters.checked_in ? 'Checked-In' : 'Checked-Out'
          "
          :menu-props="{
            closeOnClick: true,
            closeOnContentClick: true
          }"
        />
        <div
          class="clearbutton"
          @click="
            () => {
              setFilter('checked_in', null);
            }
          "
        >
          <i
            class="fa fa-remove"
            v-if="filters.checked_in != null"
          />
        </div>
      </div>
      <div class="box">
        <v-tabs
          @change="ChangeCalendar"
          fixed-tabs
          :value="calendarCase"
        >
          <v-tab>{{ $t("Shift") }}</v-tab>
          <v-tab>{{ $t("Day") }}</v-tab>
          <v-tab>{{ $t("Week") }}</v-tab>
          <v-tab>{{ $t("Month") }}</v-tab>
          <v-tab>{{ $t("Year") }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <div class="gridPadding chartGrid">
      <PaginatedChart
        class="chartCard card"
        :chartOptions="[
          {
            color: '#00b1f1',
            keys: ['target_units'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: '#ffd965',
            keys: ['actual'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="name"
        sortOn="actual"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItems"
        :useNumberRecordsPicker="true"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('Operators')"
      />
      <PaginatedChart
        class="chartCard card"
        :chartOptions="[
          {
            color: '#00b04f',
            keys: ['event_duration'],
            label: this.$t('Production Time'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="FormatTime"
        label_name="name"
        sortOn="event_duration"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItems"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('Operators - Production Time')"
      />
      <PaginatedChart
        class="chartCard card"
        :chartOptions="[
          {
            color: '#00b1f1',
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: '#2e5496',
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="part_number"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsPartNumbers"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="'Part Numbers'"
      />
      <PaginatedChart
        class="chartCard card"
        :chartOptions="[
          {
            color: '#00b1f1',
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: '#ffd965',
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="machine_name"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsMachines"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        title="Machines"
      />
      <PaginatedChart
        class="chartCard card"
        :chartOptions="[
          {
            color: '#00b1f1',
            keys: ['production_target'],
            label: this.$t('Target'),
            type: 'scatter',
            stack: 'combined'
          },
          {
            color: '#b01536',
            keys: ['produced'],
            label: this.$t('Produced'),
            type: 'bar',
            stack: 'combined'
          }
        ]"
        :DataFormatter="Shorten"
        label_name="zone"
        sortOn="produced"
        :numberRecordsPerPage="numberRecordsPerPage"
        :numberRecords="numberRecords"
        :filteredItems="filteredItemsZone"
        :useNumberRecordsPicker="false"
        :ChangeNumberRecords="ChangeNumberRecords"
        :title="$t('By Zone')"
      />
    </div>
  </div>
</template>
<script>
import moment from "moment-timezone";
import { mapGetters } from "vuex";

import { shorten } from "@/utils/filters";

import BarChart from "@/components/charts/BarChart";
import { FormatTime } from "../../../utils/timeFormat";
import PaginatedChart from "./PaginatedChart";

export default {
  components: { PaginatedChart, BarChart },
  props: [
    "setCalendarCase",
    "calendarCase",
    "shiftStart",
    "shiftEnd",
    "fromDate",
    "toDate",
    "setFromDate",
    "setToDate",
    "now",
    "numberRecords",
    "setNumberRecords",
    "items"
  ],
  data() {
    return {
      recordRange: { start: 0, end: 0 },
      numberRecordsPerPage: 25,
      filteredItems: [],
      filteredItemsPartNumbers: [],
      filteredItemsMachines: [],
      filteredItemsZone: [],
      filters: {},
      zoneSet: new Set(),
      machineSet: new Set(),
      partSet: new Set(),
      operatorSet: new Set(),
      operatorStatusSet: new Set(),
      topOperators: {},
      slicedTopOperators: {},
      FormatTime: FormatTime,
      Shorten: shorten,
      showSearch: false
    };
  },
  created() {
    this.createDropdownElements();
    this.filterItems();
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  methods: {
    setFilter(filter, e) {
      if (e != null) {
        this.filters[filter] = e;
      } else {
        delete this.filters[filter];
      }
      this.filterItems();
    },
    ChangeNumberRecords(e) {
      const index = { 0: 10, 1: 25, 2: 50, 3: null };
      this.setNumberRecords(index[e]);

      let start = this.recordRange.start;
      let end = this.recordRange.start - 1 + this.numberRecordsPerPage;

      if (index[e] != null && end >= index[e] - 1) {
        end = index[e] - 1;
        start = Math.max(index[e] - this.numberRecordsPerPage, 0);
      }

      if (index[e] == null && end > this.filteredItems.length - 1) {
        end = this.filteredItems.length - 1;
      }

      this.recordRange = { start: start, end: end };
    },
    ChangeCalendar(e) {
      this.topOperators = {};
      this.setCalendarCase(e);
      switch (e) {
        case 0:
          if (this.shiftStart != null && this.shiftEnd != null) {
            this.setFromDate(moment(this.shiftStart));
            this.setToDate(moment(this.shiftEnd));
          }
          break;
        case 1:
          this.setFromDate(moment().startOf("day"));
          this.setToDate(moment().endOf("day"));
          break;
        case 2:
          this.setFromDate(moment().startOf("week"));
          this.setToDate(moment().endOf("week"));
          break;
        case 3:
          this.setFromDate(moment().startOf("month"));
          this.setToDate(moment().endOf("month"));
          break;
        case 4:
          this.setFromDate(moment().startOf("year"));
          this.setToDate(moment().endOf("year"));
          break;
      }
    },
    filterItems() {
      const keys = Object.keys(this.filters);
      const filteredOut = new Set();
      this.items.forEach((item) => {
        for (const k of keys) {
          if (item[k] != this.filters[k]) {
            filteredOut.add(item);
          }
        }
      });

      this.filteredItems = this.items.filter((item) => {
        return !filteredOut.has(item);
      });

      const filteredItemsPartNumbersMap = new Map();
      const filteredItemsMachinesMap = new Map();
      const filteredItemsZoneMap = new Map();

      this.filteredItems.forEach((operator) => {
        operator.machines.forEach((machine) => {
          const part_number = machine.last_record.part_number;
          const produced = machine.produced;
          const production_target = machine.production_target;

          const machine_id = machine.last_record.machine_id;
          const machine_name = machine.last_record.machine_name;
          const zone = machine.last_record.zone;

          if (filteredItemsPartNumbersMap.has(part_number)) {
            filteredItemsPartNumbersMap.get(part_number).produced += produced;
            filteredItemsPartNumbersMap.get(part_number).production_target += production_target;
          } else {
            filteredItemsPartNumbersMap.set(part_number, {
              produced: produced,
              part_number: part_number,
              production_target: production_target
            });
          }

          if (filteredItemsMachinesMap.has(machine_id)) {
            filteredItemsMachinesMap.get(machine_id).produced += produced;
            filteredItemsMachinesMap.get(machine_id).production_target += production_target;
          } else {
            filteredItemsMachinesMap.set(machine_id, {
              produced: produced,
              machine_name: machine_name,
              production_target: production_target
            });
          }

          if (filteredItemsZoneMap.has(zone)) {
            filteredItemsZoneMap.get(zone).produced += produced;
            filteredItemsZoneMap.get(zone).production_target += production_target;
          } else {
            filteredItemsZoneMap.set(zone, {
              produced: produced,
              zone: zone,
              production_target: production_target
            });
          }
        });
      });

      this.filteredItemsPartNumbers = [];
      this.filteredItemsMachines = [];
      this.filteredItemsZone = [];

      filteredItemsPartNumbersMap.forEach((value, key) => {
        this.filteredItemsPartNumbers.push(value);
      });

      filteredItemsMachinesMap.forEach((value, key) => {
        this.filteredItemsMachines.push(value);
      });

      filteredItemsZoneMap.forEach((value, key) => {
        this.filteredItemsZone.push(value);
      });
    },
    createDropdownElements() {
      if (this.items == null) {
        return;
      }

      for (const item of this.items) {
        this.zoneSet.add(item.zone);
        this.machineSet.add(item.machine);
        this.partSet.add(item.part);
        this.operatorSet.add(item.name);
        this.operatorStatusSet.add(item.checked_in ? "Checked-In" : "Checked-Out");
      }
    }
  },
  watch: {
    items: function () {
      this.createDropdownElements();
      this.filterItems();
    }
  }
};
</script>
<style lang="scss">
@import "../../../scss/variables";

.realtime_charts {
  display: flex;
  flex-flow: column;
  overflow: hidden;
  .row {
    display: flex;
  }
  .v-btn {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    i {
      font-size: 1rem;
    }
  }
  .chartGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    overflow: auto;
    padding-bottom: 50px;
    margin-top: 1rem;
    &.gridPadding {
      padding: 10px;
    }
    @media screen and (max-width: 1200px) {
      &.gridPadding {
        position: relative;
        padding-top: 50px;
      }
    }
  }
  .chartCard {
    min-height: unset;
    padding: 1rem;
    padding-top: 0;
  }
  .paginatedChart {
    min-width: 0;
    &:first-child {
      grid-column: 1 / 3;
    }
    @media screen and (max-width: 1200px) {
      grid-column: 1 / 3;
      .leftPicker {
        .v-tabs {
          top: -55px;
          display: flex;
          justify-content: center;
          width: 100%;
        }
      }
    }
  }

  .chart {
    height: 300px;

    h3 {
      padding: 15px 0;
    }

    .bar_chart {
      height: 250px !important;
    }
  }

  .searchbars {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    padding-top: 10px;
    padding-left: 20px;
    height: 60px;
    flex: unset;
    @media screen and (max-width: 1200px) {
      flex-wrap: wrap;
      background-color: $cardColor;
      box-shadow: $cardBoxShadow;
      height: unset;
      margin: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      position: absolute;
      top: 3rem;
      left: 1rem;
      right: 1rem;
      z-index: 9999;
      .v-input {
        width: 100%;
      }
    }
    .clearbutton {
      width: 35px;
      flex: unset;
      font-weight: lighter;
    }
  }
  @media screen and (max-width: 1200px) {
    .box {
      width: 100%;
      z-index: 999;
      background-color: $background;
      .v-tabs {
        width: 100%;
        .v-tab {
          font-size: 12px;
          min-width: unset;
        }
      }
    }
  }
}
</style>
