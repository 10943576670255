import axios from "axios";

export const MACHINE_API_GET_MACHINE_QUERY = (id) => `
{
  machines(id: ${id}) {
    name,
    availabilityTarget,
    performanceTarget,
    qualityTarget,
    oeeTarget,
    runtimeTarget,
    manualStatus
    manualStatus,
    manualFactoryOrder,
    contributesToTotal,
    id
  }
}
`;

export const machineApi = {
  getMachine: (id) =>
    axios
      .get("graphql/", {
        params: {
          query: MACHINE_API_GET_MACHINE_QUERY(id)
        }
      })
      .then((res) => res.data),

  getMachines: () =>
    axios
      .get("graphql/", {
        params: {
          query: `
            {machines {
              id
              name
            }
          }`
        }
      })
      .then((res) => res.data),

  getMachineGroupHierarchy: (id) =>
    axios.get(`machines/${id}/machine-group-hierarchy/`).then((res) => res.data),

  getMachineLatestState: (machine_pk) =>
    axios.get(`machines/${machine_pk}/state/`).then((res) => res.data),

  getMachineCodes: (machineId) => {
    let query = `query( $machineId: Int!){
        level2Codes(machineId: $machineId){
          id
          pk
          machine{
            pk
          }
          part{
            pk
          }
          code
          description
          eventType
        }
      }`;

    let variables = {
      machineId: machineId
    };

    return axios.get("graphql/", { params: { query, variables } }).then((res) => {
      return res.data.data.level2Codes;
    });
  },
  getMachineLevel1UpdateTimeFrequency: (id) =>
    axios.get(`manufacturing/machines/${id}/level1-update-time-frequency/`).then((res) => res.data),
  getMachineThresholds: (id) =>
    axios.get(`manufacturing/machines/${id}/thresholds/`).then((res) => res.data)
};
