<template>
  <div class="settings">
    <div class="identity">
      <div class="card first">
        <div class="card-header">
          <h2>{{ $t("Personal") }}</h2>
          <span @click="saveProfile()">
            <i class="icon fa fa-save" />
          </span>
        </div>
        <div class="card-content main">
          <div class="name">
            <v-text-field
              :label="$t('First Name')"
              v-model="profile.firstName"
            >
            </v-text-field>
            <v-text-field
              :label="$t('Last Name')"
              v-model="profile.lastName"
            >
            </v-text-field>
          </div>
          <v-text-field
            :label="$t('Email')"
            v-model="profile.email"
          >
          </v-text-field>
          <v-text-field
            :label="$t('Phone Number')"
            v-model="profile.phoneNumber"
          >
          </v-text-field>

          <div class="av">
            <user-avatar :user="profile" />
            <v-file-input
              @change="setAvatar"
              :label="$t('Avatar')"
              truncate-length="50"
            >
            </v-file-input>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>{{ $t("Communication Preferences") }}</h2>
          <span @click="saveProfile()">
            <i class="icon fa fa-save" />
          </span>
        </div>
        <div class="card-content">
          <v-checkbox
            v-model="profile.emailNotifications"
            :label="$t('Email Notifications')"
            hide-details
          >
          </v-checkbox>
          <v-checkbox
            v-model="profile.smsNotifications"
            :label="$t('SMS Notifications')"
            hide-details
          >
          </v-checkbox>
        </div>
      </div>
      <div class="card pw">
        <div class="card-header">
          <h2>{{ $t("Password") }}</h2>
          <span @click="isFormValid ? savePassword() : ''">
            <i class="icon fa fa-save" />
          </span>
        </div>
        <div class="card-content">
          <v-form
            ref="passwordForm"
            v-model="isFormValid"
          >
            <v-text-field
              v-if="user.hasPassword"
              v-model="currentPassword"
              :append-icon="show.currentPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.min]"
              :type="show.currentPassword ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('Current Password')"
              :hint="$t('At least 8 characters')"
              counter
              @click:append="show.currentPassword = !show.currentPassword"
            ></v-text-field>
            <v-text-field
              v-model="newPassword"
              :append-icon="show.newPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.min, user.hasPassword ? rules.has_password : true]"
              :type="show.newPassword ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('New Password')"
              :hint="$t('At least 8 characters')"
              counter
              @click:append="show.newPassword = !show.newPassword"
            ></v-text-field>
            <v-text-field
              v-model="confirmPassword"
              :append-icon="show.confirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.min, rules.match]"
              :type="show.confirmPassword ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('Confirm Password')"
              :hint="$t('At least 8 characters')"
              counter
              @click:append="show.confirmPassword = !show.confirmPassword"
            ></v-text-field>
            <v-text-field
              v-model="newPin"
              :append-icon="show.newPin ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.minpin, rules.numeric, rules.has_password]"
              :type="show.newPin ? 'text' : 'password'"
              name="input-10-1"
              :label="$t('New Pin')"
              :hint="$t('At least 4 characters')"
              counter
              @click:append="show.newPin = !show.newPin"
            ></v-text-field>
          </v-form>
        </div>
      </div>
    </div>
    <div class="favApps">
      <div class="card version">
        <div class="card-header">
          <h2>{{ $t("Version") }} {{ applicationVersion }}</h2>
        </div>
        <p class="dfVersion">{{ $t("Digital Forms") }} : {{ dfVersion }}</p>
        <p class="iotVersion">{{ $t("IOT") }} : {{ iotVersion }}</p>
      </div>
      <div class="card">
        <div class="card-header">
          <h2>{{ $t("Preference") }}</h2>
          <span @click="saveProfile()">
            <i class="icon fa fa-save" />
          </span>
        </div>
        <div class="card-content">
          <v-select
            :items="themes"
            item-text="name"
            item-value="id"
            v-model="profile.theme"
            @change="updateTheme()"
            :label="$t('Theme')"
            outlined
          >
            <template v-slot:item="{ item }">
              {{ $t(item.name) }}
            </template>
            <template v-slot:selection="{ item }">
              {{ $t(item.name) }}
            </template>
          </v-select>
          <v-select
            :items="languages"
            item-text="name"
            item-value="pk"
            v-model="profile.language"
            :label="$t('Language')"
            outlined
          >
          </v-select>
          <v-select
            :items="locations"
            item-text="name"
            item-value="pk"
            v-model="profile.location"
            :label="$t('Location')"
            outlined
          >
          </v-select>
        </div>
      </div>
      <div class="card fav">
        <div class="card-header">
          <h2>{{ $t("Favorites") }}</h2>
          <span @click="saveFavorite()">
            <i class="icon fa fa-save" />
          </span>
        </div>
        <div class="card-content favorite">
          <div class="row">
            <v-text-field
              :label="$t('Name')"
              v-model="newFavoriteName"
            >
            </v-text-field>
            <v-text-field
              :label="$t('Url')"
              v-model="newFavoriteUrl"
              :error-messages="favoriteError"
              @input="favoriteError = ''"
            >
            </v-text-field>
          </div>
          <v-chip
            class="mr-2"
            v-for="(fav, idx) in favorites"
            :key="fav.id"
          >
            <v-icon
              @click="deleteFavorite(fav.pk)"
              class="delete-favorite"
              >mdi-delete</v-icon
            >
            <span class="bold">{{ fav.name }}</span> {{ fav.url }}
          </v-chip>
        </div>
      </div>
      <div class="card apps">
        <div class="card-header">
          <h2>{{ $t("Hide Routes") }}</h2>
        </div>
        <div class="card-content">
          <v-checkbox
            v-for="route in routes.filter((rt) => rt.displayName)"
            :key="route.path"
            v-model="route.checked"
            @click="updateRoute(route)"
            :label="$t(route.displayName)"
            hide-details
          >
          </v-checkbox>
        </div>
      </div>
    </div>
    <div class="card machine-selector">
      <div class="card-header">
        <h2>{{ $t("Machine Selector") }}</h2>
        <span @click="updateMachines()">
          <i class="icon fa fa-save" />
        </span>
      </div>
      <div class="card-content">
        <v-text-field
          v-model="machineSearchText"
          append-icon="mdi-magnify"
          :label="$t('Search')"
          single-line
          hide-details
          clearable
        >
        </v-text-field>
        <v-checkbox
          v-for="machine in filteredMachineList"
          :key="machine.id"
          v-model="machine.checked"
          :label="machine.name"
          hide-details
        >
        </v-checkbox>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/scss/variables";

.settings-error {
  .error-text {
    max-height: 280px;
    overflow-y: scroll;
  }
}
.settings {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
  padding: 0.5rem 1rem;
  padding-bottom: 0rem;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .name {
    display: flex;
  }
  .fa-save {
    cursor: pointer;
    height: 2rem;
    width: 2rem;
    border-radius: 2rem;
    background-color: white;
    text-align: center;
    line-height: 2rem;
    color: $cardColor;
  }
  .av {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
  }
  .identity {
    height: 100%;
    padding-top: 0.5rem;
    margin-top: -0.5rem;
    padding-left: 0.5rem;
    margin-left: -0.5rem;
    display: flex;
    flex-direction: column;
    .first {
      flex: 1;
    }
  }
  .favorite {
    .row {
      margin: 0 5px;
      .v-text-field {
        margin: 5px;
      }
    }
    .bold {
      font-weight: 600;
      margin-right: 5px;
      font-size: 1rem;
    }
  }
  .favApps {
    display: flex;
    flex-direction: column;
    .apps {
      flex: 1;
    }
  }
  .card {
    overflow: hidden;
    margin-bottom: 1rem;
    &.apps {
      height: -webkit-fill-available;
    }
    &.csv {
      height: 300px;
    }
    &.fav {
      min-height: unset;
    }
    &.version {
      min-height: 4rem;
      .dfVersion {
        margin-top: 3rem;
        margin-left: 1rem;
      }
      .iotVersion {
        margin-bottom: 1rem;
        margin-left: 1rem;
      }
    }
    .progress-bar {
      height: 5px;
      margin-top: 10px;
      margin-bottom: 20px;
    }
    .upload-csv {
      display: flex;
      align-items: center;
      button {
        margin-left: 10px;
        margin-right: 10px;
      }
    }
    .main {
      .v-input__slot {
        margin-bottom: 0.75rem;
      }
      .v-text-field__details {
        display: none;
      }
    }
    .card-content {
      padding-top: 3rem;
    }
    h2 {
      font-size: 1.25rem;
    }
    h3 {
      font-size: 1rem;
      margin-bottom: -0.5rem;
    }
    &.pw,
    &.fav,
    &.apps,
    &.main {
      .card-content {
        padding-bottom: 0.75rem;
      }
    }
    &.pw {
      margin-bottom: 0.5rem;
    }
    .v-chip__close.theme--light.v-icon {
      color: rgba(0, 0, 0, 0.6);
    }
    .v-text-field {
      margin-top: 0;
    }
    .delete-favorite {
      margin-right: 0.5rem;
      font-size: 1.1rem;
    }
    .delete-favorite:hover {
      cursor: pointer;
    }
  }
}
</style>

<script>
import Vue from "vue";
import UserAvatar from "@/ui/UserAvatar";
import { mapActions, mapGetters } from "vuex";
import { changeLocale } from "../utils/language";

export default {
  components: { UserAvatar },
  data() {
    return {
      applicationVersion: "",
      dfVersion: "",
      iotVersion: "",
      profile: {},
      newPassword: "",
      currentPassword: "",
      confirmPassword: "",
      newPin: "",
      themes: [],
      languages: [],
      newFavoriteName: "",
      newFavoriteUrl: "",
      favoriteError: "",
      routes: [],
      machines: [],
      machineSearchText: "",
      locations: [],
      uploading: false,
      uploadResponseOpen: false,
      uploadResponse: null,
      avatarImage: null,
      show: {
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
        newPin: false
      },
      isFormValid: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length == 0 || v.length >= 8 || "Min 8 characters",
        match: (v) => this.newPassword == v || "Does not match new password",
        minpin: (v) => v.length == 0 || v.length >= 4 || "Min 4 Numbers",
        numeric: (v) => v.split("").filter((c) => isNaN(c)).length === 0 || "Numbers Only",
        has_password: (v) =>
          v.length == 0 || this.currentPassword.length >= 8 || "Password Required"
      }
    };
  },
  computed: {
    ...mapGetters({
      favorites: "app/Favorites",
      user: "session/User",
      storedDisabledRoutes: "routes/Disabled"
    }),
    routesFromRouter() {
      return this.$router.options.routes;
    },
    filteredMachineList() {
      if (!this.machineSearchText) {
        return this.machines;
      }
      return this.machines.filter((machine) =>
        machine.name.toLowerCase().includes(this.machineSearchText.trim().toLowerCase())
      );
    }
  },
  mounted() {
    this.loadRoutes();
    this.loadProfile();
    this.loadDefaultData();
    this.loadMachines();
    this.setTitles({
      title: this.$t("User Settings"),
      mobile: this.$t("User Settings")
    });
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  methods: {
    ...mapActions({
      updateProfile: "session/UpdateProfile",
      setDisableRoutes: "routes/SetDisabledRoutes",
      setTitles: "app/SetTitles",
      setTheme: "app/SetTheme",
      setFavorites: "app/SetFavorites",
      setJWT: "session/SetJWT"
    }),
    saveProfile() {
      if (!this.profile.email) {
        this.$message("ALERT", {
          text: this.$t("Email Address Required"),
          type: "error",
          timeout: 1000
        });
      } else {
        const query = `mutation ($input: UserModelMutationInput!){
        updateUser(input:$input) {
          errors {
            messages
            field
          },
          jwt
          avatar,
          email,
          phoneNumber,
          firstName,
          lastName,
          emailNotifications,
          smsNotifications,
          theme,
          language,
          title,
          location{
            id,
            pk,
            name,
            bannerText,
            locationWeatherSearch
          }
        }
      }`;
        const variables = {
          input: {
            id: this.profile.id,
            email: this.profile.email,
            emailNotifications: this.profile.emailNotifications,
            smsNotifications: this.profile.smsNotifications,
            theme: this.profile.theme,
            username: this.user.username
          }
        };
        if (this.profile.phoneNumber) {
          variables.input["phoneNumber"] = this.profile.phoneNumber;
        }
        if (this.profile.firstName) {
          variables.input["firstName"] = this.profile.firstName;
        }
        if (this.profile.lastName) {
          variables.input["lastName"] = this.profile.lastName;
        }
        if (this.profile.language) {
          variables.input["languageId"] = this.profile.language;
        }
        if (this.profile.location) {
          variables.input["locationId"] = this.profile.location;
        }
        if (this.avatarImage !== this.profile.avatar) {
          variables.input["newAvatar"] = this.avatarImage;
        }
        this.$http
          .post("graphql/", { query, variables })
          .then((res) => {
            let updatedProfile = res.data.data.updateUser;
            if (updatedProfile.location) {
              updatedProfile.locationBanner = updatedProfile.location.bannerText;
              updatedProfile.locationLookup = updatedProfile.location.locationWeatherSearch;
              updatedProfile.locationName = updatedProfile.location.name;
              updatedProfile.location = updatedProfile.location.pk;
            }

            this.setLanguage();
            this.updateProfile(updatedProfile);
            this.setJWT(updatedProfile.jwt);
            this.errors = res.data.data.updateUser.errors;
            if (this.errors) {
              this.errors.forEach((error) => {
                error.messages.forEach((message) => {
                  this.$message("ALERT", { text: message, type: "error", timeout: 1000 });
                });
              }, this);
            } else {
              this.$message("ALERT", { text: this.$t("Saved"), type: "success", timeout: 1000 });
              this.$refs.passwordForm.reset();
            }
          })
          .catch((res) => {
            console.error(res);
            this.errors = res.errors;
            this.errors.forEach((error) => {
              this.$message("ALERT", { text: error, type: "error", timeout: 1000 });
            }, this);
          });
      }
    },
    setLanguage() {
      const query = `query($languageId:Int){
        language(id:$languageId){
          id,
          pk,
          name,
          abv,
          translations
        },
      }`;
      const variables = {
        languageId: this.profile.language
      };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        changeLocale(res.data.data.language);
      });
    },
    setAvatar(e) {
      if (e) {
        var file = e;
        var reader = new FileReader();
        const profile = this.profile;
        const context = this;
        reader.onloadend = function () {
          Vue.set(context, "avatarImage", reader.result);
        };
        reader.readAsDataURL(file);
      }
    },
    savePassword() {
      const query = `mutation ($input: UserModelMutationInput!){
        updateUser(input:$input) {
          jwt
          errors {
            messages
            field
          }
        }
      }`;
      const variables = {
        input: {
          id: this.user.pk,
          username: this.user.username
        }
      };
      if (this.user.hasPassword) {
        variables.input.currentPassword = this.currentPassword;
      }
      //set password or pin if it exists
      if (this.newPassword) {
        variables.input.password = this.newPassword;
      }

      if (this.newPin) {
        variables.input.pin = this.newPin;
      }

      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.errors = res.data.data.updateUser.errors;
          if (this.errors) {
            this.errors.forEach((error) => {
              error.messages.forEach((message) => {
                this.$message("ALERT", { text: message, type: "error", timeout: 1000 });
              });
            }, this);
          } else {
            this.setJWT(res.data.data.updateUser.jwt);
            this.$message("ALERT", { text: this.$t("Saved"), type: "success", timeout: 1000 });
            this.$refs.passwordForm.reset();
          }
        })
        .catch((res) => {
          console.log(res);
          res.data.errors.forEach((errror) => {
            this.$message("ALERT", { text: error, type: "error", timeout: 1000 });
          }, this);

          this.errors = res.updateUser.errors;
        });
    },
    loadDefaultData() {
      this.$http.get("df_version/", { baseURL: "/df" }).then((res) => {
        this.dfVersion = res.data.ImageTag;
      });
      this.$http.get("iot_version/", { baseURL: "/iotAPI" }).then((res) => {
        this.iotVersion = res.data.ImageTag;
      });
      const query = `query($userId:Int){
        settings{
          applicationVersion
        }
        languages{
          id,
          pk,
          name
        },
        themeEnums{
            id,
            name
        },
        favorites(userId:$userId){
          url,
          pk,
          id,
          name,
        },
        locations{
          id,
          pk,
          name
        }
      }`;
      const variables = {
        userId: this.user.id
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          if (res.data.data.settings.length > 0) {
            this.applicationVersion = res.data.data.settings[0].applicationVersion;
          }
          this.setFavorites(res.data.data.favorites);
          this.themes = res.data.data.themeEnums;
          this.languages = res.data.data.languages;
          this.locations = res.data.data.locations;
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    saveFavorite() {
      const query = `mutation($input: FavoriteModelMutationInput!) {
        updateFavorite(input: $input) {
          url,
          name
          id
        }
      }`;
      const variables = {
        input: {
          url: this.newFavoriteUrl,
          name: this.newFavoriteName,
          user: this.user.id
        }
      };

      if (this.favorites.find((f) => f.url === this.newFavoriteUrl)) {
        this.favoriteError = this.$t("The url must be unique");
        return;
      }
      if (this.newFavoriteUrl.length > 300) {
        this.favoriteError = this.$t("Url too long");
        return;
      }

      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          if (res.data.data.updateFavorite["id"]) {
            res.data.data.updateFavorite["pk"] = res.data.data.updateFavorite["id"];
            this.setFavorites([...this.favorites, res.data.data.updateFavorite]);
            this.newFavoriteUrl = "";
            this.newFavoriteName = "";
          }
        })
        .catch((res) => {
          this.errors = Array.isArray[res?.errors] ? res.errors : [res?.errors || res];
          this.errors.forEach((error) => {
            this.$message("ALERT", { text: error, type: "error", timeout: 1000 });
          }, this);
        });
    },

    deleteFavorite(fav_id) {
      const query = `mutation($input: DeleteFavoriteMutationInput!) {
          deleteFavorite(input: $input) {
            id
          }
      }`;
      const variables = {
        input: {
          clientMutationId: fav_id
        }
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          const deletedId = res.data.data.deleteFavorite.id;
          this.setFavorites(this.favorites.filter((fav) => fav.pk !== deletedId));
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    updateTheme() {
      if (this.profile.theme == 1) {
        this.setTheme("dark");
        this.$vuetify.theme.dark = true;
      } else {
        this.setTheme("light");
        this.$vuetify.theme.dark = false;
      }
    },
    updateRoute(route) {
      if (route.checked) {
        this.saveDisabledRoutes(route);
      } else {
        this.deleteDisabledRoute(route);
      }
    },
    deleteDisabledRoute(route) {
      const query = `mutation($input: DeleteDisabledRouteMutationInput!) {
        deleteDisabledRoute(input: $input) {
          id
        }
      }`;
      const variables = {
        input: {
          clientMutationId: route.id
        }
      };
      //TODO fix this context
      const routes = this.routes;
      const setDisableRoutes = this.setDisableRoutes;
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          delete route["id"];
          // eslint-disable-next-line no-prototype-builtins
          setDisableRoutes(routes.filter((rt) => route.hasOwnProperty("id")));
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    saveDisabledRoutes(route) {
      const query = `mutation($input: DisabledRouteMutationInput!) {
        updateDisabledRoute(input: $input) {
          routeName
          id
          pk
        }
      }`;

      const variables = {
        input: {
          routeName: route.name,
          user: this.user.id
        }
      };
      if (route.id) {
        variables["input"]["id"] = route.id;
      }
      //TODO fix this context
      const setDisableRoutes = this.setDisableRoutes;
      const routes = this.routes;
      this.$http
        .post("graphql/", { query: query, variables: variables })
        .then((res) => {
          route.id = res.data.data.updateDisabledRoute.id;
          // eslint-disable-next-line no-prototype-builtins
          setDisableRoutes(routes.filter((rt) => rt.hasOwnProperty("id")));
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    async updateMachines() {
      const profile = { ...this.profile };
      profile.machines = this.machines.filter((machine) => machine.checked).map((item) => item.pk);
      const query = `mutation ($input: UserModelMutationInput!){
        updateUser(input:$input) {
          errors {
            messages
            field
          }
        }
      }`;
      const variables = {
        input: {
          username: profile.username,
          machines: profile.machines,
          id: this.user.pk
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        this.$message("ALERT", { text: this.$t("Saved"), type: "success", timeout: 1000 });
        this.updateProfile(profile);
      } catch (error) {
        console.error(error);
      }
    },
    recurseRoute(routes) {
      let newRoutes = [];
      routes.forEach((route) => {
        newRoutes.push(route);
        if (route.children) {
          newRoutes = newRoutes.concat(this.recurseRoute(route.children));
        }
      });
      return newRoutes;
    },
    loadRoutes() {
      this.routes = this.recurseRoute(this.routesFromRouter);
      const disabledRoutes = this.storedDisabledRoutes.disabledRoutes;
      if (this.routes && disabledRoutes) {
        this.routes.forEach((route) => {
          disabledRoutes.forEach((dRoute) => {
            if (dRoute.routeName == route.name) {
              route.id = dRoute.pk;
              Vue.set(route, "checked", true);
            }
          });
        });
      }
    },
    loadProfile(userId) {
      this.profile = { ...this.user };
      this.profile.language = parseInt(this.profile.language);
      this.avatarImage = this.profile.avatar;
    },
    loadMachines() {
      const query = `query{
        machines{
          id,
          name,
          pk
        }
      }`;
      const self = this;

      this.$http
        .post("graphql/", { query: query })
        .then((res) => {
          self.machines = res.data.data.machines;
          self.user.machines.forEach((myMachineId) => {
            self.machines.forEach((machine) => {
              if (myMachineId === machine.pk) {
                Vue.set(machine, "checked", true);
              }
            });
          });
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    },
    validatePassword: function () {
      // manually call validation
      this.$refs.passwordForm.validate();
    }
  },
  watch: {
    storedDisabledRoutes: {
      handler: "loadRoutes",
      deep: true
    },
    currentPassword: "validatePassword",
    newPassword: "validatePassword",
    confirmPassword: "validatePassword"
  }
};
</script>
