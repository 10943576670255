<template>
  <div class="paginatedChart">
    <div class="flex-firstrow">
      <h3>{{ numberRecords ? `${$t("Top")} ${numberRecords}` : $t("All") }} {{ title }}</h3>
      <div class="leftPicker">
        <div class="rangeSelector">
          <i
            class="fa fa-chevron-left clickable"
            v-bind:class="recordRange.start <= 0 && 'disabled'"
            @click="
              () => {
                if (!(recordRange.start <= 0)) {
                  ChangeRecordRange(-numberRecordsPerPage);
                }
              }
            "
          />
          <span> {{ recordRange.start + 1 }} - {{ recordRange.end + 1 }} </span>
          <i
            class="fa fa-chevron-right clickable"
            v-bind:class="
              ((numberRecords != null && recordRange.end >= numberRecords - 1) ||
                (numberRecords == null && recordRange.end + 1 >= filteredItems.length)) &&
              'disabled'
            "
            @click="
              () => {
                if (
                  !(
                    (numberRecords != null && recordRange.end >= numberRecords - 1) ||
                    (numberRecords == null && recordRange.end + 1 >= filteredItems.length)
                  )
                ) {
                  ChangeRecordRange(+numberRecordsPerPage);
                }
              }
            "
          />
        </div>
        <v-tabs
          class="pageSize"
          @change="ChangeNumberRecords"
          :value="[10, 25, 50, null].indexOf(numberRecords)"
          v-if="useNumberRecordsPicker"
        >
          <v-tab>{{ $t("Top 10") }}</v-tab>
          <v-tab>{{ $t("Top 25") }}</v-tab>
          <v-tab>{{ $t("Top 50") }}</v-tab>
          <v-tab>{{ $t("All") }}</v-tab>
        </v-tabs>
      </div>
    </div>
    <bar-chart
      title=""
      class="bar_chart"
      :chart-data="slicedChartData"
      :chart-options="options"
      :height="vw > 1000 ? 400 : 250"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import BarChart from "@/components/charts/BarChart";

export default {
  components: { BarChart },
  props: [
    "chartOptions",
    "sortOn",
    "numberRecordsPerPage",
    "numberRecords",
    "filteredItems",
    "title",
    "useNumberRecordsPicker",
    "ChangeNumberRecords",
    "DataFormatter",
    "label_name",
    "secondAxis",
    "showLegend"
  ],
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  data() {
    return {
      recordRange: { start: 0, end: 0 },
      top: {},
      slicedChartData: {},
      chartData: {},
      options: {
        scales: {
          yAxes: [
            {
              zeroLineColor: "#FFF",
              stacked: true,
              ticks: {
                callback: (item) => {
                  if (this.DataFormatter) {
                    return this.DataFormatter(item);
                  }
                  return item;
                }
              }
            }
          ],
          xAxes: [
            {
              ticks: { minRotation: 45, maxRotation: 45 },
              stacked: true
            }
          ]
        },
        legend: {
          display: this.showLegend
        },
        tooltips: {
          callbacks: {
            label: (tooltipItem) => {
              let label = "";
              if (this.chartData["datasets"][tooltipItem.datasetIndex].label) {
                label = this.chartData["datasets"][tooltipItem.datasetIndex].label;
              }
              let uom = "";
              if (this.chartData["datasets"][tooltipItem.datasetIndex].uom) {
                uom = this.chartData["datasets"][tooltipItem.datasetIndex].uom;
              }
              if (this.DataFormatter) {
                return label + " : " + this.DataFormatter(tooltipItem.value) + uom;
              }
              return label + " " + tooltipItem.value + uom;
            }
          },
          mode: "index",
          intersect: false
        },
        hover: {
          mode: "index",
          intersect: false
        }
      }
    };
  },
  created() {
    if (this.secondAxis) {
      this.options["scales"]["yAxes"].push(this.secondAxis);
    }
    this.generateChart();
  },
  methods: {
    ChangeRecordRange(number) {
      let clampedMax = Math.min(this.recordRange.end + number, this.numberRecords - 1);
      if (this.numberRecords == null) {
        clampedMax = Math.min(this.recordRange.end + number, this.filteredItems.length - 1);
      }
      let clampedMin = Math.max(clampedMax - this.numberRecordsPerPage + 1, 0);

      if (clampedMin == 0) {
        clampedMax = Math.min(
          this.numberRecords ? this.numberRecords - 1 : this.filteredItems.length - 1,
          this.numberRecordsPerPage - 1
        );
      }

      this.recordRange = {
        start: clampedMin,
        end: clampedMax
      };
      this.sliceChart();
    },
    sliceChart() {
      const datasets = [];
      for (const d of this.chartData.datasets) {
        datasets.push({
          ...d,
          data: d.data.slice(this.recordRange.start, this.recordRange.end + 1)
        });
      }

      const slicedChartData = {
        datasets: datasets,
        labels: [...this.chartData.labels.slice(this.recordRange.start, this.recordRange.end + 1)],
        roundAll: true
      };

      this.slicedChartData = slicedChartData;
    },
    generateChart() {
      let data = this.filteredItems.slice();

      data.sort((itemA, itemB) => {
        const a = itemA[this.sortOn];
        const b = itemB[this.sortOn];
        // eslint-disable-next-line no-constant-condition
        if ((this.sortOrder = "asc")) {
          return b - a;
        }
        return a - b;
      });

      if (this.numberRecords) {
        data = data.slice(0, this.numberRecords);
      }

      const datasets = [];

      for (const option of this.chartOptions) {
        datasets.push({
          data: [],
          backgroundColor: [],
          label: option.label,
          uom: option.uom,
          stack: option.stack,
          type: option.type,
          borderColor: option.borderColor,
          fill: option.fill,
          color: option.color,
          keys: option.keys,
          yAxisID: option.yAxisID
        });
      }

      const numberRecords = this.numberRecords || data.length;
      const labels = [];
      for (let i = 0; i < numberRecords; i++) {
        const item = data[i];
        if (item) {
          for (const d of datasets) {
            let p = { ...item };
            for (const k of d.keys) {
              p = item[k];
            }

            d.data.push(p);
            d.backgroundColor.push(d.color);
          }

          labels.push([item[this.label_name] || ""]);
        } else {
          for (const d of datasets) {
            d.data.push(null);
          }
          labels.push("");
        }
      }

      this.chartData = {
        datasets: datasets,
        labels: labels
      };

      this.sliceChart();
      this.ChangeRecordRange(0);
    }
  },
  watch: {
    numberRecords: function () {
      this.generateChart();
    },
    filteredItems: function () {
      this.generateChart();
    }
  }
};
</script>
<style lang="scss">
@import "../../../scss/variables";
.paginatedChart {
  //height: 300px;

  h3 {
    color: $blue;
    font-weight: 600;
    padding: 15px 0;
  }

  .bar_chart {
    //height: 250px !important;
  }

  .flex-firstrow {
    display: flex;
    justify-content: space-between;

    .v-tabs {
      flex: unset;
      width: unset;
    }
  }

  .leftPicker {
    display: flex;
    align-items: center;

    .rangeSelector {
      padding: 10px;
      white-space: nowrap;
      .clickable {
        padding: 10px;
        cursor: pointer;

        &:hover {
          color: $blue;
        }

        &.disabled {
          cursor: not-allowed;
          color: gray;
        }
      }
    }
    .pageSize {
      @media screen and (max-width: 1200px) {
        position: absolute;
        top: 10px;
        left: 0;
        &.v-tabs {
          .v-tab {
            font-size: 12px;
            min-width: unset;
          }
        }
      }
    }
  }
}
</style>
