<template>
  <div class="numberInput">
    <v-btn
      color="white"
      light
      fab
      small
      @click="decrement()"
      :disabled="min !== null && input <= min"
    >
      <i
        class="fa fa-minus"
        aria-hidden="true"
      ></i>
    </v-btn>
    <v-text-field
      v-model="input"
      @input="updateValue"
      placeholder="0"
      type="tel"
    ></v-text-field>
    <v-btn
      color="white"
      light
      fab
      small
      @click="increment()"
      :disabled="max !== null && input >= max"
    >
      <i
        class="fa fa-plus"
        aria-hidden="true"
      ></i>
    </v-btn>
  </div>
</template>
<style lang="scss">
.numberInput {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20rem;
  max-width: 100%;
  margin: 0.5rem auto;
  .v-input {
    margin: 0 1rem;
    input {
      font-size: 1.5rem;
      text-align: center;
    }
  }
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
}
</style>

<script>
export default {
  props: {
    value: {
      default: null
    },
    min: {
      default: null
    },
    max: {
      default: null
    },
    id: {
      default: "number"
    },
    isInteger: {
      default: false
    }
  },
  data() {
    return {
      input: this.value
    };
  },
  components: {},
  created() {},
  beforeDestroy() {},
  computed: {},
  methods: {
    async validateValue() {
      if (
        isNaN(this.input) ||
        (this.isInteger && this.input % 1 !== 0) ||
        (this.max !== null && this.input > this.max) ||
        (this.min !== null && this.input < this.min)
      ) {
        await this.$nextTick();
        this.input = this.value;
        return false;
      }

      return true;
    },
    async updateValue() {
      if (!(await this.validateValue())) return;
      this.$emit("input", this.input);
    },
    increment() {
      this.input++;
      this.$emit("input", this.input);
    },
    decrement() {
      this.input--;
      this.$emit("input", this.input);
    }
  },
  watch: {
    value() {
      this.input = this.value;
    }
  }
};
</script>
