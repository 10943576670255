<template>
  <Card class="file-card rounded-lg d-flex">
    <v-icon
      left
      aria-hidden="true"
      class="color--text-secondary file-icon"
    >
      mdi-image-outline
    </v-icon>
    <div class="file-info d-flex flex-fill flex-column">
      <p class="file-name mr-5">{{ file.name }}</p>
      <p class="text-body-2 color--text-secondary">{{ fileSizeFormatted }}</p>
    </div>
    <v-btn
      icon
      class="color--text-secondary file-btn"
      @click="$emit('delete-file')"
    >
      <v-icon>mdi-delete-outline</v-icon>
    </v-btn>
  </Card>
</template>

<script setup>
import Card from "@/ui/Card";
import { computed, toRefs } from "vue";

const props = defineProps({
  file: {
    type: File,
    default: "",
    required: true
  }
});

defineEmits(["delete-file"]);

const { file } = toRefs(props);

const fileSizeFormatted = computed(() =>
  file.value ? Math.round((file.value.size / Math.pow(1024, 2)) * 100) / 100 + "MB" : ""
);
</script>

<style scoped lang="scss">
@import "@/scss/variables";

.file-btn {
  position: absolute;
  right: 10px;
  top: 3px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    top: 8px;
  }
}

.file-icon {
  color: var(--color-primary) !important;
}

.file-card {
  display: inline-flex !important;
  max-width: rem(210px);
  background-color: var(--bg-card-white);
  border: 1px solid var(--border-secondary);
}

.file-name {
  max-width: rem(124px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 16px;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .file-info {
    max-width: 60%;
  }
}
</style>
