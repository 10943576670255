<template>
  <div class="chart-container">
    <div class="chart-title">
      <div>{{ $t("Availability") }}</div>
    </div>
    <bar-chart
      chartName="availabilityChart"
      class="availabilityChart"
      :decimal="0"
      :chartData="chartData"
      :options="options"
      :click-function="clickEvent"
      uom="time"
      :axisType="AXISTYPE.TIME"
      :chart-options="{
        dataset: {
          maxBarThickness: 50 // number (pixels)
        }
      }"
    />
    <div class="footer">
      <div class="picker">
        <div>
          {{ $t("items per page") }}
          <select @change="sliceResult">
            <option
              value="5"
              :selected="numberSelected === 5"
            >
              5
            </option>
            <option
              value="10"
              :selected="numberSelected === 10"
            >
              10
            </option>
            <option
              value="25"
              :selected="numberSelected === 25"
            >
              25
            </option>
            <option
              value="50"
              :selected="numberSelected === 50"
            >
              50
            </option>
          </select>
        </div>
        <div>
          <i
            class="fa fa-chevron-left"
            @click="previousPage"
            v-bind:class="currentPage <= 0 && 'disabled'"
          />
          {{ `${currentPage + 1}` }} <span>of {{ numberPages }}</span>
          <i
            class="fa fa-chevron-right"
            @click="nextPage"
            v-bind:class="currentPage >= numberPages - 1 && 'disabled'"
          />
        </div>
      </div>
      <i
        class="fa sort"
        @click="toggleSort"
        v-bind:class="sortOrder === 'desc' ? 'fa-sort-amount-desc' : 'fa-sort-amount-asc'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BarChart, { AXISTYPE } from "@/components/charts/BarChart";
import { SUMMARYCHARTTYPE } from "../SummaryTable";

export default {
  name: "SummaryPerformanceChart",
  components: { BarChart },
  props: [
    "production",
    "options",
    "filters",
    "chartType",
    "setInteractiveFilter",
    "interactiveFilters"
  ],
  data() {
    return {
      AXISTYPE: AXISTYPE,
      chartData: [],
      chartIds: [],
      start: 1,
      end: 1,
      currentPage: 0,
      numberPages: 1,
      numberSelected: 10,
      sortOrder: "desc" //asc
    };
  },
  computed: {
    ...mapGetters({
      statuses: "app/Statuses",
      theme: "app/Theme"
    })
  },
  created() {
    this.buildChart();
  },
  methods: {
    toggleSort() {
      this.sortOrder = this.sortOrder === "desc" ? "asc" : "desc";
      this.buildChart();
    },
    clickEvent(evt, clickedElements) {
      const id = this.chartIds[clickedElements[0]._index];
      this.setInteractiveFilter(id, this.chartType);
    },
    previousPage() {
      if (this.currentPage > 0) {
        this.currentPage--;
        this.buildChart();
      }
    },
    nextPage() {
      if (this.currentPage < this.numberPages - 1) {
        this.currentPage++;
        this.buildChart();
      }
    },
    sliceResult(e) {
      this.numberSelected = parseInt(e.target.value);
      this.currentPage = 0;
      this.buildChart();
    },
    buildChart() {
      let productionData = [];
      let productionGradients = [];
      let footers = [];
      let titles = [];
      let labels = [];
      let targets = [];
      this.chartIds = [];

      const statusMap = {};
      for (const status of this.statuses) {
        //bool
        const running = status.running;
        const plannedDown = status.plannedDown;
        for (const set of status.statuscodeSet) {
          statusMap[set.code] = {
            ...set,
            running,
            plannedDown
          };
        }
      }

      const actual = {};
      if (this.production) {
        this.production.forEach((interval) => {
          for (const per_machine of interval.by_machine) {
            switch (this.chartType) {
              case SUMMARYCHARTTYPE.MACHINE: {
                const machine = per_machine.machine.machine_id;
                const status_codes = per_machine.status_codes;
                if (actual[machine] == null) {
                  actual[machine] = {
                    num: 0,
                    name: per_machine.machine.machine_name,
                    target: 0,
                    id: machine,
                    filtered: false
                  };
                }

                for (const b of status_codes) {
                  if (statusMap[b.status_code] && statusMap[b.status_code].running) {
                    actual[machine].num += b.duration;
                  }
                  if (statusMap[b.status_code] == null || !statusMap[b.status_code].plannedDown) {
                    actual[machine].target +=
                      b.duration * ((this.options.targets.runtimeTargets[machine] || 100) / 100);
                  }
                }

                if (
                  this.interactiveFilters.machines.length > 0 &&
                  this.interactiveFilters.machines.indexOf(machine) < 0
                ) {
                  actual[machine].filtered = true;
                }
                break;
              }
              case SUMMARYCHARTTYPE.PART: {
                for (const per_part of per_machine.by_part) {
                  const part = per_part.part.part_number;
                  const machine = per_machine.machine.machine_id;
                  const status_codes = per_part.status_codes;
                  if (actual[part] == null) {
                    actual[part] = { num: 0, name: part, target: 0, id: part, filtered: false };
                  }

                  for (const b of status_codes) {
                    if (statusMap[b.status_code] && statusMap[b.status_code].running) {
                      actual[part].num += b.duration;
                    }
                    actual[part].target +=
                      b.duration * ((this.options.targets.runtimeTargets[machine] || 100) / 100);
                  }

                  if (
                    this.interactiveFilters.parts.length > 0 &&
                    this.interactiveFilters.parts.indexOf(part) < 0
                  ) {
                    actual[part].filtered = true;
                  }
                }
                break;
              }
            }
          }
        });

        let sortedArray = [];
        for (const key of Object.keys(actual)) {
          const obj = {
            num: 0,
            target: 0,
            gradient: null,
            footer: "",
            title: "",
            label: "",
            id: 0
          };
          obj.num = actual[key].num;
          obj.target = Math.round(actual[key].target);
          if (actual[key].num >= actual[key].target) {
            obj.gradient = actual[key].filtered
              ? this.$colors.chart.good_gradient_light
              : this.$colors.chart.good_gradient;
          } else {
            obj.gradient = actual[key].filtered
              ? this.$colors.chart.bad_gradient_light
              : this.$colors.chart.bad_gradient;
          }
          obj.footer = "";
          obj.title = actual[key].name;
          obj.label = actual[key].name;
          obj.id = actual[key].id;
          sortedArray.push(obj);
        }

        sortedArray.sort((a, b) => {
          return this.sortOrder === "desc" ? b.num - a.num : a.num - b.num;
        });

        this.numberPages = Math.ceil(sortedArray.length / this.numberSelected);
        this.start = this.currentPage * this.numberSelected + 1;
        this.end = Number(this.currentPage * this.numberSelected) + Number(this.numberSelected);
        sortedArray = sortedArray.slice(this.start - 1, this.end);

        for (const element of sortedArray) {
          productionData.push(element.num);
          targets.push(element.target);
          productionGradients.push(element.gradient);
          footers.push(element.footer);
          titles.push(element.title);
          labels.push(element.label);
          this.chartIds.push(element.id);
        }

        this.chartData = {
          datasets: [
            {
              type: "line",
              data: targets,
              borderWidth: 2,
              pointRadius: 3,
              pointBackgroundColor: this.theme === "dark" ? "white" : "#44B2F3",
              hoverPointRadius: 0,
              borderColor: this.theme === "dark" ? "white" : "#44B2F3",
              titles: titles,
              fill: true,
              label: this.$t("Target"),
              showLine: false
            },
            {
              data: productionData,
              gradients: productionGradients,
              footers: footers,
              titles: titles,
              label: this.$t("Produced")
            }
          ],
          labels: labels
        };
      }
    }
  },
  watch: {
    production: function () {
      this.buildChart();
    },
    chartType: function () {
      this.buildChart();
    },
    interactiveFilters: function () {
      this.buildChart();
    },
    theme: function () {
      this.buildChart();
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";
@import "../../../../scss/mq";

.chart-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  .footer {
    display: flex;
    flex: unset;
    align-items: center;
    justify-content: flex-end;

    .sort {
      padding: 0 10px;
      transform: rotate(-90deg);
      cursor: pointer;
    }

    .picker {
      font-size: 12px;
      flex: unset;
      justify-content: flex-end;
      display: flex;

      i {
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
        }
      }

      div {
        padding: 0 10px;
        flex: unset;
      }

      select {
        color: white;
        text-decoration: underline;

        .theme--light & {
          color: black;
        }
        option {
          color: black;
        }
      }
    }
  }

  .chart-title {
    color: $blue;
    padding-bottom: 3px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;

    div {
      flex: unset;
      display: flex;
    }
  }

  .chart-title {
    flex: unset;
  }

  .availabilityChart {
    flex: 1;
    display: grid;
    height: 100%;
    overflow: hidden;
    position: relative;
  }
}
</style>
