import { ref } from "vue";
import { useMutation } from "@tanstack/vue-query";
import { auditFileUploadApi } from "../../../api/auditFileUploadApi.js";

export function useAuditFileUpload() {
  const uploadErrors = ref([]);

  const { isPending, mutateAsync } = useMutation({
    mutationFn: (formData) => auditFileUploadApi.uploadFile(formData),
    onSuccess: (data, variables) => {
      const fileName = variables.get("files").name;
    }
  });

  const uploadFiles = async (filesByQuestion, context = "question") => {
    uploadErrors.value = [];

    const uploadPromises = [];
    for (const questionId in filesByQuestion) {
      const files = filesByQuestion[questionId];
      for (const file of files) {
        const formData = new FormData();
        formData.append("context", context);
        formData.append("object_id", questionId);
        formData.append("files", file);
        uploadPromises.push(mutateAsync(formData));
      }
    }
    await Promise.all(uploadPromises);
  };

  return {
    uploadFiles,
    uploading: isPending,
    uploadErrors
  };
}
