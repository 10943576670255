<template>
  <v-text-field
    color="text-tertiary"
    :class="['text-field', 'filled' in $props && 'filled', 'large' in $props && 'large']"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </v-text-field>
</template>

<script>
export default {
  name: "TextField",
  props: {
    filled: { type: Boolean, default: false },
    large: { type: Boolean, default: false }
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/_variables.scss";

.text-field {
  &::v-deep.v-input--is-focused {
    border: 1px solid var(--btn-primary) !important;
    outline: 1px solid var(--btn-primary) !important;
  }

  &.filled {
    background: var(--bg-card-white);
    border-radius: rem(8px);
    border: 1px solid var(--border-primary);
    box-shadow: 0px 1px 2px 0px #1a1a1a08;
    padding: rem(5px) rem(15px);
    color: var(--text-tertiary) !important;
    caret-color: var(--text-tertiary) !important;

    ::v-deep {
      .v-input__slot:before,
      .v-input__slot:after {
        display: none;
      }
    }
  }

  ::v-deep .v-label {
    color: var(--text-tertiary) !important;
  }

  &.large {
    min-height: rem(44px);
    padding: rem(8px) rem(16px);

    ::v-deep .v-input__prepend-inner {
      padding-right: rem(10px);
    }
  }
}
</style>
