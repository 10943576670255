<template>
  <div class="v-time-picker-custom">
    <v-text-field
      @click.stop.prevent="
        () => {
          appendIcon && !textFieldProps.isClickableIcon ? null : setVisible(id);
        }
      "
      v-bind="textFieldProps"
      :disabled="disabled"
      :loading="loading"
      :label="label"
      :value="formattedDatetime"
      :clearable="clearable"
      :append-icon="appendIcon"
      :hint="hint"
      :rules="rules"
      @click:append="openCalendar"
      @click:clear="clearDate"
      @focus="
        () => {
          focus();
        }
      "
      @focusout="
        () => {
          focusout();
        }
      "
      readonly
    />
    <v-dialog
      v-model="dialogStatus"
      @close="close"
      @click:outside="close"
      content-class="v-time-picker-custom modal_time_picker"
    >
      <div
        class="overlay"
        @click="close()"
        v-if="dialogStatus"
      ></div>
      <transition name="slide-fade">
        <div
          class="picker modal-picker"
          :class="position"
          v-if="visibleComponent.visible == id"
        >
          <v-tabs
            fixed-tabs
            v-model="activeTab"
          >
            <v-tab
              key="calendar"
              v-if="!dateOnly"
            >
              <i
                class="mdi mdi-calendar-blank-outline"
                aria-hidden="true"
              />
            </v-tab>
            <v-tab
              key="timer"
              :disabled="dateSelected"
              v-if="!dateOnly"
            >
              <i
                class="mdi mdi-clock-outline"
                aria-hidden="true"
              />
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker
                v-model="date"
                v-bind="datePickerProps"
                @input="showTimePicker"
                full-width
                :max="max ? max.toISOString() : null"
                :min="min ? min.toISOString() : null"
                :locale="$i18n.locale()"
              />
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                ref="timer"
                class="v-time-picker-custom"
                v-model="time"
                v-bind="timePickerProps"
                full-width
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
          <v-card-actions>
            <v-spacer></v-spacer>
            <slot
              name="actions"
              :parent="this"
            >
              <v-btn
                color="green darken-1"
                text
                @click="okHandler"
                >{{ $t(okText) }}</v-btn
              >
            </slot>
          </v-card-actions>
        </div>
      </transition>
    </v-dialog>
    <div
      class="overlay"
      @click="close()"
      v-if="visibleComponent.visible == id && !isDialog"
    ></div>
    <transition name="slide-fade">
      <div
        class="picker"
        :class="position"
        v-if="visibleComponent.visible == id && !isDialog"
      >
        <v-tabs
          fixed-tabs
          v-model="activeTab"
        >
          <v-tab
            key="calendar"
            v-if="!dateOnly"
          >
            <i
              class="fa fa-calendar"
              aria-hidden="true"
            />
          </v-tab>
          <v-tab
            key="timer"
            :disabled="dateSelected"
            v-if="!dateOnly"
          >
            <i
              class="fa fa-clock-o"
              aria-hidden="true"
            />
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              v-bind="datePickerProps"
              @input="showTimePicker"
              full-width
              :max="max ? max.toISOString() : null"
              :min="min ? min.toISOString() : null"
              :locale="$i18n.locale()"
            />
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              v-model="time"
              v-bind="timePickerProps"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot
            name="actions"
            :parent="this"
          >
            <v-btn
              color="green darken-1"
              text
              @click="okHandler"
              >{{ $t(okText) }}</v-btn
            >
          </slot>
        </v-card-actions>
      </div>
    </transition>
  </div>
</template>

<style lang="scss">
@import "@/scss/variables";
@import "datepicker.scss";
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";

const DEFAULT_DATE = "";
const DEFAULT_TIME = "00:00:00";
const DEFAULT_DATE_FORMAT = "YYYY-MM-DD";
const DEFAULT_TIME_FORMAT = "HH:mm:ss";
const DEFAULT_DIALOG_WIDTH = 340;
const DEFAULT_CLEAR_TEXT = "CLEAR";
const DEFAULT_OK_TEXT = "OK";

export default {
  name: "v-datetime-picker",
  model: {
    prop: "datetime",
    event: "input"
  },
  props: {
    max: Date,
    min: Date,
    isDialog: Boolean,
    clearable: Boolean,
    appendIcon: String,
    selectCallback: Function,
    hint: String,
    rules: String,
    datetime: {
      type: [Date, String],
      default: null
    },
    dateOnly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean
    },
    loading: {
      type: Boolean
    },
    label: {
      type: String,
      default: ""
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT
    },
    timeFormat: {
      type: String,
      default: "HH:mm"
    },
    clearText: {
      type: String,
      default: DEFAULT_CLEAR_TEXT
    },
    okText: {
      type: String,
      default: DEFAULT_OK_TEXT
    },
    textFieldProps: {
      type: Object
    },
    datePickerProps: {
      type: Object
    },
    timePickerProps: {
      type: Object
    },
    id: {
      type: String,
      default: ""
    },
    position: {
      type: String,
      default: "left"
    },
    onClear: {
      type: Function,
      default: () => {}
    }
  },
  components: {
    DialogCloseButton
  },
  data() {
    return {
      display: false,
      activeTab: 0,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      visibleComponent: "components/Visible"
    }),
    dialogStatus() {
      return this.visibleComponent.visible == this.id && this.isDialog;
    },
    dateTimeFormat() {
      if (this.dateOnly) {
        return this.dateFormat;
      } else {
        return this.dateFormat + " " + this.timeFormat;
      }
    },
    defaultDateTimeFormat() {
      return DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT;
    },
    formattedDatetime() {
      if (this.selectedDatetime != null) {
        return this.selectedDatetime
          ? moment(this.selectedDatetime).format(this.dateTimeFormat)
          : "";
      } else {
        return "";
      }
    },
    selectedDatetime() {
      if (this.date && this.time) {
        let datetimeString = this.date + " " + this.time;
        if (this.time.length === 5) {
          datetimeString += ":00";
        }
        return moment(datetimeString, this.defaultDateTimeFormat).toDate();
      } else {
        return null;
      }
    },
    dateSelected() {
      return !this.date;
    }
  },
  methods: {
    ...mapActions({
      setVisible: "components/SetVisible"
    }),
    init() {
      if (!this.datetime) {
        this.date = null;
        this.time = null;
        return;
      }

      let initDateTime;
      if (this.datetime instanceof Date) {
        initDateTime = this.datetime;
      } else if (typeof this.datetime === "string" || this.datetime instanceof String) {
        // see https://stackoverflow.com/a/9436948
        initDateTime = moment(this.datetime, this.dateTimeFormat).toDate();
      }
      this.date = moment(initDateTime).format(DEFAULT_DATE_FORMAT);
      this.time = moment(initDateTime).format(DEFAULT_TIME_FORMAT);
    },
    clearDate() {
      this.date = null;
      this.time = null;
      this.onClear();
    },
    focus() {
      this.$emit("focus");
    },
    focusout() {
      this.$emit("focusout");
    },
    close() {
      this.setVisible(false);
    },
    openCalendar() {
      this.setVisible(this.id);
    },
    okHandler() {
      this.resetPicker();
      this.setVisible(false);
      this.$emit("input", this.selectedDatetime);
    },
    clearHandler() {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit("input", null);
    },
    resetPicker() {
      this.display = false;
      this.activeTab = 0;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker() {
      if (!this.dateOnly) {
        this.activeTab = 1;
      }
    }
  },
  watch: {
    datetime: function () {
      this.init();
    }
  }
};
</script>
