<template>
  <div>
    <MenuNestedLayout
      :show="show"
      :class="containerClass"
      :back-label="$t('Machine Groups')"
      :show-level1="mode == 'groupOnly' || selectionType !== 'machines'"
      :level-name="currentLevel?.name"
      :level1-search="subGroupTerm"
      :level2-search="machineFilter.terms"
      @level1-search="subGroupTerm = $event"
      @level2-search="machineFilter.terms = $event"
      @back="emit('back', $event)"
      @level2-hover-body="closeSubGroupMenu"
    >
      <template #level1List>
        <MenuItem
          v-for="group in getSubGroups(currentGroup)"
          :key="group.id"
          :icon-left="groupIconByLevel(group.level.level)"
          :label="group.name"
          :icon-right="group.subGroupIds.length > 0 ? 'mdi-chevron-right' : ''"
          @click="selectGroup(group)"
        />
      </template>

      <template #level2Header>
        <div>
          <MenuItem
            v-for="selectedGroup in selectedGroupsSorted"
            :key="selectedGroup.pk"
            icon-right-size="24"
            icon-right="mdi-chevron-right"
            :active="true"
            :label="selectedGroup.name"
            show-close-icon
            @mouseenter="reselectGroup(selectedGroup, $event)"
            @click="reselectGroup(selectedGroup, $event)"
            @click-close-button="unselectGroup(selectedGroup)"
          />

          <MenuItem
            v-if="hasSubGroups(currentGroup)"
            :label="$t('All {count}s', { count: groupLevels[currentGroup?.level.level - 1]?.name })"
            icon-right="mdi-chevron-right"
            @mouseenter="openSubGroupMenu(currentGroup, $event)"
            @click="openSubGroupMenu(currentGroup, $event)"
          />
        </div>
      </template>

      <template #level2BodyHead>
        <MenuItem
          :to="{
            name: AppRoutes.groupSummary.name,
            params: {
              level: currentGroup.level.level,
              machine_group_id: currentGroup.id,
              machine_group_pk: currentGroup.pk
            }
          }"
          :label="$t('{group} Machines', { group: currentGroup.name })"
          icon-right="mdi-chevron-right"
        />
      </template>

      <template #level2BodyList>
        <btn-toggle
          class="menu-toggle mb-4"
          @change="machineFilter.isProduction = $event"
        >
          <v-btn
            :value="0"
            class="menu-toggle-item"
          >
            {{ $t("All") }}
          </v-btn>
          <v-btn
            :value="1"
            class="menu-toggle-item"
          >
            {{ $t("Non-prod") }}
          </v-btn>
        </btn-toggle>

        <MenuItem
          v-for="machine in filteredMachines"
          :key="machine.id"
          :label="machine.name"
          icon-left="mdi-robot-industrial"
          :to="{
            name: AppRoutes.machine.name,
            params: { machine_id: machine.id, machine_pk: machine.pk }
          }"
        >
          <template #append>
            <v-chip
              v-if="!machine.isProduction"
              small
              class="text--secondary menu-chip text-subtitle-2"
            >
              {{ $t("Non-prod") }}
            </v-chip>
          </template>
        </MenuItem>
      </template>
    </MenuNestedLayout>

    <teleport to="#menu">
      <menu-machine
        v-if="showSubGroupMenu"
        mode="groupOnly"
        container-class="menu-nested"
        :group-context="subGroupContext"
        show
        @select="selectGroup"
        @back="closeSubGroupMenu"
      />
    </teleport>
  </div>
</template>
<script setup>
import { computed, del, onMounted, toRefs, reactive, ref, set, watch } from "vue";
import { useRoute } from "vue-router/composables";
// eslint-disable-next-line no-unused-vars
import Teleport from "vue2-teleport";

import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { useGroupsHierarchy } from "@/features/group/useGroupsHierarchy";
import { useIntl } from "@/shared/useIntl";
import { AppRoutes } from "@/shared/app-routes";
import BtnToggle from "@/components/BtnToggle.vue";
import MenuItem from "@/ui/Menu/MenuItem.vue";
import MenuNestedLayout from "@/ui/Menu/MenuNestedLayout.vue";

const props = defineProps({
  mode: { type: String, default: "" },
  groupContext: { type: Object, default: null },
  show: { type: Boolean, default: false },
  containerClass: { type: String, default: undefined }
});

const { groupContext } = toRefs(props);
const { isMobile } = useDeviceResolution();

const emit = defineEmits(["select", "machineSelect", "back"]);

const { $t } = useIntl();
const route = useRoute();

// #region Group Data fetching
const { groups } = useGroupsHierarchy();

const machineGroups = computed(() => {
  return groupContext.value
    ? groups.value.filter((group) => groupContext.value.subGroupIds.includes(group.pk))
    : groups.value || [];
});

const groupLevels = computed(() => {
  const levels = machineGroups.value?.map((group) => group.level);
  return [...new Set(levels)].reduce((acc, level) => {
    acc[level.level] = level;
    return acc;
  }, {});
});

const highestLevel = computed(() => {
  if (groupLevels.value?.length === 0) return 0;
  return Math.max(...Object.keys(groupLevels.value));
});

const subGroupTerm = ref("");
function getSubGroups(machineGroup) {
  if (machineGroup) {
    return machineGroups.value?.filter(
      (group) =>
        machineGroup.subGroupIds.includes(group.pk) &&
        group.name.toLowerCase().includes(subGroupTerm.value.toLowerCase())
    );
  }
  return machineGroups.value?.filter(
    (group) =>
      group.level?.level === highestLevel.value &&
      group.name.toLowerCase().includes(subGroupTerm.value.toLowerCase())
  );
}

function hasSubGroups(group) {
  return group.subGroupIds?.length > 0;
}

function getParentGroup(group) {
  return machineGroups.value.find((g) => g.subGroupIds.includes(group.pk));
}

function groupIconByLevel(level) {
  return level == highestLevel.value ? "mdi-factory" : "mdi-robot-industrial";
}
// #endregion

// #region Group selection
const selectedGroups = reactive({});
const selectionType = ref("group");

const selectedGroupsSorted = ref({});

function selectGroup(group) {
  Object.keys(selectedGroups).forEach((level) => {
    if (level <= group.level.level) {
      del(selectedGroups, level);
    }
  });
  set(selectedGroups, group.level.level, group);
  selectionType.value = "machines";
  emit("select", group);
}

function unselectGroup(group) {
  Object.keys(selectedGroups).forEach((level) => {
    if (level <= group.level.level) {
      del(selectedGroups, level);
    }
  });
}

function reselectGroup(group, $event) {
  if ($event.type === "mouseenter" && isMobile.value) {
    return;
  }

  const parentGroup = getParentGroup(group);
  showSubGroupMenu.value = true;
  subGroupContext.value = parentGroup;
  emit("select", group);
}

const subGroupContext = ref(null);
const showSubGroupMenu = ref(false);
function openSubGroupMenu(group, $event) {
  if ($event.type === "mouseenter" && isMobile.value) {
    return;
  }
  showSubGroupMenu.value = true;
  subGroupContext.value = group;
}
function closeSubGroupMenu() {
  showSubGroupMenu.value = false;
}

const currentGroup = ref(null);
const currentLevel = ref(null);
watch(
  [selectedGroups, groupLevels],
  () => {
    initGroupData();
  },
  { deep: true }
);

// #endregion

// #region Machine data fetching
const machines = computed(() => {
  if (currentGroup.value) {
    const allMachines = [];
    const traverseGroups = (groups) => {
      groups.forEach((group) => {
        if (group.machines) {
          allMachines.push(...group.machines);
        }
        if (group.subGroupIds && group.subGroupIds.length > 0) {
          const subGroups = machineGroups.value.filter((subGroup) =>
            group.subGroupIds.includes(subGroup.pk)
          );
          traverseGroups(subGroups);
        }
      });
    };

    traverseGroups(machineGroups.value.filter((group) => group.pk === currentGroup.value.pk));
    return [...new Set(allMachines.map((machine) => machine.id))].map((id) =>
      allMachines.find((machine) => machine.id === id)
    );
  }
  return [];
});

// #endregion
// #region Machine selection
const machineFilter = reactive({
  isProduction: ref(0),
  terms: ref("")
});

const filteredMachines = computed(() => {
  return machines.value.filter((machine) => {
    return (
      (machineFilter.isProduction == 0 || machineFilter.isProduction == !machine.isProduction) &&
      (!machineFilter.terms ||
        machine.name.toLowerCase().includes(machineFilter.terms.toLowerCase()))
    );
  });
});

// #endregion
function initGroupData() {
  selectedGroupsSorted.value = Object.values(selectedGroups).sort(
    (a, b) => b.level.level - a.level.level
  );
  currentGroup.value = [...selectedGroupsSorted.value].pop() || null;
  currentLevel.value = currentGroup.value?.level || groupLevels.value[highestLevel.value];

  if (!currentGroup.value) {
    selectionType.value = "group";
  }
  showSubGroupMenu.value = false;
}

onMounted(() => {
  initGroupData();
});

watch(route, () => {
  emit("machineSelect", currentGroup.value);
});
</script>

<style scoped lang="scss">
.menu-chip {
  border-radius: 4px;
  padding: 0 rem(8px);
  background: var(--bg-card-white) !important;
}
</style>
