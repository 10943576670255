<template>
  <div class="machineGroupSelector">
    <h4 v-if="!condensedView">{{ $t(zoneLevelName) }}</h4>
    <div
      class="option"
      v-if="!condensedView && !hideMachineGroupSelector"
    >
      <v-tabs
        class="smallFilterTabs"
        height="25"
        v-model="filterViewType"
        @change="changeFilterViewType"
      >
        <v-tab
          v-for="(v, idx) in filterViewsTypes"
          :key="idx"
          class="smalltab"
          >{{ $t(v) }}</v-tab
        >
      </v-tabs>
    </div>

    <div v-if="isGroupMachines(filterViewsTypes[filterViewType])">
      <div
        v-if="!condensedView"
        class="all-pills-container"
      >
        <div class="selected-pills">
          <h5 class="select-pills-title">
            {{ $t("Machine Groups") }}
            <button
              class="selectButton"
              v-ripple
              @click="openSelector"
            >
              <i class="fa fa-plus-circle" />
            </button>
          </h5>
          <div
            class="pills-container"
            v-if="machineGroupsSelected.length > 0"
          >
            <span
              class="pill"
              v-for="group in machineGroupsSelected"
            >
              {{ groupDict[group] && groupDict[group].name }}
            </span>
          </div>
          <div v-else>--</div>
        </div>
        <div class="selected-pills">
          <h5 class="select-pills-title">
            {{ $t("Machines") }}
            <button
              class="selectButton"
              v-ripple
              @click="openSelector"
            >
              <i class="fa fa-plus-circle" />
            </button>
          </h5>
          <div
            class="pills-container"
            v-if="machinesSelected.length > 0"
          >
            <span
              class="pill"
              v-for="m in machinesSelected"
            >
              {{ machineDict[m] && machineDict[m].name }}
            </span>
          </div>
          <div v-else>--</div>
        </div>
      </div>
      <div
        v-else
        class="condensed-view-container"
      >
        <div class="condensed-view-content">
          <span v-if="machineGroupsSelected.length === 0 && machinesSelected.length === 0">
            {{ $t("Machine Groups") }}
          </span>
          <div
            class="text-button-container"
            v-else-if="machineGroupsSelected.length > 0 || machinesSelected.length > 0"
          >
            <v-badge
              class="total-badge"
              :content="totalSelected"
            >
              {{ $t("Machine Groups") }}
            </v-badge>
            <button
              class="selectButton"
              v-ripple
              @click="openSelector"
            >
              <i class="fa fa-plus-circle" />
            </button>
          </div>
          <button
            v-if="machineGroupsSelected.length === 0 && machinesSelected.length === 0"
            class="selectButton"
            v-ripple
            @click="openSelector"
          >
            <i class="fa fa-plus-circle" />
          </button>
        </div>
      </div>
      <v-dialog
        v-model="showMachineSelector"
        content-class="machineSelectorModalComponent"
      >
        <v-card class="modalCard">
          <DialogCloseButton :onClick="hideSelector" />
          <div class="search">
            <v-text-field
              v-model="searchText"
              :placeholder="$t('Search')"
              clearable
            />
          </div>
          <div class="treeContainer">
            <list-node
              v-show="!searchText || g.searchMatch"
              v-for="(g, idx) in groupTree"
              :searchText="searchText"
              :key="idx"
              :node="g"
              :changeMachineGroups="changeMachines"
              :changeMachines="changeMachines"
              ref="nodes"
            />
          </div>
          <div class="selections">
            <div class="machine-groups">
              <h5>{{ $t("Machine Groups") }}</h5>
              <div
                class="pills-container"
                v-if="machineGroupsSelected.length > 0"
              >
                <span
                  class="pill"
                  v-for="group in machineGroupsSelected"
                >
                  {{ groupDict[group] && groupDict[group].name }}
                </span>
              </div>
              <div v-else>--</div>
            </div>
            <div class="machines">
              <h5>{{ $t("Machines") }}</h5>
              <div
                class="pills-container"
                v-if="machinesSelected.length > 0"
              >
                <span
                  class="pill"
                  v-for="m in machinesSelected"
                >
                  {{ machineDict[m] && machineDict[m].name }}
                </span>
              </div>
              <div v-else>--</div>
            </div>
          </div>
          <div class="buttonContainer">
            <v-btn @click="hideSelector">{{ $t("Close") }}</v-btn>
          </div>
        </v-card>
      </v-dialog>
    </div>
    <div v-else>
      <div class="all-pills-container" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";

import DialogCloseButton from "@/components/DialogCloseButton";

import ListNode, { SELECTSTATE } from "./listNode";

export const FILTERVIEWTYPES = {
  GROUPMACHINES: "Machine View",
  GROUPZONES: "Zones View"
};

export default {
  name: "MachineGroupSelector.vue",
  components: { ListNode, DialogCloseButton },
  props: [
    "hideMachineGroupSelector",
    "setMachineGroups",
    "setFilterViewType",
    "filterController",
    "condensedView",
    "defaultMachineGroups",
    "defaultMachines",
    "currentGroupPk"
  ],
  data() {
    return {
      groupTree: [],
      groupDict: {},
      machineDict: {},
      zoneLevelName: this.$t("Zone"),
      machineGroupsSelected: [],
      machinesSelected: [],
      showMachineSelector: false,
      machineSelector: null,
      filterViewType: 0,
      filterViewsTypes: [FILTERVIEWTYPES.GROUPMACHINES, FILTERVIEWTYPES.GROUPZONES],
      searchText: ""
    };
  },
  computed: {
    totalSelected() {
      return this.machineGroupsSelected.length + this.machinesSelected.length;
    },
    machineGroupsFiltered() {
      return this.currentGroupPk &&
        this.machineGroupsSelected.filter((item) => item != -1).length == 0
        ? [this.currentGroupPk]
        : this.machineGroupsSelected;
    }
  },
  mounted: async function () {
    await this.init();
    this.preselectMachines();
  },

  methods: {
    async init() {
      this.groupDict = {};
      this.machineDict = {};
      this.groupTree = [];

      try {
        const reportZoneLevelResponse = await this.getReportZoneLevel();
        let reportZoneLevel = 1;
        if (reportZoneLevelResponse) {
          reportZoneLevel = reportZoneLevelResponse.level;
          this.zoneLevelName = reportZoneLevelResponse.name;
        }
        const res = await this.$http.get("graphql/", {
          params: {
            query: `{machineGroups{
          name
          id
          pk
          level{
            name
            level
          }
          machines{
            name
            id
            pk
          }
          subGroupIds
          parentGroup {
            pk
            id
          }
        }
      }`
          }
        });

        const groups = res.data.data.machineGroups;
        for (const group of groups) {
          this.groupDict[group.pk] = group;
          this.groupDict.selected = SELECTSTATE.UNSELECTED;
          this.groupDict.children = [];

          for (const machine of group.machines) {
            this.machineDict[machine.pk] = machine;
          }
        }

        for (const groupId of Object.keys(this.groupDict)) {
          var g = this.groupDict[groupId];
          if (g) {
            Vue.set(g, "searchMatch", false);
          }

          if (g.machines) {
            g.machines.forEach((machine) => {
              Vue.set(machine, "searchMatch", false);
            });
          }
          const parentGroup = g.parentGroup ? g.parentGroup.pk : null;

          if (parentGroup != null) {
            if (!this.groupDict[parentGroup].children) {
              this.groupDict[parentGroup].children = [];
            }
            this.groupDict[parentGroup].children.push(g);
          }
          if (g.level && g.level.level === reportZoneLevel) {
            this.groupTree.push(g);
          }
        }
      } catch (e) {
        console.log(e);
      }
    },

    preselectMachines() {
      if (this.defaultMachines || this.defaultMachineGroups) {
        this.machinesSelected = this.defaultMachines || [];
        this.machineGroupsSelected = this.defaultMachineGroups || [];

        this.setMachineGroups([...this.machineGroupsFiltered], [...this.machinesSelected]);
        if (this.filterController) {
          let machine_groups = this.machineGroupsSelected.map((pk) => ({
            pk: pk,
            name: this.groupDict[pk] && this.groupDict[pk].name
          }));
          let machines = this.machinesSelected.map((pk) => ({
            pk: pk,
            name: this.machineDict[pk] && this.machineDict[pk].name
          }));

          this.filterController.setFilter("machine_groups", machine_groups);
          this.filterController.setFilter("machines", machines);
        }
      }
    },

    isGroupMachines(type) {
      return type === FILTERVIEWTYPES.GROUPMACHINES;
    },
    changeFilterViewType(type) {
      this.filterViewType = type;
      this.setFilterViewType(this.filterViewsTypes[type]);
    },
    openSelector() {
      this.showMachineSelector = true;
      this.machineSelector = "openMachineSelector";
    },
    hideSelector() {
      this.showMachineSelector = false;
      this.machineSelector = "closeMachineSelector";
    },
    changeMachineGroups() {
      this.machineGroupsSelected = [];
      window.requestAnimationFrame(() => {
        this.buildMachineGroupList(this.$refs.nodes);
        this.setMachineGroups(this.machineGroupsFiltered);
        if (this.filterController) {
          let machine_groups = this.machineGroupsSelected.map((pk) => ({
            pk: pk,
            name: this.groupDict[pk].name
          }));
          this.filterController.setFilter("machine_groups", machine_groups);
        }
      });
    },
    buildMachineGroupList(nodes) {
      for (const node of nodes) {
        if ("children" in node.$refs) {
          this.buildMachineGroupList(node.$refs.children);
        }
        if (node.selected === SELECTSTATE.SELECTED) {
          this.machineGroupsSelected.push(node.getId());
        }
      }
    },
    changeMachines() {
      this.machinesSelected = [];
      this.machineGroupsSelected = [];

      window.requestAnimationFrame(() => {
        this.buildMachineList(this.$refs.nodes);

        //we need to rebuild our machine group list because a node may
        //now be partially selected
        window.requestAnimationFrame(() => {
          this.buildMachineGroupList(this.$refs.nodes);
          this.setMachineGroups([...this.machineGroupsFiltered], [...this.machinesSelected]);
          if (this.filterController) {
            let machine_groups = this.machineGroupsSelected.map((pk) => ({
              pk: pk,
              name: this.groupDict[pk].name
            }));
            let machines = this.machinesSelected.map((pk) => ({
              pk: pk,
              name: this.machineDict[pk].name
            }));
            this.filterController.setFilter("machine_groups", machine_groups);
            this.filterController.setFilter("machines", machines);
          }
        });
      });
    },
    buildMachineList(nodes) {
      for (const node of nodes) {
        if (node.selected === SELECTSTATE.PARTIAL || node.selected === SELECTSTATE.SELECTED) {
          //select your machines out of your node
          for (const machine of Object.keys(node.machineValues)) {
            if (node.machineValues[machine]) {
              this.machinesSelected.push(parseInt(machine));
            }
          }
          this.buildMachineList(node.getChildrenRefs());
          // eslint-disable-next-line no-dupe-else-if
        } else if (node.selected === SELECTSTATE.SELECTED) {
          this.machineGroupsSelected.push(node.getId());
        }
      }
    },
    getReportZoneLevel() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{
          settings{
            reportZoneLevel {
              pk,
              name,
              id,
              level
            }
          }
        }`
            }
          })
          .then((res) => {
            const reportZoneLevel = res.data.data.settings[0].reportZoneLevel;
            resolve(reportZoneLevel);
          })
          .catch(() => {
            reject();
          });
      });
    },
    searchTree(node) {
      node.searchMatch = false;
      if (node.machines) {
        for (let machine of node.machines) {
          machine.searchMatch = false;
        }
      }

      if (this.searchText) {
        if (node.name.toLowerCase().includes(this.searchText.toLowerCase())) {
          node.searchMatch = true;
        }
        if (node.machines) {
          for (let machine of node.machines) {
            if (machine.name.toLowerCase().includes(this.searchText.toLowerCase())) {
              machine.searchMatch = true;
              node.searchMatch = true;
            }
          }
        }
        if (node.children) {
          for (let child of node.children) {
            if (this.searchTree(child)) {
              node.searchMatch = true;
            }
          }
        }
      }
      return node.searchMatch;
    }
  },
  watch: {
    searchText: function () {
      this.groupTree.forEach((node) => {
        this.searchTree(node);
      });
    }
  }
};
</script>

<style lang="scss">
@import "../../../scss/variables";

.machineSelectorModalComponent {
  box-shadow: none;

  .modalCard {
    padding: 20px;
    width: 80%;
    max-width: 750px;
    height: 750px;
    margin: auto;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    .search {
      position: absolute;
      top: 20px;
      left: 20px;
      right: 20px;
    }
    .treeContainer {
      flex-grow: 1;
      overflow-y: auto;
      margin-top: 60px;
    }

    .selections {
      display: flex;
      .machine-groups,
      .machines {
        width: 50%;
        max-height: 200px;
        overflow-y: auto;
        .pills-container {
          display: flex;
          flex-wrap: wrap;
        }

        .pill {
          background: gray;
          padding: 2px 5px;
          border-radius: 10px;
          font-size: 15px;
          margin: 5px;

          .theme--light & {
            background: $blue;
            color: white;
          }
        }
      }
    }

    .buttonContainer {
      display: flex;
      justify-content: flex-end;
      flex: unset;
      padding-top: 20px;

      button {
        width: unset;
      }
    }
  }
}

.machineGroupSelector {
  .select-pills-title {
    display: flex;
    align-items: center;
  }
  .selectButton {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    font-size: 18px;
    padding-left: 10px;
  }

  .all-pills-container {
    padding: 10px 20px;
    .selected-pills {
      padding: 5px 0;
      .pills-container {
        display: flex;
        flex-wrap: wrap;
        max-height: 200px;
        overflow-y: auto;
      }

      .pill {
        background: gray;
        padding: 2px 5px;
        border-radius: 10px;
        font-size: 10px;
        margin: 5px;

        .theme--light & {
          background: $blue;
          color: white;
        }
      }
    }
  }
  .condensed-view-container {
    .condensed-view-content {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      .text-button-container {
        display: flex;
        align-items: center;
        .total-badge {
          margin-right: 1rem;
        }
      }
    }
  }
}
</style>
