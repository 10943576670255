<template>
  <div class="application-issues-ticket-details">
    <ticket
      ref="ticketRef"
      :ticket_id="ticketId"
      :ticket="ticketDetails"
      :users="users"
      :assignFunc="ticketAssign"
      :respondFunc="ticketRespond"
      :subscribeFunc="ticketSubscribe"
      :unsubscribeFunc="ticketUnsubscribe"
      :closeFunc="ticketClose"
    >
      <template v-slot:extraDetails>
        <div class="issue-details">
          <div class="card">
            <div class="card-header">
              <h2>{{ $t("Application Issue Details") }}</h2>
            </div>
            <div
              class="card-content"
              v-if="ticketDetails"
            >
              <div class="detail">
                <label class="label">{{ $t("Reason") }}</label>
                <p>{{ ticketDetails.reason }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
    </ticket>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";

import Ticket from "@/components/ticket/Ticket.vue";

export default {
  name: "ApplicationIssueTicket",
  components: {
    Ticket
  },
  props: {
    ticketId: { type: String, default: "" }
  },
  data() {
    return {
      loading: false,
      ticketDetails: {},
      users: []
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    })
  },
  created() {
    this.getTicketDetails();
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: "",
      clickFunction: null
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    async getTicketDetails() {
      this.loading = true;
      const query = `
        query ($id: ID!) {
          applicationIssue(id: $id) {
            id,
            openedDate,
            openedBy,
            openedByName,
            subscribed,
            unsubscribed,
            ticketedDate,
            ticketedBy,
            ticketedByName,
            firstResponseDate,
            firstResponseBy,
            firstResponseByName,
            closedDate,
            closedBy,
            closedByName,
            factoryOrder,
            machineId,
            machineName,
            partId,
            partName,
            assetId,
            assetName,
            title,
            reference,
            reason,
            assignedTo,
            assignedToNames,
            responding,
            respondingNames,
          }
          users{
            id,
            firstName,
            lastName,
          }
        }`;
      const variables = {
        id: this.ticketId
      };
      try {
        const response = await this.$http.post("graphql/", { query, variables });
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.applicationIssue &&
          response.data.data.users
        ) {
          this.ticketDetails = {
            ...response.data.data.applicationIssue,
            machineName: response.data.data.applicationIssue.machineName || this.machineLookup(
              parseInt(response.data.data.applicationIssue.machineId, 10)
            ).name
          };
          this.users = response.data.data.users;
        }
        this.loading = false;
        this.setTitles({
          title: `<h3 class="backBtn"><i class="fa fa-chevron-left"></i> ${this.$t("Back")} </h3>`,
          mobile: `<i class="fa fa-chevron-left"/> ${this.ticketDetails.title}`,
          clickFunction: this.back
        });
      } catch (error) {
        console.error(error);
        this.loading = false;
      }
    },
    back() {
      if (window.history.length > 1) {
        window.history.back();
      } else {
        window.close();
      }
    },
    async ticketAssign(users) {
      const newUserIds = [];
      users.forEach((user) => newUserIds.push(user.id));
      const query = `mutation ($id: ID!, $applicationIssueTicketData: ApplicationIssueInput!) {
        updateApplicationIssue(globalId: $id, issueData: $applicationIssueTicketData) {
          ok,
          issue {
            assignedTo,
            assignedToNames,
          }
        }
      }`;
      const variables = {
        id: this.ticketId,
        applicationIssueTicketData: {
          assignNew: newUserIds
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        setTimeout(() => this.getTicketDetails(), 500);
      } catch (error) {
        console.error(error);
      }
    },
    async ticketRespond(pinJwt) {
      let oldJwt = this.$http.defaults.headers["Authorization"];
      if (pinJwt) {
        this.$http.defaults.headers["Authorization"] = `JWT ${pinJwt}`;
      }
      const query = `mutation ($id: ID!, $applicationIssueTicketData: ApplicationIssueInput!) {
        updateApplicationIssue(globalId: $id, issueData: $applicationIssueTicketData) {
          ok,
          issue {
            firstResponseBy,
            firstResponseByName,
            firstResponseDate,
            responding,
            respondingNames,
          }
        }
      }`;
      const variables = {
        id: this.ticketId,
        applicationIssueTicketData: {
          responding: true
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        setTimeout(() => {
          this.getTicketDetails();
          this.$refs.ticketRef.respondingToTicket = false;
        }, 500);
      } catch (error) {
        console.error(error);
      } finally {
        this.$http.defaults.headers["Authorization"] = oldJwt;
        this.pinJwt = null; // Clear pinJwt after use
      }
    },
    async ticketSubscribe() {
      const query = `mutation ($id: ID!, $applicationIssueTicketData: ApplicationIssueInput!) {
        updateApplicationIssue(globalId: $id, issueData: $applicationIssueTicketData) {
          ok,
          issue {
            subscribed,
            unsubscribed,
          }
        }
      }`;
      const variables = {
        id: this.ticketId,
        applicationIssueTicketData: {
          subscribing: true
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        setTimeout(() => this.getTicketDetails(), 500);
      } catch (error) {
        console.error(error);
      }
    },
    async ticketUnsubscribe() {
      const query = `mutation ($id: ID!, $applicationIssueTicketData: ApplicationIssueInput!) {
        updateApplicationIssue(globalId: $id, issueData: $applicationIssueTicketData) {
          ok,
          issue {
            subscribed,
            unsubscribed,
          }
        }
      }`;
      const variables = {
        id: this.ticketId,
        applicationIssueTicketData: {
          unsubscribing: true
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        setTimeout(() => this.getTicketDetails(), 500);
      } catch (error) {
        console.error(error);
      }
    },
    async ticketClose() {
      const query = `mutation ($id: ID!, $applicationIssueTicketData: ApplicationIssueInput!) {
        updateApplicationIssue(globalId: $id, issueData: $applicationIssueTicketData) {
          ok,
          issue {
            closedDate,
            closedBy,
            closedByName,
          }
        }
      }`;
      const variables = {
        id: this.ticketId,
        applicationIssueTicketData: {
          close: true
        }
      };
      try {
        await this.$http.post("graphql/", { query, variables });
        setTimeout(() => this.getTicketDetails(), 500);
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>
<style lang="scss">
@import "./TicketDetails.scss";
</style>
