<template>
  <ModalDialog
    content-class="assign-dialog"
    :value="open"
    persistent
    max-width="768px"
    full-screen-mobile
    @close="closeAssign"
  >
    <template #title> {{ $t("Assign users") }} </template>

    <template #body>
      <div class="assign-body">
        <div class="assign-col">
          <TextField
            v-model="userSearch"
            filled
            prepend-inner-icon="mdi-magnify"
            :label="$t('Search')"
            single-line
            class="custom"
            hide-details
            clearable
          />
          <div class="assignable mt-4">
            <p
              v-for="user in filteredUsers"
              :key="user.id"
              class="assign-item"
              @click="newUsers.push(user)"
            >
              {{ user.firstName }} {{ user.lastName }}
            </p>
          </div>
        </div>
        <div class="assign-col">
          <div class="text-body-1 assign-heading color--text-primary">{{ $t("Added") }}</div>
          <div class="newUsers mt-0 mt-md-4">
            <div
              v-for="(new_user, new_user_index) in newUsers"
              :key="new_user.id"
              class="newUsers-item selected"
              @click="newUsers.splice(new_user_index, 1)"
            >
              {{ new_user.firstName }} {{ new_user.lastName }}

              <div>
                <v-icon class="color--text--secondary">mdi-close</v-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #actions>
      <btn
        class="btn-assign"
        primary
        @click="closeAssign"
      >
        {{ $t("Cancel") }}
      </btn>
      <btn
        class="btn-assign"
        variant="primary"
        :disabled="newUsers.length === 0"
        :loading="assigningTicket"
        @click="helpAssign(ticket)"
      >
        {{ $t("Assign") }}
      </btn>
    </template>
  </ModalDialog>
</template>

<script>
import { mapGetters } from "vuex";
import Btn from "@/ui/Btn/Btn.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import TextField from "@/ui/TextField/TextField.vue";

export default {
  name: "TicketAssignDialog",
  components: { TextField, ModalDialog, Btn },

  props: {
    type: { type: String, default: "p911" },
    ticket: { type: Object, default: () => undefined },
    open: { type: Boolean, default: false, required: true },
    machine_group_id: { type: String, default: "", required: false }
  },

  data() {
    return {
      assignDialog: true,
      userSearch: "",
      users: [],
      newUsers: [],
      assigningTicket: false
    };
  },

  computed: {
    ...mapGetters({
      user: "session/User",
      statuses: "app/Statuses"
    }),
    filteredUsers() {
      if (!this.userSearch) {
        return this.users;
      }
      return this.users.filter((user) => {
        return `${user.firstName} ${user.lastName}`
          .toLowerCase()
          .includes(this.userSearch.toLowerCase());
      });
    }
  },

  created() {
    this.checkMachineGroupPrioritization();
  },
  methods: {
    checkMachineGroupPrioritization() {
      let query = "";
      let variables = {};
      if (this.machine_group_id) {
        query = `query getMachineGroup($id: ID!){
          machineGroup(id: $id){
            id
            ... on MachineGroupType{
              prioritization
            }
          }
          users{
            id,
            firstName,
            lastName,
          }
        }`;
        variables = { id: this.machine_group_id };
      } else {
        query = `query {
          users{
            id,
            firstName,
            lastName,
          }
        }`;
      }

      this.$http.post("graphql/", { query, variables }).then((res) => {
        if (
          res.data.data &&
          "machineGroup" in res.data.data &&
          "prioritization" in res.data.data.machineGroup
        ) {
          this.prioritization = res.data.data.machineGroup.prioritization;
        }
        this.users = res.data.data.users;
      });
    },

    closeAssign() {
      this.assignDialog = false;
      this.newUsers = [];
      this.$emit("close");
    },

    helpAssign(ticket) {
      this.assigningTicket = true;
      let newUserIds = [];
      this.newUsers.forEach((user) => {
        newUserIds.push(user.id);
      });

      let query;
      let variables;

      if (this.type === "p911") {
        query = `mutation ($id: ID!, $helpTicketData: HelpUpdateInput!){
          updateHelpTicket(globalId:$id, helpData:$helpTicketData){
            ok,
            helpTicket{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          helpTicketData: {
            assignNew: newUserIds
          }
        };
      } else {
        query = `mutation ($id: ID!, $issueData: IssueInput!){
          updateIssue(globalId:$id, issueData:$issueData){
            ok,
            issue{
              assignedTo,
              assignedToNames,
            }
          }
        }`;

        variables = {
          id: ticket.id,
          issueData: {
            assignNew: newUserIds
          }
        };
      }

      this.$http
        .post("graphql/", { query, variables })
        .then(() => {
          this.newUsers = [];
          this.assigningTicket = false;
          this.$emit("success");
          this.$emit("close");
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  }
};
</script>
<style scoped lang="scss">
@import "@/scss/input";

.assignable,
.newUsers {
  border: rem(1px) solid var(--border-secondary);
  border-radius: rem(8px);
  padding: rem(8px) rem(12px);
  height: rem(272px);
  overflow-y: auto;
}

.newUsers {
  padding: rem(12px);
}

.newUsers-item,
.assign-item {
  transition: all 0.25s ease-in-out;
  padding: rem(12px) rem(12px) rem(11px);
  border-radius: rem(8px);
  cursor: pointer;
  border: rem(1px) solid transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--text-primary);

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &:hover,
  &.selected {
    background-color: var(--bg-card-white);
    color: var(--text-primary);
  }
}

.newUsers-item {
  & ~ .newUsers-item {
    margin-top: rem(8px);
  }

  &:hover,
  &.selected {
    border: rem(1px) solid var(--border-secondary);
  }
}

.assign-heading {
  min-height: 44px;
  display: flex;
  align-items: center;

  @media (max-width: 600px) {
    min-height: 0;
    margin-top: rem(24px);
    margin-bottom: rem(12px);
  }
}

.root {
  background-color: var(--bg-secondary);
}

.btn-assign {
  min-width: 168px !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }
  }
}
</style>

<style lang="scss">
.assign-dialog {
  .body.v-card__text {
    padding-top: 16px !important;
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    .body {
      padding-top: 0 !important;
      padding-bottom: 16px !important;
    }
    .footer {
      padding-top: 16px !important;
      padding-bottom: 12px !important;
    }
  }
}

.assign-body {
  @media (min-width: map-get($grid-breakpoints, "md")) {
    gap: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
</style>
