<template>
  <div>
    <MenuNestedLayout
      :show="show"
      :back-label="$t('Back')"
      :show-level1="mode === 'locationOnly' || selectionType !== 'assets'"
      :level-name="locationContext?.name || 'Groups'"
      :level1-search="subLocationTerm"
      :level2-search="assetFilter.terms"
      @level1-search="subLocationTerm = $event"
      @level2-search="assetFilter.terms = $event"
      @back="emit('back', $event)"
      @level2-hover-body="closeSubLocationMenu"
    >
      <template #level1List>
        <MenuItem
          v-for="location in assetLocations"
          :key="location.pk"
          :icon-left="locationIconByDepth(location.depth)"
          :label="location.name"
          :icon-right="location.children.length > 0 ? 'mdi-chevron-right' : ''"
          @click="selectLocation(location)"
        />
      </template>

      <template #level2Header>
        <div>
          <MenuItem
            v-for="selectedLocation in Object.values(selectedLocations)"
            :key="selectedLocation.id"
            :active="true"
            :label="selectedLocation.name"
            icon-right="mdi-chevron-right"
            icon-right-size="24"
            show-close-icon
            @click="reselectLocation(selectedLocation)"
            @click-close-icon="removeLocation(selectedLocation)"
          />

          <MenuItem
            v-if="hasChildLocations(currentLocation)"
            key="sublocation"
            :label="$t('Sub-groups')"
            icon-right="mdi-chevron-right"
            @mouseenter="openSubLocationMenu(currentLocation, $event)"
            @click="openSubLocationMenu(currentLocation, $event)"
          />
        </div>
      </template>

      <template #level2BodyList>
        <MenuItem
          :to="{
            name: 'aeyevision/group',
            params: {
              groupId: currentLocation.pk,
              group: { ...currentLocation },
              type: 'group'
            }
          }"
          :label="$t('{group} Assets', { group: currentLocation.name })"
          icon-right="mdi-chevron-right"
        />

        <MenuItem
          v-for="asset in filteredAssets"
          :key="asset.id"
          icon-left="mdi-robot-industrial"
          :to="{
            name: 'aeyevision/asset',
            params: {
              assetId: asset.pk || asset.id,
              asset: { ...asset, pk: asset.pk || asset.id },
              type: 'asset'
            }
          }"
          :label="asset.name"
          icon-right="mdi-chevron-right"
        />
      </template>
    </MenuNestedLayout>

    <teleport to="#menu">
      <menu-asset
        v-if="showSubLocationMenu"
        container-class="menu-nested"
        mode="locationOnly"
        :location-context="subLocationContext"
        show
        @select="selectLocation"
        @back="closeSubLocationMenu"
      />
    </teleport>
  </div>
</template>
<script setup>
import { computed, del, reactive, ref, set, watch } from "vue";
import { useRoute } from "vue-router/composables";
// TODO: Improve/update lint rules so it detects the usage in the template
// eslint-disable-next-line no-unused-vars
import Teleport from "vue2-teleport";

import { useAssetQuery } from "@/features/asset/useAssetQuery";
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { useIntl } from "@/shared/useIntl";
import MenuItem from "@/ui/Menu/MenuItem.vue";
import MenuNestedLayout from "@/ui/Menu/MenuNestedLayout.vue";

const props = defineProps({
  mode: { type: String, default: "" },
  show: { type: Boolean, default: false },
  locationContext: { type: Object, default: null },
  containerClass: { type: String, default: '' }
});
const emit = defineEmits(["select", "assetSelect"]);

const { $t } = useIntl();
const route = useRoute();
const { isMobile } = useDeviceResolution();

// #region Location Data fetching

const depth = computed(() => (props.locationContext?.depth || 0) + 1);
const treeId = computed(() => props.locationContext?.treeId || 0);
const lft = computed(() => props.locationContext?.lft || 0);
const rgt = computed(() => props.locationContext?.rgt || 0);

const { locations } = useAssetQuery(depth, treeId, lft, rgt);
const subLocationTerm = ref("");
const assetLocations = computed(
  () =>
    locations.value?.filter((location) =>
      subLocationTerm.value
        ? location.name.toLowerCase().includes(subLocationTerm.value.toLowerCase())
        : true
    ) || []
);

function getParentLocation(location) {
  return selectedLocations[location.depth - 1] || null;
}

function locationIconByDepth(depth) {
  return depth == 1 ? "mdi-factory" : "mdi-robot-industrial";
}

const hasChildLocations = (location) => {
  try {
    return location?.children && JSON.parse(location.children)[0]?.children?.length > 0;
  } catch (error) {
    return false;
  }
};
// #endregion

// #region Location Selection
const currentLocation = ref(null);
const selectionType = ref("location");

const selectedLocations = reactive({});
function selectLocation(location) {
  removeLocation(location);
  set(selectedLocations, location.depth, location);
  selectionType.value = "assets";
  emit("select", location);
}

function removeLocation(location) {
  Object.keys(selectedLocations).forEach((depth) => {
    if (depth >= location.depth) {
      del(selectedLocations, depth);
    }
  });
}

const subLocationContext = ref(null);
const showSubLocationMenu = ref(false);

function reselectLocation(location) {
  const parentLocation = getParentLocation(location);
  showSubLocationMenu.value =
    parentLocation?.pk !== subLocationContext.value?.pk || !showSubLocationMenu.value;
  subLocationContext.value = parentLocation;
  emit("select", location);
}

function openSubLocationMenu(location, event) {
  if (event?.type === "mouseenter" && isMobile.value) {
    return;
  }
  showSubLocationMenu.value = true;
  subLocationContext.value = location;
}
function closeSubLocationMenu(event) {
  if (event?.type === "mouseenter" && isMobile.value) {
    return;
  }
  showSubLocationMenu.value = false;
}

watch(
  selectedLocations,
  () => {
    currentLocation.value = Object.values(selectedLocations).pop() || null;

    if (!currentLocation.value) {
      selectionType.value = "location";
    }
  },
  { deep: true }
);

watch([() => props.show, selectedLocations], () => {
  showSubLocationMenu.value = false;
});

// #endregion

// #region Asset selection
const assets = computed(() =>
  currentLocation.value?.childAssets ? JSON.parse(currentLocation.value.childAssets) : []
);

const assetFilter = reactive({
  terms: ref("")
});
const filteredAssets = computed(() => {
  return assets.value.filter((asset) => {
    return (
      !assetFilter.terms || asset.name?.toLowerCase().includes(assetFilter.terms.toLowerCase())
    );
  });
});
// #endregion

watch(route, () => {
  emit("assetSelect", currentLocation.value);
});
</script>
