<template>
  <div class="machine_summary_container">
    <div class="fixed-tabs-container">
      <div v-if="!manualStatusMachines.length">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-on="on">
              <v-btn
                color="primary"
                @click="statusEntry = true"
                class="ml-8"
                :disabled="!manualStatusMachines.length"
                v-bind="attrs"
              >
                {{ $t("Set Status") }}
              </v-btn>
            </div>
          </template>
          <span>{{ $t("No machines with manual status.") }}</span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-btn
          color="primary"
          @click="statusEntry = true"
          class="ml-8"
          :disabled="!manualStatusMachines.length"
        >
          {{ $t("Set Status") }}
        </v-btn>
      </div>
      <v-dialog
        content-class="machineStatusDialog"
        v-model="statusEntry"
        persistent
        max-width="400px"
      >
        <v-card>
          <DialogCloseButton :onClick="cancelStatusEntry" />
          <v-card-title class="headline">
            {{ $t("Update Machine Status") }}
          </v-card-title>

          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                :complete="step > 1"
                step="1"
                >{{ $t("Select Machines") }}
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                :complete="step > 2"
                step="2"
                >{{ $t("Select Status") }}
              </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
              <!-- Step 1 -->
              <v-stepper-content step="1">
                <v-card-text>
                  <div class="mt-2 ml-2">
                    <v-checkbox
                      v-for="machine in manualStatusMachines"
                      :key="machine.machine_id"
                      :label="machine.machine_name"
                      :value="machine.machine_id"
                      v-model="selectedMachines"
                      class="my-0 py-0"
                    >
                    </v-checkbox>
                    <div style="display: flex; justify-content: space-around; align-items: center">
                      <v-btn
                        @click="selectAllMachines"
                        :disabled="selectedMachines.length === manualStatusMachines.length"
                      >
                        {{ $t("Select All") }}
                      </v-btn>
                      <v-btn
                        @click="deselectAllMachines"
                        :disabled="selectedMachines.length === 0"
                      >
                        {{ $t("Deselect All") }}
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="cancelStatusEntry"
                  >
                    {{ $t("Cancel") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    primary
                    text
                    :disabled="!selectedMachines.length"
                    @click="step = 2"
                  >
                    {{ $t("Next") }}
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>

              <!-- Step 2 -->
              <v-stepper-content step="2">
                <v-card-text>
                  <div
                    v-for="(status, status_index) in downtimeStatues"
                    :key="status_index"
                    class="mb-2"
                  >
                    <v-btn
                      class="status-btn"
                      :class="machineStatusClassName(status)"
                      @click="newSelectedStatus = status"
                    >
                      {{ status.name }}
                    </v-btn>
                    <div
                      v-if="newSelectedStatus && newSelectedStatus.id === status.id"
                      v-for="(code, code_index) in status.statuscodeSet"
                      :key="code_index"
                      class="ml-4"
                    >
                      <v-btn
                        class="code-btn mt-1"
                        :class="`${machineStatusClassName(newSelectedStatus)}`"
                        @click="newCode = code"
                      >
                        {{ code.code }} - {{ code.description }}
                      </v-btn>
                    </div>
                  </div>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="error"
                    text
                    @click="step = 1"
                  >
                    {{ $t("Back") }}
                  </v-btn>
                  <v-btn
                    color="primary"
                    primary
                    text
                    :disabled="!newCode || !selectedMachines.length"
                    :loading="statusUpdateLoading"
                    @click="saveStatus"
                  >
                    {{ $t("Save") }}
                  </v-btn>
                </v-card-actions>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-dialog>
      <div class="fixed-tabs-spacer" />
      <v-tabs
        fixed-tabs
        class="page_tabs"
        v-model="summaryPage"
        @change="
          (tab) => {
            this.summaryPage = tab;
          }
        "
      >
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab key="cards">
          <i class="fa fa-id-card" />
        </v-tab>
        <v-tab key="table">
          <i class="fa fa-table" />
        </v-tab>
      </v-tabs>
    </div>
    <div class="machine-summary row">
      <div class="filters card col-xs-12 row">
        <v-autocomplete
          class="col-xs-12 col-sm-6 col-md-3"
          v-model="filters.machine"
          :items="filterOptions.machines"
          :menu-props="{ maxHeight: '400', closeOnClick: true }"
          :label="$t('Machines')"
          clearable
          hide-details
        >
        </v-autocomplete>
        <v-autocomplete
          class="col-xs-12 col-sm-6 col-md-3"
          v-model="filters.part"
          :items="filterOptions.parts"
          :menu-props="{ maxHeight: '400', closeOnClick: true }"
          :label="$t('Parts')"
          clearable
          hide-details
        >
        </v-autocomplete>
        <v-autocomplete
          class="col-xs-12 col-sm-6 col-md-3"
          v-model="filters.job"
          :items="filterOptions.jobs"
          :menu-props="{ maxHeight: '400', closeOnClick: true }"
          :label="$t('Jobs')"
          clearable
          hide-details
        >
        </v-autocomplete>
        <v-autocomplete
          class="col-xs-12 col-sm-6 col-md-3"
          v-model="filters.status"
          :items="filterOptions.statuses"
          :menu-props="{ maxHeight: '400', closeOnClick: true }"
          :label="$t('Statuses')"
          :item-text="getStatusCodeText"
          item-value="code"
          clearable
          hide-details
        >
        </v-autocomplete>
      </div>
      <div
        class="machine-summary-cards-container"
        v-if="summaryPage == 0"
      >
        <div class="headerContainer">
          <div class="datesbox">
            <div class="shift_performance blue-title">
              <span v-if="fromDate && toDate && calendarCase > 2">
                <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY") }} -</h4>
                <h4>{{ toDate && toDate.format("MMMM DD, YYYY") }}</h4>
              </span>
              <span v-else-if="fromDate && toDate && calendarCase == 0">
                <h4 v-if="currentShift">{{ currentShift.name }}</h4>
                <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY h:mma") }} -</h4>
                <h4>{{ toDate && toDate.format("MMMM DD, YYYY h:mma") }}</h4>
              </span>
              <span v-else-if="fromDate && toDate">
                <h4>{{ fromDate && fromDate.format("MMMM DD, YYYY") }}</h4>
              </span>
              <span v-else>
                <h4>{{ $t("No Shift Data") }}</h4>
              </span>
            </div>
          </div>

          <div class="box intervalSelection">
            <v-tabs
              @change="ChangeCalendar"
              fixed-tabs
              :value="calendarCase"
            >
              <v-tab>
                <template v-if="shifts && shifts.length > 1">
                  <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                      <v-btn
                        style="padding: 0"
                        plain
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t("Shift") }}
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="shift in shifts"
                        :key="shift.id"
                        @click="selectShift(shift)"
                      >
                        <v-list-item-title>{{ shift.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <template v-else>
                  {{ $t("Shift") }}
                </template>
              </v-tab>
              <v-tab>{{ productionDay.clone().subtract(1, "day").format("MMM DD") }}</v-tab>
              <v-tab>{{ productionDay.clone().format("MMM DD") }}</v-tab>
              <v-tab>{{ $t("Week") }}</v-tab>
              <v-tab>{{ $t("Month") }}</v-tab>
              <v-tab>{{ $t("Year") }}</v-tab>
            </v-tabs>
          </div>
        </div>
        <v-progress-linear
          v-if="!machinesLoaded"
          indeterminate
        />
        <div
          class="col-xs-12 col-md-6 col-xl-4 cards-container"
          v-for="(machine, machine_index) in filteredMachines"
          :key="machine_index"
        >
          <router-link
            class="machine-link"
            :to="{
              name: 'machine',
              params: {
                machine_id: machinesLookup[machine.machine_id].id,
                machine_pk: machine.machine_id
              }
            }"
          >
            <div class="machine-card card">
              <div
                class="text-details"
                :class="machine.statusClassName"
              >
                <div>
                  <h3>{{ machine.machine_name }}</h3>
                  <div class="columns">
                    <div class="column">
                      <div>
                        <p class="label">{{ $t("Job") }}</p>
                        <p>{{ machine.factory_order ? machine.factory_order : $t("None") }}</p>
                      </div>
                      <div>
                        <p class="label">{{ $t("Part") }}</p>
                        <p>{{ machine.part_number }}</p>
                      </div>
                      <div>
                        <p class="label">{{ $t("Tool") }}</p>
                        <p>{{ machine.tool_number }}</p>
                      </div>
                    </div>
                    <div class="column">
                      <div>
                        <p class="label">{{ $t("Produced") }}</p>
                        <p>{{ numberWithCommas(machine.produced) }} {{ machine.uom }}</p>
                      </div>
                      <div>
                        <p class="label">{{ $t("Scrapped") }}</p>
                        <p>{{ numberWithCommas(machine.scrapped.value) }} {{ machine.uom }}</p>
                      </div>
                      <div>
                        <p class="label">{{ $t("Downtime") }}</p>
                        <p>
                          {{ seconds(machine.duration.value - machine.runtime.duration.value) }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="status">
                  <h4>{{ machine.status }}</h4>
                </div>
              </div>
              <div class="bars-trends-container">
                <div class="bars">
                  <div class="performance bar-container">
                    <p class="barLabel">
                      <span>{{ $t("Performance") }}</span>
                      {{
                        (
                          (machine.production_target.value
                            ? machine.produced / machine.production_target.value
                            : 0) * 100
                        ).toFixed(2) + "%"
                      }}
                      - {{ numberWithCommas(machine.produced) }} {{ machine.uom }}
                    </p>
                    <div class="barBackground"></div>
                    <div
                      class="bar"
                      :style="{
                        width: (machine.produced / machine.production_target.value) * 100 + '%'
                      }"
                      :class="
                        getCls(
                          machine,
                          (machine.produced / machine.production_target.value) * 100,
                          'performanceTarget'
                        )
                      "
                    ></div>
                    <div
                      class="target goodCaret"
                      :style="{
                        left: machinesLookup[machine.machine_id]['performanceTarget'] + '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>{{ machinesLookup[machine.machine_id]["performanceTarget"] }}</p>
                    </div>
                    <div
                      class="target warningCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['performanceTarget'] +
                          tolerances.warningTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["performanceTarget"] +
                          tolerances.warningTolerance
                        }}
                      </p>
                    </div>
                    <div
                      class="target badCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['performanceTarget'] +
                          tolerances.badTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["performanceTarget"] +
                          tolerances.badTolerance
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="quality bar-container">
                    <p class="barLabel">
                      <span>{{ $t("Quality") }}</span>
                      {{
                        (
                          (machine.produced
                            ? (machine.produced - machine.scrapped.value) / machine.produced
                            : 0) * 100
                        ).toFixed(2) + "%"
                      }}
                      - {{ numberWithCommas(machine.scrapped.value) }} {{ machine.uom }}
                    </p>
                    <div class="barBackground"></div>
                    <div
                      class="bar"
                      :style="{
                        width:
                          ((machine.produced - machine.scrapped.value) / machine.produced) * 100 +
                          '%'
                      }"
                      :class="
                        getCls(
                          machine,
                          ((machine.produced - machine.scrapped.value) / machine.produced) * 100,
                          'qualityTarget'
                        )
                      "
                    ></div>
                    <div
                      class="target goodCaret"
                      :style="{ left: machinesLookup[machine.machine_id]['qualityTarget'] + '%' }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>{{ machinesLookup[machine.machine_id]["qualityTarget"] }}</p>
                    </div>
                    <div
                      class="target warningCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['qualityTarget'] +
                          tolerances.warningTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["qualityTarget"] +
                          tolerances.warningTolerance
                        }}
                      </p>
                    </div>
                    <div
                      class="target badCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['qualityTarget'] +
                          tolerances.badTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["qualityTarget"] +
                          tolerances.badTolerance
                        }}
                      </p>
                    </div>
                  </div>
                  <div class="availability bar-container">
                    <p class="barLabel">
                      <span>{{ $t("Availability") }}</span>
                      {{
                        machine.duration.value > 0
                          ? (
                              (machine.runtime.duration.value / machine.duration.value) *
                              100
                            ).toFixed(2) + "%"
                          : "0%"
                      }}
                    </p>
                    <div class="barBackground"></div>
                    <div
                      class="bar"
                      :style="{
                        width:
                          machine.duration.value > 0
                            ? (machine.runtime.duration.value / machine.duration.value) * 100 + '%'
                            : 0
                      }"
                      :class="
                        getCls(
                          machine,
                          (machine.runtime.duration.value / machine.duration.value) * 100,
                          'runtimeTarget'
                        )
                      "
                    ></div>
                    <div
                      class="target goodCaret"
                      :style="{ left: machinesLookup[machine.machine_id]['runtimeTarget'] + '%' }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>{{ machinesLookup[machine.machine_id]["runtimeTarget"] }}</p>
                    </div>
                    <div
                      class="target warningCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['runtimeTarget'] +
                          tolerances.warningTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["runtimeTarget"] +
                          tolerances.warningTolerance
                        }}
                      </p>
                    </div>
                    <div
                      class="target badCaret"
                      v-if="tolerances"
                      :style="{
                        left:
                          machinesLookup[machine.machine_id]['runtimeTarget'] +
                          tolerances.badTolerance +
                          '%'
                      }"
                    >
                      <p class="line">|</p>
                      <i class="fa fa-caret-up" />
                      <p>
                        {{
                          machinesLookup[machine.machine_id]["runtimeTarget"] +
                          tolerances.badTolerance
                        }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="trends">
                  <div v-html="getPerfTrend(machinesLookup[machine.machine_id])"></div>
                  <div v-html="getQualTrend(machinesLookup[machine.machine_id])"></div>
                  <div v-html="getAvailTrend(machinesLookup[machine.machine_id])"></div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="machine-summary-table-container"
        v-if="summaryPage == 1"
      >
        <MachineSummaryTable
          :filters="filters"
          :machine_group_id="machine_group_id"
          :machine_group_pk="machine_group_pk"
          :machinesLookup="machinesLookup"
          :from-date="fromDate"
          :to-date="toDate"
          :set-from-date="
            (date) => {
              this.fromDate = date;
            }
          "
          :set-to-date="
            (date) => {
              this.toDate = date;
            }
          "
          :setCalendarCase="
            (e) => {
              this.calendarCase = e;
            }
          "
          :calendarCase="calendarCase"
        />
      </div>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mq";

.theme--light {
  .machine-summary {
    .machine-link {
      .barLabel {
        color: $light-color-val;
      }
    }
  }
}

.machine_summary_container {
  //flex: 1;
  display: flex;
  flex-flow: column;
  overflow: hidden;

  .fixed-tabs-container {
    display: flex;
    flex-direction: row;

    .fixed-tabs-spacer {
      flex: 1;
    }

    .page_tabs {
      flex: unset;
      width: 250px;

      i {
        font-size: 24px;
      }
    }
  }

  .headerContainer {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    align-items: end;
    padding-bottom: 20px;
    width: 100%;

    .datesbox {
      max-width: 300px;
      margin-top: 1rem;
      margin-left: 1rem;
    }
    .blue-title {
      color: #44b2f3;
    }
  }

  .machine-card {
    .text-details {
      min-height: 240px;
      padding: 0.5rem;
      width: 14rem;
      flex: unset;
      display: flex;
      flex-flow: column;
      justify-content: space-between;

      @media screen and(max-width: 600px) {
        min-height: unset;
      }
    }
  }

  @media screen and(max-width: 1200px) {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    padding-top: 20px !important;

    .fixed-tabs-container {
      .fixed-tabs-spacer {
        flex: 0;
      }

      .page_tabs {
        flex: 1;
        width: unset;
      }
    }
  }

  @media screen and(max-width: 699px) {
    padding-top: 0px !important;
  }

  .machine-summary-cards-container {
    flex: 1;
    flex-flow: row wrap;
    display: flex;
  }

  .machine-summary-table-container {
    flex: 1;
    flex-flow: column;
    display: flex;
    margin: 1rem 1rem;

    @media screen and(max-width: 1200px) {
      margin: 1rem 0.5rem;
    }
  }

  .machine-summary {
    flex: 1;
    display: flex;
    padding: 1rem;
    overflow-y: auto;
    margin: 0;
    margin-top: 0;
    padding-top: 0;
    align-content: flex-start;
    flex-flow: column;
    overflow-x: hidden;

    .v-progress-linear {
      margin-top: 10px;
    }

    @media screen and(max-width: 1200px) {
      flex: 1 !important;
      margin: 0;
      margin-bottom: 0px;
      padding-bottom: 20px;
      height: unset;
      max-height: unset;
      flex-flow: column;

      .cards-container {
        height: unset;
        flex: unset;
      }
    }

    .filters {
      min-height: unset;
      padding: 1rem;
      margin: 0 1rem;
      width: calc(100% - 2rem);
      flex-basis: calc(100% - 2rem);
      z-index: 99;
      flex: unset;

      @media screen and(max-width: 1200px) {
        background: none;
        background-color: transparent !important;
        box-shadow: none !important;
        margin: 0;
        padding: 0;
        flex: unset;
        width: 100%;
      }

      .v-input__control {
        margin: 0 0.5rem;
      }
    }

    a.machine-link {
      text-decoration: none;
      color: white;
    }

    .machine-card {
      margin: 0.25rem;
      padding: 0;
      display: flex;
      overflow: hidden;

      @media screen and(max-width: 600px) {
        flex-flow: column;

        .text-details {
          width: unset !important;

          .columns {
            flex-flow: column;

            .column {
              display: flex;
              justify-content: space-between;
              width: unset !important;

              p {
                font-size: 14px;
              }

              div {
                width: 33%;
              }
            }
          }
        }
      }

      @media screen and(max-width: 1200px) {
        .trends {
          height: unset !important;
        }

        h3 {
          font-size: 1.25rem !important;
        }

        .status {
          h4 {
            background: #ffffff29;
            padding: 5px;
            font-size: 16px !important;
            margin-top: 10px;
          }
        }
      }

      .text-details {
        background-color: $blue;
        padding: 0.5rem;
        width: 14rem;
        flex: unset;

        h3 {
          font-size: 1.5rem;
          font-weight: 600;
          text-align: center;
        }

        h4 {
          font-size: 1.25rem;
          text-align: center;
        }

        .columns {
          display: flex;
          justify-content: space-between;

          .column {
            width: calc(50% - 0.5rem);

            .label {
              margin-bottom: -0.25rem;
            }
          }
        }
      }

      .bars-trends-container {
        display: flex;
        flex: 1;
        padding-top: 10px;
        padding-bottom: 15px;

        @media screen and(max-width: 600px) {
          .trends {
            margin-left: 0 !important;
            margin-right: 0.6rem !important;
          }
        }

        .bars {
          flex-grow: 1;
          margin: 0 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          min-width: 250px;

          .bar-container {
            position: relative;
            height: 4rem;
            width: 100%;

            .barLabel {
              position: absolute;
              top: 0;
              font-weight: 500;

              span {
                font-weight: 400;
              }
            }

            .barBackground {
              position: absolute;
              top: 2rem;
              background-color: rgba(0, 0, 0, 0.6);
              height: 0.5rem;
              width: 100%;
              border-radius: 0.15rem;
              z-index: 1;
            }

            .target {
              position: absolute;
              bottom: 0;
              height: 2rem;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              transform: translateX(-50%);

              p {
                font-size: 0.85rem;
                text-align: center;
                margin-top: -0.25rem;
              }

              i {
                text-align: center;
                margin: 0;
                margin-top: -0.5rem;
              }

              .line {
                font-size: 1rem;
              }

              &.goodCaret {
                color: #78c47d;
              }

              &.warningCaret {
                color: #e0cc6b;
              }

              &.badCaret {
                color: #f46c6a;
              }
            }

            .bar {
              position: absolute;
              top: 2rem;
              z-index: 99;
              height: 0.5rem;
              background-color: $blue;
              border-radius: 0.15rem;
              max-width: 100%;

              &.goodBar {
                background-color: #78c47d;
              }

              &.warningBar {
                background-color: #e0cc6b;
              }

              &.badBar {
                background-color: #f46c6a;
              }
            }
          }
        }

        .trends {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          margin-left: 0.5rem;
          margin-right: 1rem;

          .trend {
            display: flex;
            width: 2rem;
            height: 2rem;
            justify-content: center;
            align-items: center;
            border-radius: 2rem;

            &.up {
              background-color: #78c47d;
            }

            &.flat {
              background-color: #e0cc6b;
            }

            &.down {
              background-color: #f46c6a;
            }

            &.hidden {
              opacity: 0;
            }

            i {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }

  @include media_below(500px) {
    .machine-summary {
      .machine-card {
        flex-wrap: wrap;
        height: auto;

        .text-details {
          width: 100%;

          .columns {
            flex-wrap: wrap;

            .column {
              width: 100%;
              display: flex;
              justify-content: space-between;

              div {
                width: 33%;
              }
            }
          }
        }

        .bars {
          padding: 0.5rem;
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import DialogCloseButton from "@/components/DialogCloseButton";
import { numberWithCommas, seconds } from "@/utils/filters";

import DataTable from "../../../components/dataTable";
import PaginatedChart from "../../analytics/operator/PaginatedChart";
import MachineSummaryTable from "./MachineSummaryTable";

import { useMachineStatusClassName } from "@/features/machine-status/hooks/useMachineStatusClassName";

export default {
  props: ["machine_group_id", "machine_group_pk", "machinesLookup"],
  data() {
    return {
      tableHeaders: [
        { text: this.$t("Machine"), value: "equipment" },
        { text: this.$t("Status"), value: "status" },
        { text: this.$t("Job"), value: "job" },
        { text: this.$t("Part #"), value: "part" },
        { text: this.$t("Description"), value: "description" },
        { text: this.$t("Total Time"), value: "duration" },
        { text: this.$t("Run Time"), value: "run_time" },
        { text: this.$t("Produced"), value: "produced" },
        { text: this.$t("Scrapped"), value: "scrapped" },
        { text: this.$t("Downtime"), value: "downtime" },
        { text: this.$t("P(%)"), value: "performance" },
        { text: this.$t("Q(%)"), value: "quality" },
        { text: this.$t("A(%)"), value: "availability" },
        { text: this.$t("OEE(%)"), value: "oee" }
      ],
      filters: {
        machine: null,
        status: null,
        part: null,
        job: null
      },
      filterOptions: {
        machines: [],
        statuses: [],
        codes: [],
        parts: [],
        jobs: []
      },
      shifts: [],
      machines: [],
      machinesLoaded: false,
      machinesTimeout: null,
      tolerances: false,
      trendUp: `<div class="trend up">
          <i class='mdi mdi-trending-up'/>
        </div>`,
      trendStable: `<div class="trend flat">
          <i class='mdi mdi-trending-neutral'/>
        </div>`,
      trendDown: `<div class="trend down">
          <i class='mdi mdi-trending-down'/>
        </div>`,
      trendNeutral: `<div class="trend flat">
          <i class='mdi mdi-trending-neutral'/>
        </div>`,
      summaryPage: 0,
      fromDate: moment().startOf("day"),
      toDate: moment().endOf("day"),
      shiftStart: null,
      shiftEnd: null,
      calendarCase: 2,
      newSelectedStatus: false,
      newCode: false,
      statusUpdateLoading: false,
      statusEntry: false,
      selectedMachines: [],
      step: 1,
      productionDay: moment(),
      currentShift: false
    };
  },
  components: {
    DataTable,
    PaginatedChart,
    MachineSummaryTable,
    DialogCloseButton
  },
  created() {
    this.loadVarThresholds();
    this.loadShiftInfo();
    this.loadMachines();
  },
  beforeDestroy() {},
  computed: {
    ...mapGetters({
      statuses: "app/Statuses"
    }),
    filteredMachines() {
      let filteredMachines = [];
      this.machines.forEach((machine, i) => {
        let status = this.getStatus(machine.status_code);
        machine.statuscode = machine.status_code;
        if (!status.running && machine.pending_status) {
          machine.statuscode = machine.pending_status;
          status = this.getStatus(machine.pending_status);
        }

        if (status) {
          machine.status = `${machine.statuscode} - ${status.name}`;
          machine.statusClassName = useMachineStatusClassName(status);
        }

        let include = true;
        if (this.filters.machine && this.filters.machine != machine.machine_name) {
          include = false;
        }
        if (this.filters.part && this.filters.part != machine.part_number) {
          include = false;
        }
        if (this.filters.job && this.filters.job != machine.factory_order) {
          include = false;
        }
        if (this.filters.status && this.filters.status != machine.current_status) {
          include = false;
        }
        if (include) {
          filteredMachines.push(machine);
        }
      });
      return filteredMachines;
    },
    manualStatusMachines() {
      const productionStatusCodes = this.statuses
        .filter((status) => status.statuscodeSet.some((statusCode) => status.running))
        .flatMap((status) =>
          status.statuscodeSet
            .filter((statusCode) => status.running)
            .map((statusCode) => statusCode.code)
        );
      return this.filteredMachines
        .filter(
          (machine) =>
            machine["manual_status"] && !productionStatusCodes.includes(machine.statuscode)
        )
        .sort((a, b) => a.machine_name.localeCompare(b.machine_name));
    },
    downtimeStatues() {
      return this.statuses.filter((status) => !status.running);
    }
  },
  methods: {
    seconds,
    numberWithCommas,
    moment,
    loadShiftInfo() {
      return new Promise((resolve, reject) => {
        this.$http
          .get("graphql/", {
            params: {
              query: `{shifts(current:true${this.machine_group_pk ? `, machineGroupId: ${this.machine_group_pk}` : ""}){
                name
                shiftDays(current:true){
                  lastStartDatetime
                  nextStartDatetime
                  nextEndDatetime
                }
              }
            }`
            }
          })
          .then((res) => {
            this.currentShift = false;
            if (res.data.data.shifts.length > 0) {
              let latestShift = null;
              let latestDatetime = null;
              this.shifts = res.data.data.shifts;
              res.data.data.shifts.forEach((shift) => {
                shift.shiftDays.forEach((day) => {
                  const currentDatetime = new Date(day.lastStartDatetime);
                  if (!latestDatetime || currentDatetime > latestDatetime) {
                    latestDatetime = currentDatetime;
                    latestShift = shift;
                  }
                });
              });
              if (latestDatetime) {
                this.productionDay = moment(latestDatetime).startOf("day");
                this.currentShift = latestShift;
                if (latestShift.shiftDays.length > 0) {
                  this.shiftStart = new moment(latestShift.shiftDays[0].lastStartDatetime);
                  this.shiftEnd = new moment(latestShift.shiftDays[0].nextEndDatetime);
                }
                resolve(latestShift);
              } else {
                resolve(null);
              }
            }
          })
          .catch((e) => {
            reject();
          });
      });
    },
    selectShift(shift) {
      this.calendarCase = 0;
      this.currentShift = shift;
      if (shift.shiftDays.length > 0) {
        this.shiftStart = new moment(shift.shiftDays[0].lastStartDatetime);
        this.shiftEnd = new moment(shift.shiftDays[0].nextEndDatetime);
      }
      this.fromDate = moment(this.shiftStart);
      this.toDate = moment(this.shiftEnd);
      this.loadMachines();
    },
    loadMachines() {
      this.machinesLoaded = false;
      this.filterOptions = {
        machines: [],
        statuses: [],
        codes: [],
        parts: [],
        jobs: []
      };
      let params = {
        machine_group_id: this.machine_group_pk,
        start_date: this.fromDate.toISOString(),
        end_date: this.toDate.toISOString(),
        fields: [
          "factory_order",
          "lot_size",
          "part_number",
          "machine_name",
          "machine_id",
          "tool_number",
          "uom",
          "status_code",
          "manual_status"
        ],
        metrics: ["production_target", "scrapped", "duration", "runtime"]
      };
      this.$http.get("metrics/job_status/", { params: params }).then((res) => {
        this.machines = res.data;
        this.machines.forEach((machine, i) => {
          this.filterOptions.machines.push(machine.machine_name);
          this.filterOptions.jobs.push(machine.factory_order);
          if (!this.filterOptions.parts.includes(machine.part_number)) {
            this.filterOptions.parts.push(machine.part_number);
          }
          if (
            machine.pending_status &&
            !this.filterOptions.codes.includes(machine.pending_status)
          ) {
            this.filterOptions.codes.push(machine.pending_status);
            this.filterOptions.statuses.push(this.getStatusCode(machine.pending_status));
          } else if (!this.filterOptions.codes.includes(machine.status_code)) {
            this.filterOptions.codes.push(machine.status_code);
            this.filterOptions.statuses.push(this.getStatusCode(machine.status_code));
          }
        });

        this.machinesLoaded = true;
        if (this.machinesTimeout) {
          clearTimeout(this.machinesTimeout);
        }
        let loadMachines = this.loadMachines;
        this.machinesTimeout = setTimeout(loadMachines, 300000);
      });
    },

    ChangeCalendar(e) {
      this.calendarCase = e;
      this.expanded = [];
      this.machines = [];
      switch (e) {
        case 0:
          if (this.shiftStart != null && this.shiftEnd != null) {
            this.fromDate = moment(this.shiftStart);
            this.toDate = moment(this.shiftEnd);
          } else {
            this.fromDate = null;
            this.toDate = null;
          }
          break;
        case 1:
          this.fromDate = moment().subtract(1, "day").startOf("day");
          this.toDate = moment().subtract(1, "day").endOf("day");
          break;
        case 2:
          this.fromDate = moment().startOf("day");
          this.toDate = moment().endOf("day");
          break;
        case 3:
          this.fromDate = moment().startOf("week");
          this.toDate = moment().endOf("week");
          break;
        case 4:
          this.fromDate = moment().startOf("month");
          this.toDate = moment().endOf("month");
          break;
        case 5:
          this.fromDate = moment().startOf("year");
          this.toDate = moment().endOf("year");
          break;
      }
    },
    getStatus(machine_code) {
      let statusMatch = false;
      if (machine_code) {
        this.statuses.forEach((status) => {
          status.statuscodeSet.forEach((code) => {
            if (String(code.code) == String(machine_code)) {
              statusMatch = status;
            }
          });
        });
      }
      return statusMatch;
    },
    getStatusCode(machine_code) {
      let statusMatch = false;
      if (machine_code) {
        this.statuses.forEach((status) => {
          status.statuscodeSet.forEach((code) => {
            if (String(code.code) == String(machine_code)) {
              statusMatch = code;
            }
          });
        });
      }
      return statusMatch;
    },
    getStatusCodeText(code) {
      return code.code + " - " + code.description;
    },
    getPerfTrend(machine) {
      if (machine.trend) {
        let length = machine.trend.buckets.length;
        if (length > 2) {
          let slope = 0;
          let point1 = machine.trend.buckets[length - 1];
          let point2 = machine.trend.buckets[length - 2];
          let y1 = point1.produced.value / point1.production_target.value;
          let y2 = point2.produced.value / point2.production_target.value;
          if (y1 > y2) {
            return this.trendUp;
          }
          if (y1 == y2) {
            return this.trendStable;
          }
          if (y1 < y2) {
            return this.trendDown;
          }
        }
      }
      return this.trendNeutral;
    },
    getQualTrend(machine) {
      if (machine.trend) {
        let length = machine.trend.buckets.length;
        if (length > 2) {
          let slope = 0;
          let point1 = machine.trend.buckets[length - 1];
          let point2 = machine.trend.buckets[length - 2];
          let y1 = (point1.produced.value - point1.scrapped.value) / point1.produced.value;
          let y2 = (point2.produced.value - point2.scrapped.value) / point2.produced.value;
          if (y1 > y2) {
            return this.trendUp;
          }
          if (y1 == y2) {
            return this.trendStable;
          }
          if (y1 < y2) {
            return this.trendDown;
          }
        }
      }
      return this.trendNeutral;
    },
    getAvailTrend(machine) {
      if (machine.trend) {
        let length = machine.trend.buckets.length;
        if (length > 2) {
          let slope = 0;
          let point1 = machine.trend.buckets[length - 1];
          let point2 = machine.trend.buckets[length - 2];
          let y1 = point1.runtime.duration.value / point1.duration.value;
          let y2 = point2.runtime.duration.value / point2.duration.value;
          if (y1 > y2) {
            return this.trendUp;
          }
          if (y1 == y2) {
            return this.trendStable;
          }
          if (y1 < y2) {
            return this.trendDown;
          }
        }
      }
      return this.trendNeutral;
    },
    loadVarThresholds() {
      let query = `query{
        settings{
          goodTolerance
          warningTolerance
          badTolerance
        }
      }`;
      this.$http.post("graphql/", { query: query }).then((res) => {
        if (res.data.data.settings.length > 0) {
          this.tolerances = res.data.data.settings[0];
        }
      });
    },
    getCls(machine, val, target) {
      let targetVal = this.machinesLookup[machine.machine_id][target];
      let diff = parseFloat(val) - parseFloat(targetVal);
      if (diff >= 0) {
        return "goodBar";
      } else if (diff >= this.tolerances.warningTolerance) {
        return "warningBar";
      }
    },
    saveStatus() {
      this.statusUpdateLoading = true;
      const data = {
        machine_ids: this.selectedMachines,
        status_code: this.newCode.pk
      };
      this.$http
        .post("manufacturing/machines/update_manual_status/", { ...data })
        .then(() => {
          this.statusEntry = false;
          this.statusUpdateLoading = false;
          this.$message("ALERT", {
            text: this.$t("Set status successful."),
            type: "success"
          });
          this.loadMachines();
        })
        .catch((error) => {
          this.statusEntry = false;
          this.statusUpdateLoading = false;
          this.$message("ALERT", {
            text: this.$t("Failed to set status."),
            type: "error"
          });
          console.error(error);
        });
    },
    selectAllMachines() {
      this.selectedMachines = [];
      this.manualStatusMachines.forEach((machine) => {
        this.selectedMachines.push(machine.machine_id);
      });
    },
    deselectAllMachines() {
      this.selectedMachines = [];
    },
    cancelStatusEntry() {
      this.statusEntry = false;
      this.selectedMachines = [];
      this.newCode = null;
    },
    machineStatusClassName(status) {
      return useMachineStatusClassName(status);
    }
  },
  watch: {
    fromDate: function () {
      if (this.fromDate && this.toDate) {
        this.loadMachines();
      }
    },
    toDate: function () {
      if (this.fromDate && this.toDate) {
        this.loadMachines();
      }
    },
    summaryPage: function (val) {
      if (val === 1) {
        return;
      }
      this.loadMachines();
    }
  }
};
</script>
