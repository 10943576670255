<template>
  <v-timeline-item
    :color="data.color"
    :icon="data.icon"
    :icon-color="data.date ? data.iconColor : 'var(--text-tertiary)'"
    :class="{ time: data.date }"
    fill-dot
  >
    <div class="d-flex flex-column align-start timeline-item">
      <p
        :class="[
          'text-body-1 font-weight-medium',
          data.date ? data.titleColor : 'color--text-tertiary'
        ]"
      >
        {{ data.title }}
      </p>
      <p
        v-if="data.date"
        class="time text-body-2 color--text-secondary"
      >
        <span v-if="showFromLabel">{{ $t("From") }}: </span
        >{{ moment(data.date).format($datetime.default) }}
      </p>
    </div>
  </v-timeline-item>
</template>

<script setup>
import moment from "moment";
import { computed, toRefs } from "vue";
import { useIntl } from "@/shared/useIntl";
import { useTicketWorkFlowConfig } from "../useTicketWorkFlowConfig";
import { TICKET_STATUS } from "@/features/ticket-details/constants";

const props = defineProps({
  ticket: {
    type: Object,
    required: true,
    default: () => ({})
  },
  stage: {
    type: String,
    required: true
  }
});

const { stage, ticket } = toRefs(props);
const { $t } = useIntl();
const { getConfig } = useTicketWorkFlowConfig();
const config = getConfig(ticket);

const data = computed(() => {
  let state = stage.value;

  if (state === "resolved") {
    state = ticket.value.solutionType;
  }

  const result = { ...config.value[state || stage.value] };
  return result;
});

const showFromLabel = computed(() => {
  const statuses = Object.values(TICKET_STATUS);
  return !statuses.includes(stage.value);
});
</script>

<style lang="scss" scoped>
@import "@/scss/variables";

.v-timeline-item:has(.time):last-of-type {
  padding-bottom: 8px !important;
}

.v-timeline-item:not(.time):last-of-type {
  padding-bottom: 0 !important;
}

.timeline-item {
  transform: translate(-25px, 10px);
  align-items: flex-start !important;
  min-height: 44px;
}
</style>
