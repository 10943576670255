import { useQuery } from "@tanstack/vue-query";
import { helpCenterApi } from "../helpCenterApi";

export const useHelpCenterHelpTags = (isReady) => {
  const query = useQuery({
    queryKey: ["useHelpCenterHelpTags", isReady.value],
    queryFn: () => helpCenterApi.loadHelpTags(),
    keepPreviousData: true,
    enabled: isReady
  });

  return query;
};
