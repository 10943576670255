<template>
  <div class="comment pa-4 rounded-lg">
    <div
      class="d-flex flex-column-reverse flex-sm-row align-stretch align-sm-center justify-space-between"
    >
      <div>
        <span
          v-if="item.userName.trim()"
          class="mr-2 font-weight-medium text-subtitle-1 color--text-primary"
        >
          {{ item.userName }}
        </span>
      </div>
      <time class="text-body-2 color--text-secondary ml-sm-auto mb-2 mb-sm-0">
        {{ moment(item.date).format(FULL_DATE_FORMAT) }}
      </time>
    </div>
    <div class="comment-content mt-2">
      <div class="d-flex justify-start">
        <p class="text-subtitle-1 color--text-primary">
          {{ item.body }}
        </p>
      </div>
      <a
        v-if="item.imageUrl"
        class="preview py-1"
        :href="item.imageUrl || null"
        :target="item.imageUrl ? '_blank' : null"
      >
        <img
          :src="item.imageUrl"
          class="rounded-lg"
        />
      </a>
      <VideoPlayer
        v-if="item.videoUrl"
        :file="item.videoUrl"
        class="rounded-lg"
      />
    </div>
  </div>
</template>

<script setup>
import moment from "moment-timezone";
import VideoPlayer from "@/components/VideoPlayer.vue";
import { FULL_DATE_FORMAT } from "../constants";

defineProps({
  item: {
    type: Object,
    default: () => {}
  },
  isEdit: {
    type: Boolean,
    default: false
  }
});

defineExpose({
  VideoPlayer
});
</script>

<style lang="scss" scoped>
@import "@/scss/variables";

.comment {
  position: relative;
  z-index: 8;
  background-color: var(--bg-card-white);
}

.comment-content {
  flex-wrap: wrap;
  position: relative;

  p {
    width: 100%;
  }
}

.preview {
  max-width: 100%;
  height: 150px;
  display: block;

  img {
    height: 100%;
  }
}

.videoPlayer {
  margin-top: 12px;
  height: 250px;
  border-radius: 0.25rem;
  overflow: hidden;
  aspect-ratio: 16 / 9;

  ::v-deep {
    #video-controls {
      border-radius: 4px;
    }
    #play-pause:not(.maxBtn) {
      color: var(--bg-brand-contrast);
    }
    input[type="range"] {
      border-radius: 4px;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        background: var(--text-accent);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }

      &::-ms-track {
        appearance: none;
        background: var(--text-accent);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      }
    }
  }

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 100%;
    height: auto;
  }
}
</style>
