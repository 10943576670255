export const TICKET_STATUS = {
  RESOLVED: "resolved",
  CAN_NOT_BE_RESOLVED: "can_not_be_resolved",
  CREATED_BY_MISTAKE: "created_by_mistake"
};

export const TICKET_DETAILS_STATUS = {
  ACTIVE: "active",
  WAITING: "waiting",
  ...TICKET_STATUS
};

export const FULL_DATE_FORMAT = "DD MMM YYYY, HH:mm A";

export const TICKET_ACTIVITY_TYPE = {
  COMMENT: "comment",
  HISTORY: "history"
};

export const ACTIVITY_TABS = {
  ALL: 0,
  COMMENTS: 1,
  HISTORY: 2
};
