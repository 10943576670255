import { useMutation, useQueryClient } from "@tanstack/vue-query";
import { ticketDetailsApi } from "./ticketDetailsApi";

export const useTicketDetailsEdit = () => {
  const queryClient = useQueryClient();
  return useMutation(ticketDetailsApi.getTicketDetailsEdit, {
    onSuccess: () => {
      queryClient.invalidateQueries("ticket-details");
    }
  });
};
