<template>
  <div class="scrap-analytics-container">
    <ScrapAnalyticsFilter
      :selectedFilterType="selectedFilterType"
      :applyFilters="applyFilters"
      :applySelectedFilterType="applySelectedFilterType"
    />
    <div
      class="card no-data"
      v-if="loading || noData"
    >
      <p v-if="noData">{{ $t("No Data") }}</p>
      <div class="loading-bar">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
      </div>
    </div>
    <ScrapAnalyticsDataTable
      ref="dataTableView"
      v-show="showDataTableView && !loading && !noData"
      :filters="filters"
    />
    <ScrapAnalyticsReports
      ref="reportsView"
      v-show="showScrapReportsView && !loading && !noData"
      :filters="filters"
    />
    <ScrapAnalyticsDetailsTable
      ref="detailsTableView"
      v-show="showDetailsTableView && !loading && !noData"
      :filters="filters"
    />
  </div>
</template>
<script>
import Vue from "vue";

import { FILTER_TYPES } from "@/utils/scrapAnalyticsUtils";

import ScrapAnalyticsDataTable from "./ScrapAnalyticsDataTable.vue";
import ScrapAnalyticsDetailsTable from "./ScrapAnalyticsDetailsTable.vue";
import ScrapAnalyticsFilter from "./ScrapAnalyticsFilter.vue";
import ScrapAnalyticsReports from "./ScrapAnalyticsReports.vue";
import { mapActions } from "vuex";

export default {
  name: "ScrapAnalytics",
  components: {
    ScrapAnalyticsFilter,
    ScrapAnalyticsDataTable,
    ScrapAnalyticsDetailsTable,
    ScrapAnalyticsReports
  },
  data() {
    return {
      loading: false,
      noData: true,
      selectedFilterType: FILTER_TYPES.DATA_TABLE,
      filters: {},
      showDataTableView: true,
      showScrapReportsView: false,
      showDetailsTableView: false,
      filterChange: false
    };
  },
  mounted() {
    this.setTitles({
      title: this.$t("Scrap Analytics"),
      mobile: this.$t("Scrap Analytics")
    });
  },
  methods: {
    ...mapActions({
      setTitles: "app/SetTitles"
    }),
    applySelectedFilterType(filterType) {
      this.selectedFilterType = FILTER_TYPES[filterType];
    },
    applyFilters(filters) {
      this.filters = filters;
    },
    handleDataTableViewVisibility(isVisible) {
      this.showDataTableView = isVisible;
    },
    handleScrapReportsViewVisibility(isVisible) {
      this.showScrapReportsView = isVisible;
    },
    handleDetailsTableViewVisibility(isVisible) {
      this.showDetailsTableView = isVisible;
    },
    handleNoData(noData) {
      this.noData = noData;
    }
  },
  beforeDestroy() {
    this.setTitles({
      title: "",
      mobile: ""
    });
  },
  watch: {
    selectedFilterType(newValue, oldValue) {
      switch (newValue) {
        case FILTER_TYPES.DATA_TABLE:
          this.handleScrapReportsViewVisibility(false);
          this.handleDetailsTableViewVisibility(false);
          this.handleDataTableViewVisibility(true);
          break;
        case FILTER_TYPES.SCRAP_REPORTS:
          this.handleDataTableViewVisibility(false);
          this.handleDetailsTableViewVisibility(false);
          this.handleScrapReportsViewVisibility(true);
          break;
        case FILTER_TYPES.DETAILS_TABLE:
          this.handleDataTableViewVisibility(false);
          this.handleScrapReportsViewVisibility(false);
          this.handleDetailsTableViewVisibility(true);
          break;
      }
    },
    filters() {
      if (!this.filters.fromDate || !this.filters.toDate) {
        return;
      }
      Vue.nextTick(() => {
        Promise.all([
          this.$refs.dataTableView.getSummaryTableData(),
          this.$refs.detailsTableView.getDetailsTableScrap(),
          this.$refs.reportsView.$refs.ScrapAnalyticsCostByMachine[0].getCostByMachine(),
          this.$refs.reportsView.$refs.ScrapAnalyticsCostByType[0].getCostByType(),
          this.$refs.reportsView.$refs.ScrapAnalyticsCostByPart[0].getCostByPart()
        ]).then(() => {
          this.loading = false;
          this.handleNoData(false);
          this.filterChange = false;
        });
      });
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalytics.scss";
</style>
