<template>
  <Btn
    large
    :text="showText"
    class="order-btn"
    :class="{ showText }"
    @click="handleChangeOrder"
  >
    <svg
      v-if="isDescDirection"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="currentColor"
        d="M14.233 16.321v2.826L18 15.379l-3.767-3.767v2.825H0v1.884M0 .728h2.512v9.209H0zM5.023 3.24h2.512v6.698H5.023zM10.047 5.751h2.512v4.186h-2.512zM15.07 8.263h2.512v1.674H15.07z"
      />
    </svg>

    <svg
      v-if="!isDescDirection"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="18"
      height="20"
      viewBox="0 0 18 20"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        fill="currentColor"
        d="M14.233 16.412v2.825L18 15.47l-3.767-3.768v2.826H0v1.884M0 8.354h2.512v1.674H0zM5.023 5.842h2.512v4.186H5.023zM10.047 3.33h2.512v6.698h-2.512zM15.07.819h2.512v9.209H15.07z"
      />
    </svg>

    <span
      v-if="showText"
      class="text-uppercase font-weight-bold ml-2"
    >
      {{ isDescDirection ? $t("Newest first") : $t("Oldest first") }}
    </span>
  </Btn>
</template>

<script setup>
import { ref } from "vue";
import Btn from "@/ui/Btn";

const props = defineProps({
  order: {
    type: String,
    default: "DESC"
  },
  showText: {
    type: Boolean,
    default: false
  }
});

const emits = defineEmits(["order"]);

const isDescDirection = ref(props.order === "DESC");

const handleChangeOrder = () => {
  isDescDirection.value = !isDescDirection.value;
  emits("order", isDescDirection.value ? "DESC" : "ASC");
};

defineExpose({
  isDescDirection,
  handleChangeOrder
});
</script>

<style lang="scss" scoped>
.buttons-container {
  flex-grow: 9999;

  ::v-deep .v-btn__content {
    text-transform: none;
    font-weight: 400 !important;
  }
}

.order-btn.button {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 52px !important;
    min-width: 0 !important;
    max-width: 52px;
    height: 52px;
  }
}

.order-btn.showText.button {
  min-width: 158px;
}

svg {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  height: 19px;
  width: 19px;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    width: 24px;
    height: 24px;
  }
}
</style>
