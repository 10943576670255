<template>
  <ModalDialog
    :value="open"
    content-class="ticket-solution-dialog"
    persistent
    full-screen-mobile
    max-width="972px"
    height="640px"
    @close="$emit('close')"
  >
    <template #title> {{ $t("Similar Solutions") }} </template>
    <template #body>
      <div
        v-if="isLoading"
        class="loader-wrapper"
      >
        <img
          :src="loader"
          :alt="$t('Loading similar solutions')"
          rel="preload"
          class="loader"
        />
      </div>
      <div v-else>
        <router-link
          v-for="(solution, solutionIndex) in similarSolutions"
          :key="solutionIndex"
          :to="{ name: 'ticket-details', params: { ticket_id: solution.global_id } }"
          target="_blank"
          class="solution-link"
        >
          <Card
            class="solution pa-4"
            custom-padding
          >
            <header
              class="d-flex mb-3 flex-column-reverse flex-sm-row align-stretch align-sm-center justify-space-between"
            >
              <div
                class="d-flex flex-wrap align-center justify-space-between justify-space-sm-start"
              >
                <span
                  v-if="solution.machine_name"
                  class="solution-machine text-h6 color--text-primary mr-3"
                  >{{ solution.machine_name }}</span
                >
                <span class="d-inline-block">
                  <span class="solution-author d-inline-block px-2 py-1 text-subtitle-1 mr-3">
                    {{ solution.ticketed_by_name }}
                  </span>
                </span>
              </div>

              <time class="text-body-2 color--text-secondary mb-2 mb-sm-0">
                {{ moment(solution.ticketed_date).format("D MMM YYYY, hh:mm A") }}
              </time>
            </header>
            <div
              v-if="solution.subcategory"
              class="mb-3"
            >
              <span
                class="solution-category d-inline-block color--text-contrast text-subtitle-1 py-1 px-2"
              >
                {{ solution.subcategory }}
              </span>
            </div>
            <div class="solution-body">
              <h3 class="text-body-1 color--text-secondary mb-1">{{ $t("Details") }}</h3>
              <p class="text-body-1 color--text-primary">{{ solution.details || "-" }}</p>
            </div>
            <div class="solution-body">
              <h3 class="text-body-1 color--text-secondary mb-1">{{ $t("Solution") }}</h3>
              <p class="text-body-1 color--text-primary">{{ solution.solution_details || "-" }}</p>
            </div>
          </Card>
        </router-link>
      </div>
    </template>
    <template #actions>{{ "" }}</template>
  </ModalDialog>
</template>

<script setup>
import moment from "moment-timezone";
import { computed, toRefs } from "vue";

import Card from "@/ui/Card";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import loader from "@/assets/img/planet-loader.gif";
import { useTicketDetailsSimilarSolution } from "../useTicketDetailsSimilarSolution";

defineEmits(["close"]);

const props = defineProps({
  ticket: {
    type: Object,
    default: () => ({})
  },
  open: {
    type: Boolean,
    default: false,
    required: true
  }
});

const { open } = toRefs(props);

const category = computed(() => props.ticket?.issues?.[0] || null);
const subcategory = computed(() => props.ticket?.issues?.slice(1) || []);

const variables = computed(() => ({
  global_id: props.ticket.id
}));
const { data, isLoading } = useTicketDetailsSimilarSolution(variables, open);
const similarSolutions = computed(() =>
  data.value.filter((item) => item.global_id !== props.ticket.id)
);

defineExpose({
  category,
  subcategory,
  isLoading,
  similarSolutions
});
</script>

<style lang="scss" scoped>
.root {
  max-height: 100%;
  height: 100%;
}

.loader-wrapper {
  display: flex;
  height: 100%;
  width: 100%;
}

.loader {
  max-width: 100%;
  max-height: 100%;
  width: 167px;
  height: 167px;
  display: block;
  margin: auto;
}

.solution {
  background-color: var(--bg-card-white);
}

.solution-link {
  text-decoration: none;
  display: block;
  --border-radius: 4px;

  & + .solution-link {
    margin-top: 12px;
  }

  &:hover .solution {
    background-color: var(--btn-secondary-hover) !important;
  }
}

.solution-author {
  background-color: var(--bg-card-white);
  color: var(--text-primary);
  border-radius: var(--border-radius);
}

.solution-category {
  background-color: var(--status-orange);
  border-radius: var(--border-radius);
}

.solution-body + .solution-body {
  margin-top: 12px;
}
</style>

<style lang="scss">
.ticket-solution-dialog {
  .footer {
    display: none !important;
  }
  .body {
    padding-top: 20px !important;
  }
}
</style>
