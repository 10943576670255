<template>
  <div class="operator_tracking_leaderboard">
    <v-tabs
      fixed-tab
      v-model="mobileTab"
      centered
      v-if="vw < 1000"
    >
      <v-tab class="smalltab">{{ $t("Leaderboard") }}</v-tab>
      <v-tab class="smalltab">{{ $t("Reactions") }}</v-tab>
    </v-tabs>
    <div class="leaderboard-content">
      <div
        class="tableView"
        :class="{ active: mobileTab == 0 }"
      >
        <v-card class="tableHeader">
          <div class="dd">
            <v-autocomplete
              :items="years"
              :label="$t('Year')"
              :value="selectyear"
              @change="
                (value) => {
                  this.selectyear = value;
                }
              "
              hide-details
              :menu-props="{ closeOnClick: true }"
            ></v-autocomplete>
          </div>
          <div class="dd">
            <v-autocomplete
              :items="months"
              :label="$t('Month')"
              :value="selectmonth"
              @change="
                (value) => {
                  this.selectmonth = value;
                }
              "
              hide-details
              :menu-props="{ closeOnClick: true }"
            ></v-autocomplete>
          </div>
        </v-card>
        <div class="tableContainer">
          <v-data-table
            :headers="table_headers"
            :items="sorted_items"
            :loading="loading"
            :hide-default-header="vw > 1000"
          >
            <template
              v-slot:header="{ props }"
              v-if="vw > 1000"
            >
              <thead>
                <tr>
                  <th v-for="head in table_headers">
                    <div
                      class="header_item"
                      v-bind:class="head.class"
                      @click="
                        () => {
                          sortby(head);
                        }
                      "
                    >
                      <div class="rowcontent">{{ head.text }}</div>
                      <i
                        v-if="head.sort == 'asc'"
                        class="mdi mdi-arrow-up"
                      />
                      <i
                        v-else-if="head.sort == 'desc'"
                        class="mdi mdi-arrow-down"
                      />
                      <i v-else></i>
                    </div>
                  </th>
                </tr>
              </thead>
            </template>

            <template v-slot:item.name="{ item }">
              <div class="table_name">
                <div
                  class="icon"
                  v-bind:style="
                    item.avatar
                      ? {
                          background: `url(${item.avatar})`,
                          'background-size': 'cover',
                          'background-position-x': 'center',
                          'background-position-y': 'center'
                        }
                      : null
                  "
                >
                  <i
                    class="fa fa-user"
                    v-if="item.avatar == null"
                  ></i>
                </div>
                <div class="name">{{ item.name }}</div>
              </div>
            </template>

            <template v-slot:[`item.target_units`]="{ item }">
              {{ numberWithCommas(item.target_units.toFixed()) }}
            </template>

            <template v-slot:[`item.actual`]="{ item }">
              {{ numberWithCommas(item.actual.toFixed()) }}
            </template>

            <template v-slot:[`item.best_units_per_hour`]="{ item }">
              {{ numberWithCommas(item.best_units_per_hour) }}
            </template>

            <template v-slot:item.efficiency="{ item }">
              {{ `${item.efficiency}%` }}
            </template>

            <template v-slot:item.reactions="{ item }">
              {{ `${item.reactions} Reactions` }}
            </template>

            <template v-slot:item.reactions_node="{ item }">
              <div class="reaction_icon_container">
                <div
                  class="reaction_icon"
                  v-for="rt of reactionTypes"
                  v-bind:style="{ background: `url(${rt.image})` }"
                  @click="
                    () => {
                      AddReaction(rt, item.userId);
                    }
                  "
                />
              </div>
            </template>
          </v-data-table>
        </div>
      </div>
      <v-card
        class="feedView"
        :class="{ active: mobileTab == 1 }"
      >
        <div class="new-reactions">
          <transition-group
            name="rain"
            tag="ul"
            :css="false"
            @before-enter="beforeEnter"
            @enter="enter"
            @before-leave="beforeLeave"
            @leave="leave"
          >
            <div
              class="new-reaction"
              v-for="(reaction, index) in newReactions"
              :key="String(reaction.index)"
              v-bind:data-index="reaction.delay"
            >
              <img :src="reaction.reaction.image" />
            </div>
          </transition-group>
        </div>
        <div class="feedTitle">
          <h3>Reactions</h3>
        </div>
        <div class="feed">
          <div v-for="reaction of reactionFeed">
            <div class="table_name">
              <div class="icon">
                <img
                  class="user-image"
                  :src="reaction.user.avatar"
                  v-if="reaction.user.avatar"
                />
                <div
                  class="default-user"
                  v-if="!reaction.user.avatar"
                >
                  <i class="fa fa-user"></i>
                </div>
                <img
                  class="tinyicon"
                  :src="reaction.reaction.image"
                />
              </div>
              <div class="name name_feed">
                <div>{{ reaction.fromUser ? reaction.fromUser.username : "---" }}</div>
                <div>for {{ reaction.user.username }}</div>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>
<style lang="scss">
@import "../../../scss/variables";

.operator_tracking_leaderboard {
  display: flex;
  flex-flow: row;
  flex: 1;
  overflow-y: hidden;
  position: relative;
  @media screen and (max-width: 1200px) {
    overflow: hidden;
  }
  .v-tabs {
    .v-tab {
      width: 45%;
      font-size: 12px;
    }
  }
  .leaderboard-content {
    display: flex;
    flex-flow: row;
    flex: 1;
    overflow: hidden;
    @media screen and (max-width: 1200px) {
      position: absolute;
      top: 3.5rem;
      width: 200vw;
      margin-top: 0;
      margin-left: 0rem;
      bottom: 0px;
      .tableView {
        flex: unset;
        width: 100vw;
        padding: 0 1rem;
        margin: 0;
        margin-left: -100vw;
        transition: 0.25s margin-left ease-out;
        &.active {
          margin-left: 0;
        }
      }
      .feedView {
        margin: 0 1rem;
        width: calc(100vw - 2rem);
      }
    }
  }
  .header_item {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: left;
    flex: unset;
    font-weight: lighter;
    color: $blue;
    text-align: left;
    font-size: 14px;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */

    &:hover {
      color: $green;
    }

    .rowcontent {
      flex: unset;
      margin-right: 10px;
    }

    i {
      font-size: 18px;
      width: 18px;
      position: relative;
      left: 0;
      bottom: 0;
    }
  }

  .table_name {
    display: flex;
    justify-content: center;
    align-items: center;

    .name_feed {
      padding-left: 8px;
      display: flex;
      flex-flow: column;
      justify-content: center;
    }

    .icon {
      padding: 10px;
      margin: 10px;
      width: 40px;
      height: 40px;
      border-radius: 100%;
      font-size: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .user-image {
        height: 40px;
        width: 40px;
        border-radius: 100%;
      }
      .default-user {
        height: 40px;
        width: 40px;
        min-height: 40px;
        min-width: 40px;
        border-radius: 100%;
        background-color: $blue;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .tinyicon {
      position: absolute;
      width: 20px;
      height: 20px;
      right: -4px;
      bottom: -4px;
      background-color: white !important;
      border-radius: 100%;
      border: 1px solid black;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .name {
      flex: 1;
    }
  }

  .tableView {
    flex: 1;
    margin: 10px;
    background: none;
    display: flex;
    flex-flow: column;
    overflow: hidden;
    position: relative;

    .v-data-table {
      display: flex;
      flex-flow: column;
    }

    .v-data-table__wrapper {
      height: unset;
      overflow: auto;
    }

    .tableHeader {
      height: 70px;
      flex: unset;
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      .dd {
        width: 50%;
        max-width: 200px;
        margin: 0.5rem;
      }
      &.theme--dark {
        background: #393641;
      }
    }

    .tableContainer {
      flex: 1;
      overflow: hidden;
    }

    .reaction_icon_container {
      display: flex;
      align-items: flex-start;

      .reaction_icon {
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background-size: cover !important;
        background-color: white !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
        margin: 5px;
        cursor: pointer;

        &:hover {
          opacity: 70%;
        }
      }
    }
    .v-data-table__mobile-row {
      border-bottom: 1px rgba(255, 255, 255, 0.05) solid;
      justify-content: flex-start;
      display: flex !important;

      .v-data-table__mobile-row__header {
        color: $blue;
        width: 150px;
        text-align: left;
        flex: unset;
      }

      .v-data-table__mobile-row__cell {
        flex: 1;
        text-align: left;

        p {
          text-align: left;
        }
        div {
          text-align: left;
        }
      }
    }
  }
  .feedView {
    flex: unset;
    width: 250px;
    margin: 10px;
    display: flex;
    flex-flow: column;
    position: relative;
    &.theme--dark {
      background: #393641;
    }
    .new-reactions {
      position: absolute;
      width: 100%;
      z-index: 99999;
      .new-reaction {
        position: absolute;
        img {
          width: 35px;
          height: 35px;
          border-radius: 35px;
        }
      }
    }
    .feedTitle {
      flex: unset;
      color: $blue;
      padding: 10px;
    }
    .feed {
      flex: 1;
      overflow-y: auto;
    }
  }
}
</style>
<script>
import moment from "moment-timezone";
import Velocity from "velocity-animate";
import { mapGetters } from "vuex";

import { numberWithCommas } from "@/utils/filters";

import { FormatTime, GetAveragePerHour } from "../../../utils/timeFormat";

export default {
  created() {
    this.reactionSocket = this.$socket("reactions/", (r) => {
      let data = JSON.parse(r.data);
      for (let i = 0; i < 20; i++) {
        let newReaction = JSON.parse(JSON.stringify(data.message));
        newReaction["index"] = String(newReaction.createdAt) + String(newReaction.id) + String(i);
        newReaction["delay"] = i * 100;
        let leaveDelay = newReaction["delay"] + 1000;
        this.newReactions.push(newReaction);
        let newReactions = this.newReactions;
        setTimeout(function () {
          newReactions.shift();
        }, leaveDelay);
      }
      this.reactionFeed.unshift(data.message);
    });
  },
  mounted: async function () {
    this.years = this.populateYears(2020);

    //set your default date
    const d = new Date();
    this.selectyear = d.getFullYear();
    this.selectmonth = this.months[d.getMonth()];
    this.reactionTypes = await this.GetReactionTypes();

    const m = moment();
    //use from and to, because we likely want to
    //paginate requests eventually
    const toDate = m.toISOString();
    const fromDate = m.subtract(30, "days").toISOString();

    this.reactionFeed = await this.GetReactionFeed(fromDate, toDate);
  },
  beforeDestroy() {
    if (this.reactionSocket) {
      this.reactionSocket.close();
      this.reactionSocket = null;
    }
  },
  computed: {
    ...mapGetters({
      vw: "app/VW"
    })
  },
  data: function () {
    return {
      years: [],
      loading: true,
      mobileTab: 0,
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November"
      ],
      table_headers: [
        {
          text: this.$t("Employee"),
          value: "name",
          width: 160,
          class: ""
        },
        {
          text: this.$t("Zone"),
          value: "zone",
          width: 130,
          class: ""
        },
        {
          text: this.$t("Department"),
          value: "department",
          width: 160,
          class: ""
        },
        {
          text: this.$t("Shift"),
          value: "shift_name",
          width: 110,
          class: ""
        },
        {
          text: this.$t("Target (units)"),
          value: "target_units",
          width: 115,
          class: ""
        },
        {
          text: this.$t("Actual (units)"),
          value: "actual",
          width: 110,
          class: ""
        },
        {
          text: this.$t("Average (UPH)"),
          value: "average",
          width: 110,
          class: ""
        },
        {
          text: this.$t("All Time Best (UPH)"),
          value: "best_units_per_hour",
          width: 120,
          class: ""
        },
        {
          text: this.$t("Efficiency"),
          value: "efficiency",
          width: 95,
          class: ""
        },
        {
          text: this.$t("Reactions"),
          value: "reactions",
          width: 95,
          class: ""
        },
        {
          text: "",
          value: "reactions_node",
          width: 95,
          cellClass: "reactions"
        }
      ],
      items: [],
      sorted_items: [],
      reactionTypes: [],
      fetchDataDebounce: null,
      selectyear: 0,
      selectmonth: "",
      newReactions: [],
      reactionFeed: [],
      reactionSocket: null
    };
  },
  methods: {
    numberWithCommas,
    findHeader(header) {
      for (const i of this.table_headers) {
        if (header == i.value) {
          return i;
        }
      }
    },
    findHeaderWithSort(header) {
      for (const i of this.table_headers) {
        if (i.sort != null) {
          return i;
        }
      }
    },
    GetReactionFeed(fromDate, toDate) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const query =
          `query($fromDate: DateTime!, $toDate: DateTime!, $orderBy: String)` +
          `{` +
          `reaction(fromDate: $fromDate, toDate: $toDate, orderBy: $orderBy) {` +
          `user {` +
          `username,` +
          "avatar" +
          `},` +
          `createdAt,` +
          `reaction {` +
          `type,` +
          "image," +
          "gif" +
          `},` +
          `fromUser {` +
          `username` +
          `}` +
          `}` +
          `}`;

        const variables = { fromDate: fromDate, toDate: toDate, orderBy: "-created_at" };

        const result = await this.$http.post("graphql/", { query, variables });
        console.log(result);
        const reactionFeed = result.data.data.reaction;
        resolve(reactionFeed);
      });
    },
    populateYears(startYear) {
      const years = [];
      const currentYear = new Date().getFullYear();
      startYear = startYear || 1980;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }
      return years;
    },
    sortby(item, advanceClick = true) {
      if (item == null) {
        this.sorted_items = this.items.slice();
        return;
      }

      if (advanceClick) {
        if (item.sort == null) {
          item.sort = "desc";
        } else if (item.sort == "desc") {
          item.sort = "asc";
        } else if (item.sort == "asc") {
          item.sort = null;
        }
      }

      this.table_headers = this.table_headers.map((i) => {
        if (i == item) {
          return i;
        } else {
          i.sort = null;
          return i;
        }
      });

      const sortKey = item.value;
      const sort = item.sort;

      if (sort == null) {
        this.sorted_items = this.items.slice();
      } else {
        const unsorted = this.items.slice();
        unsorted.sort((item_a, item_b) => {
          const a = isNaN(item_a[sortKey]) ? item_a[sortKey] : Number(item_a[sortKey]);
          const b = isNaN(item_b[sortKey]) ? item_b[sortKey] : Number(item_b[sortKey]);

          if (sort == "asc") {
            if (a < b || a == null) {
              return 1;
            } else {
              return -1;
            }
          } else {
            if (a > b || b == null) {
              return 1;
            } else {
              return -1;
            }
          }
        });

        this.sorted_items = unsorted.slice();
      }
    },
    GetLeaderboardData: async function (year, month) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        if (this.fetchDataDebounce) {
          clearTimeout(this.fetchDataDebounce);
        }

        this.fetchDataDebounce = setTimeout(async () => {
          const m = moment(`${this.months.indexOf(month) + 1}-1-${year}`);
          const fromDate = m.startOf("month").toISOString();
          const toDate = m.endOf("month").toISOString();

          const result = await this.$http.get("/operator/leaderboard/", {
            params: {
              from_date: fromDate,
              to_date: toDate
            }
          });

          resolve(this.CreateItemsData(result.data.results));
          this.loading = false;
        }, 500);
      });
    },

    GetReactionTypes: function () {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        const query =
          "{" + "reactionType {" + "pk," + "type," + "image," + "description," + "gif" + "}" + "}";

        const result = await this.$http.post("graphql/", { query });
        const reactionTypes = result.data.data.reactionType;
        resolve(reactionTypes);
      });
    },
    AddReaction: async function (rt, userId) {
      console.log(rt, userId);
      const data = {
        user: userId,
        reaction: rt.pk
      };
      const response = await this.$http.post("reaction/AddReaction", data);
      const responseData = response.data;
      for (const item of this.items) {
        if (item.userId == responseData.user) {
          item.reactions++;
        }
      }
    },
    CreateItemsData(data) {
      const items = [];
      data.forEach((d, i) => {
        const item = {
          key: i,
          name: d.last_record.username,
          userId: d.last_record.user_id,
          avatar: d.last_record.avatar,
          role: d.last_record.role,
          status: "1",
          machine: d.last_record.machine_name,
          end_time: d.last_record.end_time,
          zone: d.last_record.zone,
          department: d.last_record.department,
          shift_name: d.last_record.shift_name,
          best_units_per_hour: d.last_record.best_units_per_hour,
          production_time: FormatTime(d.event_duration),
          event_duration: d.event_duration,
          target_units: d.production_target,
          actual: d.produced,
          gap: d.production_target - d.produced,
          average: GetAveragePerHour(d.produced, d.event_duration),
          scrap: d.scrapped,
          efficiency: `${d.production_target > 0 ? Math.round((d.produced / d.production_target) * 100) : "--"}`,
          machines: d.machines,
          reactions: d.reactions
        };
        items.push(item);
      });
      //sort these items by efficiency
      return items.sort((item_a, item_b) => {
        const a = Number(item_a.efficiency);
        const b = Number(item_b.efficiency);
        return b - a;
      });
    },
    beforeEnter: function (el) {
      el.style.opacity = 0;
      el.style.top = Math.random() * 5 + "vh";
      el.style.left = Math.random() * 95 + "%";
      el.dataset.animation = Math.random() * 2 + 1;
      el.style.transition = "all " + el.dataset.animation + "s ease-out, opacity 1s ease-out";
    },
    enter: function (el, done) {
      var delay = el.dataset.index;
      el.dataset.top = Math.random() * 40 + 10;
      setTimeout(function () {
        Velocity(
          el,
          { opacity: Math.random() + 0.75, top: el.dataset.top + "vh" },
          { complete: done }
        );
      }, delay);
    },
    beforeLeave: function (el, done) {
      el.style.opacity = 1;
    },
    leave: function (el, done) {
      Velocity(el, { opacity: "0 !important" }, { complete: done });
    }
  },
  watch: {
    selectyear: async function () {
      const items = await this.GetLeaderboardData(this.selectyear, this.selectmonth);
      this.items = items;
      const header = this.findHeaderWithSort();
      this.sortby(header, false);
    },
    selectmonth: async function () {
      const items = await this.GetLeaderboardData(this.selectyear, this.selectmonth);
      this.items = items;
      const header = this.findHeaderWithSort();
      this.sortby(header, false);
    }
  }
};
</script>
