import axios from "axios";

export const assetApi = {
  getAssetLocations: async (depth, treeId, lft, rgt) =>
    axios
      .post("graphql/", {
        query: `query($depth:Int, $treeId:Int, $lft:Int, $rgt:Int) {
          assetLocations(depth:$depth, treeId:$treeId, lft:$lft, rgt:$rgt) {
            name,
            id,
            pk,
            depth,
            children,
            childAssets,
            treeId,
            lft,
            rgt,
            assets {
              name,
              pk,
            }
          }
        }`,
        variables: { depth, treeId, lft, rgt }
      })
      .then((res) => res.data)
};
