import { computed } from "vue";
import { useQuery } from "@tanstack/vue-query";
import { settingsRouteApi } from "./settingsRouteApi";

export const useSettingsDisabledRoutes = (userId) => {
  const query = useQuery({
    queryKey: ["disabled_routes", userId],
    queryFn: () => settingsRouteApi.getDisabledRoutes(userId.value),
    keepPreviousData: true
  });

  const disabledRoutes = computed(() => query.data.value?.data?.data.disabledRoutes);

  return { ...query, disabledRoutes };
};
