<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5873 1.34595C6.61065 2.25849 6.00016 3.55797 6.00016 5.00003C6.00016 7.76144 8.23876 10 11.0002 10C12.4422 10 13.7417 9.38957 14.6542 8.41291C14.441 11.9025 11.5434 14.6667 8.00016 14.6667C4.31826 14.6667 1.3335 11.6819 1.3335 8.00004C1.3335 4.4568 4.09769 1.55917 7.5873 1.34595Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "MoonFilled"
};
</script>

<style scoped lang="scss"></style>
