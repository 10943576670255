<template>
  <!--
    https://intelycx.atlassian.net/browse/MIM-4701
    Enable Giant Screen only for summary pages
  -->
  <component
    is="style"
    v-if="isUseGiant"
    type="text/css"
  >
    @media (min-width: 1981px) { html { font-size: 1vw; } }
  </component>
</template>

<script>
import { AppRoutes } from "@/shared/app-routes";

const allowedPaths = [AppRoutes.machineSummary.name, AppRoutes.groupSummary.name, "login", "okta"];

export default {
  computed: {
    isUseGiant() {
      return allowedPaths.includes(this.$route.name);
    }
  }
};
</script>
