import moment from "moment-timezone";
import { Presets, Scales } from "@/shared/constants";
import { useRoute } from "vue-router/composables";
import { computed } from "vue";
import { useTickets } from "@/shared/useTickets";
import { MIN_DATE } from "@/shared/constants";
import qs from "qs";

export const useGroupParams = () => {
  const { isTickets } = useTickets();

  const defaultQuery = {
    dateFrom: moment().startOf("day").toISOString(),
    dateTo: moment().endOf("day").toISOString(),
    preset: Presets.DAY,
    scale: Scales.HOUR,
    ...(isTickets.value
      ? { dateFrom: moment(MIN_DATE).toISOString(), preset: Presets.ALL, scale: Scales.ALL }
      : {})
  };

  const route = useRoute();
  const machine_group_id = computed(() => route.params.machine_group_id);
  const machine_group_pk = computed(() => Number(route.params.machine_group_pk));
  const machine_group_level = computed(() => Number(route.params.level));

  let params = computed(() =>
    qs.parse({
      ...defaultQuery,
      ...(route.query || {}),
      preset: route.query.preset ? Number(route.query.preset) : defaultQuery.preset,
      scale: route.query.scale || defaultQuery.scale
    })
  );

  return {
    params,
    machine_group_id,
    machine_group_pk,
    machine_group_level
  };
};
