<template>
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 15.3333C5 19.0167 7.9034 22 11.488 22C14.2698 22 16.7596 20.2583 17.7895 17.6083L19.8657 12.25C20.0685 11.7167 20.0522 11.1 19.7441 10.625C19.3467 10.0083 18.6411 9.74167 17.9761 9.91667L17.3435 10.1083C16.9704 10.2083 16.6298 10.4 16.3541 10.6583V5.75C16.3541 4.6 15.4457 3.66667 14.3266 3.66667C14.1725 3.66667 14.0265 3.66667 13.8886 3.71667C13.7183 2.74167 12.8911 2 11.8935 2C11.0339 2 10.304 2.55 10.0039 3.325C9.83359 3.275 9.64706 3.25 9.46053 3.25C8.34134 3.25 7.43301 4.18333 7.43301 5.33333V5.79167C7.30325 5.75 7.16538 5.75 7.02751 5.75C5.90833 5.75 5 6.68333 5 7.83333V15.3333ZM6.62201 7.83333C6.62201 7.6 6.80043 7.41667 7.02751 7.41667C7.25459 7.41667 7.43301 7.6 7.43301 7.83333V12H9.05502V5.33333C9.05502 5.1 9.23344 4.91667 9.46053 4.91667C9.68761 4.91667 9.86603 5.1 9.86603 5.33333V12H11.488V4.08333C11.488 3.85 11.6665 3.66667 11.8935 3.66667C12.1206 3.66667 12.299 3.85 12.299 4.08333V12H13.9211V5.75C13.9211 5.51667 14.0995 5.33333 14.3266 5.33333C14.5536 5.33333 14.7321 5.51667 14.7321 5.75V14.5H16.3541L17.1651 12.4167C17.2867 12.0417 17.5706 11.7583 17.9761 11.6583L18.3816 11.5417L16.2811 17C15.5025 19.0083 13.6048 20.3333 11.488 20.3333C8.80361 20.3333 6.62201 18.0917 6.62201 15.3333V7.83333Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "VHandBackLeftOutline"
};
</script>
