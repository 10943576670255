import axios from "axios";

export const SETTINGS_API_GET_TOLERANCES_QUERY = `{
  settings{
    goodTolerance
    warningTolerance
    badTolerance,
    commentDisplayWindow
  }
}`;

export const settingsApi = {
  getSettings: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              goodTolerance
              warningTolerance
              badTolerance,
              commentDisplayWindow,
              globalConfiguration
              zcbEscalationTime
            }
          }`
        }
      })
      .then((res) => res.data),

  getTolerances: () =>
    axios
      .get("graphql/", {
        params: {
          query: SETTINGS_API_GET_TOLERANCES_QUERY
        }
      })
      .then((res) => res.data),

  getTimezone: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              timezone
            }
          }`
        }
      })
      .then((res) => res.data),

  getDowntimeRevisionWindow: () =>
    axios
      .get("graphql/", {
        params: {
          query: `{
            settings{
              downtimeRevisionWindow
            }
          }`
        }
      })
      .then((res) => res.data)
};
