import axios from "axios";

export const groupApi = {
  getGroup: (id) =>
    axios
      .post("graphql/", {
        query: `query getMachineGroup($id: ID!){
          machineGroup(id: $id){
            id
            ... on MachineGroupType {
              name
              id
            }
          }
        }`,
        variables: { id }
      })
      .then((res) => res.data),

  getGroups: () =>
    axios
      .post("graphql/", {
        query: `{machineGroups{
        name
        id
        pk
        level{
          name
          level
        }
        machines{
          name
          id
          pk
          isProduction
        }
        subGroupIds
      }
    }`
      })
      .then((res) => res.data),
  getMachineGroupThresholds: (id, include_machines_thresholds = false) =>
    axios
      .get(`manufacturing/machine_groups/${id}/thresholds/`, {
        params: { include_machines_thresholds }
      })
      .then((res) => res.data)
};
