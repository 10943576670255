<template>
  <div class="card details-table-view">
    <v-text-field
      v-model="searchText"
      append-icon="mdi-magnify"
      :label="$t('Search')"
      single-line
      hide-details
      clearable
    ></v-text-field>
    <v-data-table
      :headers="scrapDetailsTable.headers"
      :items="scrapDetailsTable.data"
      :page.sync="scrapDetailsTable.page.current"
      :sortBy.sync="scrapDetailsTable.sortBy"
      :sortDesc.sync="scrapDetailsTable.sortDesc"
      :itemsPerPage.sync="scrapDetailsTable.page.itemsPerPage"
      :serverItemsLength="scrapDetailsTable.total"
      :loading="scrapDetailsTable.loading"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Scrap Details... Please wait')"
      disable-pagination
      fixed-header
    >
      <template v-slot:item.user="{ item }">
        {{ `${item.user?.firstName} ${item.user?.lastName}` }}
      </template>
      <template v-slot:item.unsubmitted="{ item }">
        <i
          v-if="item.unsubmitted"
          class="mdi mdi-checkbox-blank-circle"
        />
        <i
          v-else
          class="mdi mdi-checkbox-blank-circle-outline"
        />
      </template>
      <template v-slot:item.submitted="{ item }">
        <i
          v-if="item.submitted"
          class="mdi mdi-checkbox-blank-circle"
        />
        <i
          v-else
          class="mdi mdi-checkbox-blank-circle-outline"
        />
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          class="csv-download-button"
          color="primary"
          medium
          @click="downloadCsv"
        >
          <v-icon size="16">mdi-file-download</v-icon> CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import Vue from "vue";
import { mapGetters } from "vuex";

import { TableData } from "@/datatypes/table";
import createCSVFromRows from "@/utils/createCSVFromRows";

export default {
  name: "ScrapAnalyticsDetailsTable",
  props: {
    filters: { type: Object, default: () => {} }
  },
  data() {
    return {
      scrapDetailsTable: new TableData([
        { text: this.$t("Date"), value: "lastUpdateDate", width: "175px" },
        { text: this.$t("User"), value: "user", sortable: false },
        { text: this.$t("Machine"), value: "machine" },
        { text: this.$t("Reason"), value: "reason", sortable: false },
        { text: this.$t("Part"), value: "partNumber", sortable: false },
        { text: this.$t("Scrap Type"), value: "scrapType", sortable: false },
        { text: this.$t("Scrapped"), value: "enteredQuantity" },
        { text: this.$t("Cost"), value: "cost", sortable: false, cellClass: "cost" },
        { text: this.$t("Unsubmitted"), value: "unsubmitted", sortable: false, align: "center" },
        { text: this.$t("Submitted"), value: "submitted", sortable: false, align: "center" }
      ]),
      users: [],
      downloadingCsv: false,
      searchText: "",
      searchDebouncer: null
    };
  },
  computed: {
    ...mapGetters({
      machineLookup: "dbCache/machineFromPk"
    })
  },
  async created() {
    await this.getUsers();
    this.getDetailsTableScrap();
  },
  methods: {
    async getUsers() {
      let query = `{
        users {
          pk,
          id,
          firstName,
          lastName,
        }
      }`;
      try {
        const response = await this.$http.post("graphql/", { query });
        if (response && response.data && response.data.data) {
          this.users = response.data.data.users;
        }
        Promise.resolve();
      } catch (error) {
        console.error(error);
        Promise.resolve();
      }
    },
    async getDetailsTableScrap() {
      const query = `query (
        ${this.searchText ? "$queryString: String," : ""}
        ${this.filters.fromDate && this.filters.toDate ? `$filter: GrapheneElasticFilterScrapDocumentNodeConnectionBackendFilter!,` : ""}
        $ordering: GrapheneElasticOrderingScrapDocumentNodeConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String
      ) {
        allScrap (
          ${this.searchText ? "queryString: $queryString," : ""}
          ${this.filters.fromDate && this.filters.toDate ? "filter: $filter," : ""}
          ordering: $ordering,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [last_update_date]
        ) {
          facets,
          pageInfo {
            startCursor,
            endCursor,
            hasNextPage,
            hasPreviousPage,
          }
          edges {
            node {
              lastUpdateDate,
              submittedDate,
              lastUpdateUserId,
              machineId,
              partNumber,
              scrapType {
                name,
              },
              scrapCode {
                childComponent {
                  name,
                  description,
                }
              }
              enteredQuantity,
              scrapReasonCode{
                code
                description
              },
              description,
              cost,
            }
          }
        }
      }`;
      let ordering = { lastUpdateDate: "DESC" };
      if (this.scrapDetailsTable.sortBy.length > 0) {
        ordering = {};
        this.scrapDetailsTable.sortBy.forEach((sortBy, index) => {
          let sortField = sortBy;
          switch (sortBy) {
            case "machine":
              sortField = "machineId";
              break;
          }
          ordering[sortField] = this.scrapDetailsTable.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        ordering: ordering,
        before: false,
        after: false
      };
      if (this.searchText) {
        variables.queryString = "*" + this.searchText + "*";
      }
      if (this.filters.fromDate && this.filters.toDate) {
        variables.filter = {
          lastUpdateDate: {
            gte: { datetime: this.filters.fromDate },
            lte: { datetime: this.filters.toDate }
          }
        };
        if (this.filters.machines.length > 0) {
          variables.filter.machineId = { in: this.filters.machines };
        }
        if (this.filters.partNumbers.length > 0) {
          variables.filter.partNumber = { in: this.filters.partNumbers };
        }
      }
      if (this.scrapDetailsTable.page.load) {
        Object.assign(variables, this.scrapDetailsTable.page.load);
      } else {
        variables["first"] =
          this.scrapDetailsTable.page.itemsPerPage < 9999
            ? this.scrapDetailsTable.page.itemsPerPage
            : 9999;
      }
      try {
        this.scrapDetailsTable.loading = true;
        const response = await this.$http.post("graphql/", { query, variables });
        if (response && response.data && response.data.data) {
          const scrapData = response.data.data.allScrap.edges.map((scrap) => {
            const data = {
              lastUpdateDate: moment(scrap.node.lastUpdateDate).format(this.$datetime.default),
              machine: this.machineLookup(parseInt(scrap.node.machineId, 10)).name,
              partNumber: scrap.node.partNumber,
              reason: scrap.node.description,
              scrapType: scrap.node.scrapType ? scrap.node.scrapType.name : this.$t("Process"),
              enteredQuantity: scrap.node.enteredQuantity,
              cost: `$ ${scrap.node.cost.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
              unsubmitted: scrap.node.submittedDate ? false : true,
              submitted: scrap.node.submittedDate ? true : false
            };
            if (scrap.node.lastUpdateUserId) {
              const foundUser = this.users.find((user) => user.pk === scrap.node.lastUpdateUserId);
              if (foundUser) {
                data.user = {
                  firstName: foundUser?.firstName || "",
                  lastName: foundUser?.lastName || ""
                };
              } else {
                data.user = { firstName: "", lastName: "" };
              }
            }
            return data;
          });
          if (this.downloadingCsv) {
            this.scrapDetailsTable.loading = false;
            return Promise.resolve(scrapData);
          }
          this.scrapDetailsTable.data = scrapData;
          this.scrapDetailsTable.total =
            response.data.data.allScrap.facets.last_update_date.doc_count;
          this.scrapDetailsTable.page.next = response.data.data.allScrap.pageInfo.endCursor;
          this.scrapDetailsTable.page.prev = response.data.data.allScrap.pageInfo.startCursor;
          this.scrapDetailsTable.page.load = false;
          this.scrapDetailsTable.loading = false;
          return Promise.resolve();
        }
      } catch (error) {
        console.error(error);
        this.scrapDetailsTable.loading = false;
        return Promise.resolve();
      }
    },
    async downloadCsv() {
      this.downloadingCsv = true;
      const pageInfo = JSON.parse(JSON.stringify(this.scrapDetailsTable.page));
      this.scrapDetailsTable.page = {
        // TODO: Handle when total > 10000
        itemsPerPage: this.scrapDetailsTable.total,
        current: this.scrapDetailsTable.page.current,
        load: false,
        next: null,
        prev: null
      };
      const csvData = await this.getDetailsTableScrap();
      this.scrapDetailsTable.page = pageInfo;
      const rows = [];
      const headers = this.scrapDetailsTable.headers.map((item) => item.text);
      rows.push(headers);
      csvData.forEach((item) =>
        rows.push([
          item.lastUpdateDate,
          `${item.user?.firstName} ${item.user?.lastName}`,
          item.machine,
          item.reason,
          item.partNumber,
          item.scrapType,
          item.enteredQuantity,
          item.cost,
          item.unsubmitted,
          item.submitted
        ])
      );
      createCSVFromRows(rows, `Scrap_Details_${new Date().toISOString()}`);
      Vue.nextTick(() => {
        this.downloadingCsv = false;
      });
    }
  },
  watch: {
    "scrapDetailsTable.sortDesc": function () {
      this.getDetailsTableScrap();
    },
    "scrapDetailsTable.page.itemsPerPage": function () {
      if (this.downloadingCsv) {
        return;
      }
      this.getDetailsTableScrap();
    },
    "scrapDetailsTable.page.current": function (newPage, oldPage) {
      if (this.downloadingCsv) {
        return;
      }
      if (newPage > oldPage) {
        this.scrapDetailsTable.page.load = {
          after: this.scrapDetailsTable.page.next,
          before: false,
          first:
            this.scrapDetailsTable.page.itemsPerPage < 9999
              ? this.scrapDetailsTable.page.itemsPerPage
              : 9999
        };
        this.getDetailsTableScrap();
      } else if (newPage < oldPage) {
        this.scrapDetailsTable.page.load = {
          after: false,
          before: this.scrapDetailsTable.page.prev,
          last: this.scrapDetailsTable.page.itemsPerPage
        };
        this.getDetailsTableScrap();
      }
    },
    searchText() {
      if (this.searchDebouncer) {
        clearTimeout(this.searchDebouncer);
      }
      this.searchDebouncer = setTimeout(this.getDetailsTableScrap, 500);
    }
  }
};
</script>
<style lang="scss">
@import "./ScrapAnalyticsDetailsTable.scss";
</style>
