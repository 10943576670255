var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(_setup.MenuNestedLayout,{attrs:{"show":_vm.show,"back-label":_setup.$t('Back'),"show-level1":_vm.mode === 'locationOnly' || _setup.selectionType !== 'assets',"level-name":_vm.locationContext?.name || 'Groups',"level1-search":_setup.subLocationTerm,"level2-search":_setup.assetFilter.terms},on:{"level1-search":function($event){_setup.subLocationTerm = $event},"level2-search":function($event){_setup.assetFilter.terms = $event},"back":function($event){return _setup.emit('back', $event)},"level2-hover-body":_setup.closeSubLocationMenu},scopedSlots:_vm._u([{key:"level1List",fn:function(){return _vm._l((_setup.assetLocations),function(location){return _c(_setup.MenuItem,{key:location.pk,attrs:{"icon-left":_setup.locationIconByDepth(location.depth),"label":location.name,"icon-right":location.children.length > 0 ? 'mdi-chevron-right' : ''},on:{"click":function($event){return _setup.selectLocation(location)}}})})},proxy:true},{key:"level2Header",fn:function(){return [_c('div',[_vm._l((Object.values(_setup.selectedLocations)),function(selectedLocation){return _c(_setup.MenuItem,{key:selectedLocation.id,attrs:{"active":true,"label":selectedLocation.name,"icon-right":"mdi-chevron-right","icon-right-size":"24","show-close-icon":""},on:{"click":function($event){return _setup.reselectLocation(selectedLocation)},"click-close-icon":function($event){return _setup.removeLocation(selectedLocation)}}})}),_vm._v(" "),(_setup.hasChildLocations(_setup.currentLocation))?_c(_setup.MenuItem,{key:"sublocation",attrs:{"label":_setup.$t('Sub-groups'),"icon-right":"mdi-chevron-right"},on:{"mouseenter":function($event){return _setup.openSubLocationMenu(_setup.currentLocation, $event)},"click":function($event){return _setup.openSubLocationMenu(_setup.currentLocation, $event)}}}):_vm._e()],2)]},proxy:true},{key:"level2BodyList",fn:function(){return [_c(_setup.MenuItem,{attrs:{"to":{
          name: 'aeyevision/group',
          params: {
            groupId: _setup.currentLocation.pk,
            group: { ..._setup.currentLocation },
            type: 'group'
          }
        },"label":_setup.$t('{group} Assets', { group: _setup.currentLocation.name }),"icon-right":"mdi-chevron-right"}}),_vm._v(" "),_vm._l((_setup.filteredAssets),function(asset){return _c(_setup.MenuItem,{key:asset.id,attrs:{"icon-left":"mdi-robot-industrial","to":{
          name: 'aeyevision/asset',
          params: {
            assetId: asset.pk || asset.id,
            asset: { ...asset, pk: asset.pk || asset.id },
            type: 'asset'
          }
        },"label":asset.name,"icon-right":"mdi-chevron-right"}})})]},proxy:true}])}),_vm._v(" "),_c(_setup.Teleport,{attrs:{"to":"#menu"}},[(_setup.showSubLocationMenu)?_c('menu-asset',{attrs:{"container-class":"menu-nested","mode":"locationOnly","location-context":_setup.subLocationContext,"show":""},on:{"select":_setup.selectLocation,"back":_setup.closeSubLocationMenu}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }