<template>
  <Card
    class="pa-5 rounded-lg"
    custom-padding
  >
    <MobileOnlyExpansion>
      <template #header>
        <span class="text-body-1 font-weight-medium color--text-primary">{{ $t("Details") }}</span>
      </template>
      <template #content>
        <div class="type color--text-primary mt-4">
          <span class="color--text-secondary">{{ $t("Ref ID") }}</span>
          <div class="d-flex align-center ref-wrapper">
            <div class="ref-btn d-flex align-center">
              <span
                v-if="ticket.reference"
                class="mr-7"
                >{{ ticket.reference }}</span
              >
              <Btn
                text
                class="pa-0 ml-n3 ml-sm-n5"
                @click="showSubscribeDialog = true"
                @close="showSubscribeDialog = false"
              >
                {{ ticket.reference ? $t("Edit") : $t("Add") }}
              </Btn>
            </div>
          </div>
          <span class="color--text-secondary">{{ $t("Created by") }}</span>
          <span>{{ ticket.ticketedByName }}</span>
          <span class="color--text-secondary">{{ $t("Assigned") }}</span>
          <span>{{ assignedUsers }}</span>
          <span class="color--text-secondary">{{ $t("Responding") }}</span>
          <span>{{ respondingUsers }}</span>
          <span class="color--text-secondary">{{ $t("Date") }}</span>
          <span>{{ ticketCreatedDate }}</span>
          <span class="color--text-secondary">{{ $t("Machine") }}</span>
          <span>{{ ticket.machineName }}</span>
          <span class="color--text-secondary">{{ $t("Part") }}</span>
          <span>{{ ticket.partName }}</span>
          <span class="color--text-secondary">{{ $t("Factory Order") }}</span>
          <span>{{ ticket.factoryOrder }}</span>
        </div>
      </template>
    </MobileOnlyExpansion>

    <TicketDetailsSubscribeDialog
      :open="showSubscribeDialog"
      :ticket="ticket"
      @close="showSubscribeDialog = false"
      @success="$emit('success')"
    />
  </Card>
</template>

<script setup>
import moment from "moment-timezone";
import { computed, ref } from "vue";

import Btn from "@/ui/Btn";
import Card from "@/ui/Card";
import MobileOnlyExpansion from "@/ui/MobileOnlyExpansion";
import TicketDetailsSubscribeDialog from "@/features/ticket-details/components/TicketDetailsSubscribeDialog.vue";
import { FULL_DATE_FORMAT } from "../constants";

const props = defineProps({
  ticket: {
    type: Object,
    default: () => ({})
  }
});

const showSubscribeDialog = ref(false);
const ticketCreatedDate = computed(() =>
  moment(props.ticket.ticketedDate).format(FULL_DATE_FORMAT)
);
const assignedUsers = computed(() => {
  return props.ticket?.assignedToNames?.slice(1, -1).replace(/["']/g, "").trim();
});
const respondingUsers = computed(() => {
  return props.ticket?.respondingNames?.slice(1, -1).replace(/["']/g, "").trim();
});

defineExpose({
  assignedUsers,
  respondingUsers,
  ticketCreatedDate,
  showSubscribeDialog
});
</script>

<style lang="scss" scoped>
.type {
  display: grid;
  gap: 16px 12px;
  grid-template-columns: auto 1fr;

  span {
    margin: auto 0;
  }
}

.ref-wrapper {
  position: relative;
}

.ref-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
</style>
