<template>
  <div>
    <p
      v-if="label"
      class="mb-2 text-subtitle-1 color--text-secondary"
    >
      {{ label }}
    </p>
    <v-autocomplete
      ref="autocomplete"
      v-model="search"
      :items="items"
      :name="name"
      :multiple="multiple"
      :menu-props="{
        closeOnClick: true,
        contentClass: 'custom-dropdown',
        'max-width': DROPDOWN_FILTERS_WIDTH,
        ...menuProps
      }"
      :placeholder="placeholder || $t('Search')"
      :loading="loading"
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="isDisabled"
      class="custom"
      clearable
      solo
      attach
      hide-details
      @change="$emit('change', $event, name)"
      @update:search-input="$emit('update:search-input', $event, name)"
    >
      <template
        v-if="concatSelectedFrom"
        #selection="{ item, index }"
      >
        <span
          v-if="index < concatSelectedFrom"
          class="mr-1"
        >
          {{ selectedItems(item, index) }}
        </span>
        <span
          v-if="index === concatSelectedFrom"
          class="grey--text text-caption mt-1"
          >{{ `(+${search.length - concatSelectedFrom} ${$t("others")})` }}
        </span>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { ref, watch } from "vue";

export const DROPDOWN_FILTERS_WIDTH = 368;

export default {
  name: "DropdownAutocomplete",
  components: {},
  props: {
    items: { type: Array, default: () => [] },
    label: {
      type: String,
      default: ""
    },
    name: { type: String, default: "" },
    value: { type: [Array, Number, Object], default: undefined },
    multiple: { type: Boolean, default: true, required: false },
    concatSelectedFrom: { type: Number, default: 0, required: false },
    loading: { type: Boolean, default: false, required: false },
    placeholder: { type: String, default: "", required: false },
    itemText: { type: String, default: undefined, required: false },
    itemValue: { type: [String, Array, Function], default: undefined, required: false },
    menuProps: { type: Object, default: undefined, required: false },
    isDisabled: { type: Boolean, default: false, required: false }
  },
  setup(props) {
    const search = ref(props.value);
    const autocomplete = ref(null);

    const selectedItems = (item, index) => {
      let text = item.text;
      if (props.itemText) {
        text = item[props.itemText];
      }
      return `${text}${index < props.concatSelectedFrom - 1 && index !== search.value.length - 1 ? "," : ""}`;
    };

    watch(
      () => props.value,
      (value) => {
        if (value === undefined) {
          autocomplete.value.reset();
          autocomplete.value.blur();
          return;
        }
        search.value = value;
      },
      {
        deep: true
      }
    );

    return {
      autocomplete,
      search,
      selectedItems,
      DROPDOWN_FILTERS_WIDTH
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/input";
</style>
