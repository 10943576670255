import { useRoute } from "vue-router/composables";
import { AppRoutes } from "@/shared/app-routes";
import { computed } from "vue";

export const TICKET_ROUTES = [
  AppRoutes.machineTicketsP911.name,
  AppRoutes.machineTicketsLdms.name,
  AppRoutes.groupTicketsP911.name,
  AppRoutes.groupTicketsLdms.name
];

export const useTickets = () => {
  const route = useRoute();

  const isTickets = computed(() => {
    const routeName = route.name;
    return TICKET_ROUTES.includes(routeName);
  });

  return {
    isTickets
  };
};
