<template>
  <div
    class="menu-item d-flex flex-column cursor-pointer rounded-lg mb-3"
    :class="{ active: active }"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <component
      :is="!to ? 'div' : RouterLink"
      class="label d-flex flex-grow-1 px-3 align-center"
      :to="to"
    >
      <v-icon
        v-if="Boolean(iconLeft)"
        :class="{ 'mr-2': label }"
        size="24"
        >{{ iconLeft }}</v-icon
      >

      <slot name="prepend"></slot>

      <span
        v-if="label"
        class="flex-grow-1"
        >{{ label }}</span
      >
      <v-icon
        v-if="iconRight"
        :size="iconRightSize"
        @click="$emit('click-icon-right')"
        >{{ iconRight }}</v-icon
      >
      <div
        v-if="showCloseIcon"
        class="d-flex"
      >
        <v-divider
          vertical
          class="mr-3 ml-3 flex-grow-1"
        />
        <v-icon
          class="mr-1 text--secondary"
          size="24"
          @click.stop="$emit('click-close-button')"
        >
          mdi-close
        </v-icon>
      </div>

      <slot name="append"></slot>
    </component>
  </div>
</template>

<script>
export default {
  name: "MenuItem"
};
</script>
<script setup>
import { RouterLink } from "vue-router";

defineProps({
  label: { type: String, default: "" },
  iconLeft: { type: String, default: "" },
  iconRight: { type: String, default: "" },
  iconRightSize: { type: String, default: "16" },
  active: { type: Boolean, default: false },
  to: { type: [String, Object], default: undefined },
  showCloseIcon: {
    type: Boolean,
    default: false
  }
});
</script>

<style lang="scss" scoped>
.menu-item {
  margin-left: rem(-12px);
  margin-right: rem(-12px);
  cursor: pointer;
  text-decoration: none;
  color: var(--text-secondary);
  min-height: rem(44px);

  @media #{map-get($display-breakpoints, 'xs-only')} {
    min-height: rem(52px);
  }

  i {
    color: var(--text-secondary);
  }

  &:hover:not(.active) {
    color: var(--text-primary);
    background: var(--bg-card-white);

    i,
    .v-icon {
      color: var(--text-primary);
    }
  }

  &.active {
    color: var(--text-accent);
    background: var(--bg-card-white);

    i,
    .v-icon {
      color: var(--text-accent);
    }
  }
}

.label {
  white-space: nowrap;
  overflow: hidden;
  font-weight: 400;
}

@import "@/scss/variables";
</style>
