<template>
  <v-btn-toggle
    v-bind="$attrs"
    v-on="$listeners"
    mandatory
    class="custom-btn-toggle"
  >
    <slot></slot>
  </v-btn-toggle>
</template>

<script>
export default {
  name: "BtnToggle"
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables";

.custom-btn-toggle {
  padding: rem(2px);
  border-radius: rem(8px);
  overflow-x: auto;
  text-wrap: nowrap;
  max-width: 100%;

  ::v-deep .v-btn {
    border-radius: rem(6px) !important;
    border-width: rem(1px) !important;
    min-height: rem(40px) !important;
    height: rem(40px) !important;
    padding-left: rem(16px) !important;
    padding-right: rem(16px) !important;
    letter-spacing: -0.01em;

    &:not(:first-of-type):not(.v-btn--active) {
      .v-btn__content::before {
        content: "";
        height: rem(17px);
        transform: translateX(rem(-16px));
        border-left: rem(1px) solid var(--border-primary) !important;
      }
    }

    &:not(.v-btn--active) {
      border: none !important;
      background-color: transparent;

      &,
      .v-icon {
        color: var(--text-tertiary) !important;
      }
    }

    &.v-btn--active {
      &.theme--dark, // adding this because !important is not enough to override vuetify styles
      &.theme--light {
        border-color: var(--border-secondary) !important;
        box-shadow: rem(0px) rem(1px) rem(2px) rem(0px) var(--alpha-black-5);
      }

      &,
      .v-icon {
        color: var(--v-primary-base) !important;
      }

      + .v-btn .v-btn__content::before {
        display: none;
      }
    }

    &::before {
      background: none;
    }

    .v-btn__content {
      font-weight: 500 !important;
    }
  }

  &.theme--dark {
    background-color: var(--bg-card-white) !important;

    ::v-deep .v-btn.v-btn--active {
      background-color: var(--bg-card-white) !important;
    }
  }

  &.theme--light {
    background-color: var(--bg-card-white) !important;

    ::v-deep .v-btn.v-btn--active {
      background-color: var(--bg-primary) !important;
    }
  }
}
</style>
