<template>
  <div>
    <v-btn
      color="primary"
      @click="triggerFileInput"
    >
      <v-icon left>mdi-upload</v-icon> {{ buttonText }}
    </v-btn>
    <input
      type="file"
      ref="fileInput"
      class="d-none"
      :multiple="allowMultiple"
      :accept="acceptedFileTypes"
      capture="environment"
      @change="onFileChange"
    />

    <div class="file-list">
      <v-chip
        v-for="(file, index) in selectedFiles"
        :key="index"
        class="ma-1"
        close
        @click:close="removeFile(index)"
      >
        <v-icon left>mdi-file</v-icon>
        {{ file.name }}
      </v-chip>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";

const props = defineProps({
  buttonText: { type: String, default: "Upload File" },
  allowMultiple: { type: Boolean, default: false },
  acceptedFileTypes: { type: String, default: "*/*" },
  maxFileSize: { type: Number, default: 50 * 1024 * 1024 },
  categoryIndex: { type: Number, required: true },
  questionIndex: { type: Number, required: true }
});

const emit = defineEmits(["files-selected", "file-removed"]);

const fileInput = ref(null);
const selectedFiles = ref([]);

const triggerFileInput = () => {
  fileInput.value.click();
};

function onFileChange(event) {
  const files = Array.from(event.target.files).filter((file) => {
    const isValidSize = file.size <= props.maxFileSize;
    return isValidSize;
  });

  if (files.length > 0) {
    selectedFiles.value.push(...files);
    emit("files-selected", selectedFiles.value, props.categoryIndex, props.questionIndex);
  } else {
    console.log("No valid files after filtering (e.g., size exceeded).");
  }

  event.target.value = "";
}

function removeFile(index) {
  selectedFiles.value.splice(index, 1);
  emit("file-removed", {
    categoryIndex: props.categoryIndex,
    questionIndex: props.questionIndex,
    files: selectedFiles.value
  });
}
</script>

<style scoped>
.file-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
</style>
