<template>
  <div class="level2-analytics-tile">
    <div class="tile events">
      <p class="tile-label">{{ $t("Total Number of Events") }}</p>
      <p class="tile-value">{{ numberWithCommas(events) }}</p>
    </div>
    <div class="tile downtime">
      <p class="tile-label">{{ $t("Total Downtime") }}</p>
      <p class="tile-value">{{ seconds(downtime) }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import { numberWithCommas, seconds } from "../../../../utils/filters";
import { getEndTime, getStartTime } from "../../../../utils/level2Utils";

export default {
  name: "Level2Tiles",
  props: ["filterController", "interactiveFilters"],
  created() {
    this.loadTileData();
  },
  data() {
    return {
      events: 0,
      downtime: 0,
      codes: [],
      data: []
    };
  },
  methods: {
    numberWithCommas,
    seconds,
    ...mapMutations({
      setMachineCodes: "dbCache/SET-MACHINES-CODES"
    }),
    loadTileData() {
      let params = {
        from_date: this.filterController.initialFilters.date_range.from_date,
        to_date: this.filterController.initialFilters.date_range.to_date
      };
      if (this.filterController.hasFilter("machines")) {
        params["machine_ids"] = this.filterController.getFilterPks("machines");
      }
      if (this.filterController.hasFilter("machine_groups")) {
        params["machine_group_ids"] = this.filterController.getFilterPks("machine_groups");
      }
      if (this.interactiveFilters.machines.length > 0) {
        params.machine_ids = this.interactiveFilters.machines.map((machine) => machine.id);
      }
      if (this.interactiveFilters.codes.length > 0) {
        params.codes = this.interactiveFilters.codes.map((code) => code.id);
      }
      if (this.interactiveFilters.trends.length > 0) {
        const trendKeys = this.interactiveFilters.trends.map((trend) => trend.id);
        params.from_date = getStartTime(
          Math.min(...trendKeys),
          this.filterController.initialFilters.scale
        );
        params.to_date = getEndTime(
          Math.max(...trendKeys),
          this.filterController.initialFilters.scale
        );
      }
      if (this.filterController.initialFilters.codeEventType) {
        params.event_type = this.filterController.initialFilters.codeEventType;
      }
      if (this.filterController.hasFilter("parts")){
        params.part_numbers = this.filterController.initialFilters.parts;
      }
      this.$http.get("level2/summary/", { params }).then((res) => {
        this.events = res.data.events;
        this.downtime = res.data.downtime;
        if (
          this.filterController.initialFilters.machines.length === 1 ||
          this.interactiveFilters.machines.length === 1
        ) {
          this.loadData();
        } else {
          this.resetMachineCodes();
        }
      });
    },
    resetMachineCodes() {
      this.filterController.initialFilters.code = null;
      // this.filterController.initialFilters.codeEventType = null;
      this.setMachineCodes([]);
    },
    loadData() {
      this.loading = true;
      const query = `query(
        $filters: GrapheneElasticFilterLevel2EventNodeConnectionBackendFilter!
        $machineId: Int!){
        level2Events(
          filter: $filters,) {
          edges{
            node{
              machineId,
              partNumber,
              factoryOrder,
              startTime,
              endTime,
              eventDuration,
              statusCode
            }
          }
        }
        level2Codes(machineId: $machineId){
          id
          pk
          machine{
            pk
          }
          part{
            pk
          }
          code
          description
          eventType
        }
      }`;
      const variables = {
        filters: {
          startTime: {
            range: {
              lower: { datetime: this.filterController.initialFilters.date_range.from_date },
              upper: { datetime: this.filterController.initialFilters.date_range.to_date }
            }
          }
        }
      };
      let machinePk;
      if (this.filterController.initialFilters.machines.length === 1) {
        machinePk = this.filterController.initialFilters.machines[0].pk;
      } else {
        machinePk = this.interactiveFilters.machines[0].id;
      }
      variables["filters"]["machineId"] = { terms: machinePk };
      variables["machineId"] = machinePk;
      return this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.data = res.data.data["level2Events"].edges;
          this.codes = res.data.data.level2Codes;
          this.setMachineCodes(this.codes);
          this.loading = false;
        })
        .catch((e) => {
          console.error(e);
          this.loading = false;
        });
    }
  },
  computed: {
    ...mapGetters({
      machineCodes: "dbCache/machineCodes"
    })
  },
  watch: {
    "filterController.reload": function () {
      this.loadTileData();
    },
    "interactiveFilters.codes.length": function () {
      if (!this.loading) {
        this.loadTileData();
      }
    },
    "interactiveFilters.trends": function () {
      if (!this.loading) {
        this.loadTileData();
      }
    },
    "interactiveFilters.machines.length": function () {
      if (!this.loading) {
        this.loadTileData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";
.level2-analytics-tile {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;

  margin: 10px;
  margin-bottom: 1px;

  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  justify-content: space-between;
  .tile {
    flex: 1 1 100%; // makes even column
    border-radius: 5px;
    position: relative;
    height: 100px;
    &.events {
      background: $tiffanyBlue;
    }
    &.downtime {
      background: $coralReef;
    }
    .tile-label {
      font-size: 20px;
      font-weight: 600;
      margin: 7px 10px;
    }
    .tile-value {
      font-size: 35px;
      position: absolute;
      margin: 7px 10px;
      bottom: 0px;
      right: 0px;
    }
  }
}
</style>
