// The Vue build version to load with the `import` command
//okta configuration
import * as am4core from "@amcharts/amcharts4/core";
import * as am5 from "@amcharts/amcharts5";
import DigitalForms from "@intelycx/digital-forms";
import IOT from "@intelycx/iot";
import { OktaAuth } from "@okta/okta-auth-js";
import OktaVue from "@okta/okta-vue";
import axios from "axios";
import ChartJS from "chart.js";
import chartTrendline from "chartjs-plugin-trendline";
import "url-search-params-polyfill";
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import VueRouter from "vue-router";
import "vuetify/src/styles/main.sass";
import Vuex from "vuex";
//Localization Module
import vuexI18n from "vuex-i18n";

import App from "./App";
import translationsEn from "./assets/lang/en.json";
import timeline from "./components/charts/Timeline.js";
import VueConsole from "./plugins/console";
import vuetify from "./plugins/vuetify";
import router from "./router";
import "./scss/global.scss";
import store from "./store/index";
import colors from "./utils/colors";
import datetime from "./utils/datetime";
import EventBus from "./utils/eventbus";
import Resource from "./utils/resource";
import shim from "./utils/shim";
import { VueQueryPlugin } from "@tanstack/vue-query";
import Vuetify from "vuetify/lib";
import { env } from "./shared/env";
import PortalVue from "portal-vue";

Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(EventBus);
Vue.use(Resource);
Vue.use(PortalVue);

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  }
};

Vue.use(VueQueryPlugin, vueQueryPluginOptions);
shim();

colors();

datetime();

timeline();

am5.addLicense("AM5C385462996");
am5.addLicense("AM5M385462996");

am4core.addLicense("CH385462996");
am4core.addLicense("MP385462996");

ChartJS.plugins.register(chartTrendline);

Vue.config.productionTip = false;
// eslint-disable-next-line no-unused-vars
const _ = require("lodash");

window.addEventListener("load", function () {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.register("/js/sw.js").then(function (reg) {
      if (reg.installing) {
        console.log("Service worker installing");
      } else if (reg.waiting) {
        console.log("Service worker installed");
      } else if (reg.active) {
        console.log("Service worker active");
      }

      if (!reg.showNotification) {
        console.log("Notifications aren't supported on service workers.");
        store.dispatch("app/EnableNotifications", false);
      } else {
        store.dispatch("app/EnableNotifications", true);
      }
    });
  } else {
    console.log("Service workers aren't supported in this browser.");
  }
});

function setOkta(issuer, clientId) {
  const oktaAuth = new OktaAuth({
    issuer,
    clientId,
    redirectUri: window.location.origin + "/login/okta",
    postLogoutRedirectUri: window.location.origin + "/login",
    scopes: ["openid", "profile", "email"]
  });
  Vue.use(OktaVue, { oktaAuth });
}

async function handleOkta() {
  let issuer = localStorage.getItem("issuer");
  let clientId = localStorage.getItem("clientId");
  if (issuer && clientId && !(issuer === "null" || clientId === "null")) {
    const issuer = localStorage.getItem("issuer");
    const clientId = localStorage.getItem("clientId");
    return Promise.resolve(setOkta(issuer, clientId));
  }

  try {
    await axios.get("oktainfo/").then((res) => {
      if (res.data.issuer && res.data.clientId) {
        localStorage.setItem("issuer", res.data.issuer);
        localStorage.setItem("clientId", res.data.clientId);
        return Promise.resolve(setOkta(res.data.issuer, res.data.clientId));
      }
    });
  } catch (error) {
    console.error("Error obtaining Okta info");
    return Promise.resolve();
  }
}

// before the router takes effect, check if there was a jwt in the query params
// and write it to storage
let params = new URLSearchParams(window.location.search);
if (params.has("jwt")) {
  store.dispatch("session/SetJWT", params.get("jwt"));
}

Vue.use(vuexI18n.plugin, store);
// Initialize translations in the i18n plugin
Vue.i18n.add("en", translationsEn);
Vue.i18n.set("en");
Vue.use(Vuetify);

Vue.use(VueConsole);

Vue.use(DigitalForms, {
  apiUrl: env.DFBaseUrl,
  store
});

Vue.use(IOT, {
  apiUrl: env.IOTBaseUrl,
  store
});
handleOkta().then(() => {
  new Vue({
    el: "#app",
    router,
    store,
    template: "<app/>",
    vuetify,
    components: { App }
  });
});
Vue.config.devtools = true;

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      // here I check that click was outside the el and his childrens
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        if (vnode.context[binding.expression]) {
          vnode.context[binding.expression](event);
        }
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.event);
  }
});
