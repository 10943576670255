<template>
  <div class="py-6 px-5 px-md-6 d-flex flex-column page">
    <div class="text-body-2 font-weight-medium mb-4">
      <a
        :class="{ 'disabled-breadcrumb': !machine_pk || !machine_id }"
        @click.prevent="backToTickets"
        >{{ $t("Tickets") }}</a
      >
      <span class="mx-1 font-weight-regular">/</span>
      <span class="">{{ ticket?.machineName }}</span>
    </div>

    <div class="composition">
      <div class="ticket-header d-flex align-center mb-1">
        <h1 class="text-h5 font-weight-medium">{{ ticket?.machineName }}</h1>

        <div class="ml-auto">
          <Btn
            large
            :icon="isSm"
            :text="isSm"
            @click="showEditDialog = true"
            ><v-icon size="24">mdi-pencil-outline</v-icon>
            <span
              v-if="!isSm"
              class="ml-1 font-weight-bold"
              >{{ $t("Edit") }}</span
            >
          </Btn>
          <Btn
            large
            class="ml-3"
            :icon="isSm"
            :text="isSm"
            @click="showSolutionDialog = true"
          >
            <v-icon size="24"> mdi-arrow-decision-outline </v-icon>
            <span
              v-if="!isSm"
              class="ml-1 font-weight-bold"
              >{{ $t("Similar solutions") }}</span
            >
          </Btn>
        </div>
      </div>

      <div class="ticket-body mb-10">
        <div class="type text-body-1 color--text-secondary mb-3">
          <span>{{ $t("Category") }}</span>
          <span>
            <span class="ticket-issue color--text-primary text-subtitle-1">{{
              categoryIssue?.name
            }}</span>
          </span>

          <span class="text-subtitle-1">{{ $t("Issue") }}</span>
          <span class="d-flex flex-wrap mb-n1">
            <v-chip
              v-for="(issue, issueIndex) in qualityIssues"
              :key="issueIndex"
              :color="issue.color"
              class="ticket-issue color--text-contrast text-subtitle-1 py-1 px-2 mb-1"
              label
            >
              {{ issue.name }}
            </v-chip>
          </span>
        </div>

        <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-1">{{ $t("Description") }}</h2>
          <p class="text-subtitle-1">
            {{ ticket?.details }}
          </p>
        </div>

        <div class="ticket-body-item">
          <h2 class="text-body-1 color--text-secondary mb-2">{{ $t("Solution") }}</h2>
          <component
            :is="resolution.DemoComponent"
            v-if="resolution"
            :item="resolution"
            class="mb-2"
          />
          <p class="text-subtitle-1">
            {{ ticket?.solutionDetails }}
          </p>
        </div>
      </div>

      <TicketDetailsControl
        class="ticket-control"
        :ticket="ticket"
        :users="users"
        :refetch="refetch"
      />

      <TicketDetailsActivity
        class="ticket-comments"
        :filters="filters"
        :new-comment-params="commentParams"
        :reload="reload"
        :ticket="ticket"
        :tabs="[
          {
            label: $t('All')
          },
          {
            label: $t('Comments')
          },
          { label: $t('History') }
        ]"
      />

      <TicketDetailsSolutionDialog
        :open="showSolutionDialog"
        :ticket="ticket"
        @close="showSolutionDialog = false"
      />

      <TicketDetailsEditDialog
        v-if="showEditDialog"
        :open="showEditDialog"
        :ticket="ticket"
        @close="showEditDialog = false"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { useRoute, useRouter } from "vue-router/composables";

import Btn from "@/ui/Btn";
import TicketDetailsActivity from "./components/TicketDetailsActivity.vue";
import TicketDetailsSolutionDialog from "./components/TicketDetailsSolutionDialog.vue";
import TicketDetailsEditDialog from "./components/TicketDetailsEditDialog.vue";
import TicketDetailsControl from "./components/TicketDetailsControl.vue";
import { useTicketDetails } from "./useTicketDetails";
import { useTicketWebsocket } from "@/features/ticket-details/useTicketWebsocket";
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { useTicketWorkFlowConfig } from "@/features/ticket-details/useTicketWorkFlowConfig";
import { useMachineQuery } from "@/features/machine";
import { AppRoutes } from "@/shared/app-routes";

const route = useRoute();
const router = useRouter();
const { isSm } = useDeviceResolution();

const variables = computed(() => ({
  id: route.params.ticket_id
}));

const reload = ref(false);

const handleWebsocketUpdate = () => {
  reload.value = !reload.value;
};

const { ticket, users, refetch } = useTicketDetails(variables);
const { getConfig } = useTicketWorkFlowConfig();
useTicketWebsocket(variables, handleWebsocketUpdate);

const machine_pk = computed(() => ticket.value?.machineId);
const { machine } = useMachineQuery(machine_pk);

const categoryIssue = computed(() => ticket.value?.issues?.[0] || null);
const qualityIssues = computed(() => ticket.value?.issues?.slice(1) || []);
const workflowConfig = getConfig(ticket);

const resolution = computed(() => workflowConfig?.value[ticket.value.solutionType]);

const machine_id = computed(() => machine.value?.id);

const backToTickets = () => {
  if (!machine_pk.value || !machine_id.value) return;
  router.push({
    name: AppRoutes.machineTickets.name,
    params: { machine_id: machine_id.value, machine_pk: machine_pk.value }
  });
};

const filters = computed(() => ({
  ticketId: { value: route.params.ticket_id }
}));

const commentParams = computed(() => ({
  ticketId: route.params.ticket_id
}));

const showSolutionDialog = ref(false);
const showEditDialog = ref(false);

defineExpose({
  isSm,
  categoryIssue,
  qualityIssues,
  commentParams,
  filters,
  ticket,
  users,
  showSolutionDialog,
  showEditDialog,
  backToTickets,
  refetch,
  reload,
  machine_id,
  machine_pk
});
</script>

<style lang="scss" scoped>
@import "@/scss/mq";

.type {
  display: grid;
  gap: 12px 24px;
  grid-template-columns: auto 1fr;
}

.composition {
  display: grid;
  grid-template-areas:
    "header control"
    "body control"
    "comments control";
  column-gap: 32px;
  grid-template-columns: auto 460px;
  grid-template-rows: auto auto 1fr;

  @include media_below(1367px) {
    grid-template-columns: auto 360px;
  }

  @include media_below(1025px) {
    @media screen and (orientation: portrait) {
      grid-template-areas:
        "header"
        "body"
        "control"
        "comments";
      column-gap: 32px;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
  }
}

.ticket-issue {
  margin: 0 5px 5px 0;
}

.ticket-header {
  grid-area: header;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.ticket-body {
  grid-area: body;

  @media (max-width: 950px) and (orientation: landscape) {
    margin-bottom: 24px !important;
  }

  @media (max-width: 1100px) and (orientation: portrait) {
    margin-bottom: 24px !important;
  }
}

.ticket-body-item {
  & ~ .ticket-body-item {
    margin-top: 16px;
  }
}

.ticket-control {
  grid-area: control;
}

.ticket-comments {
  grid-area: comments;
}

.disabled-breadcrumb {
  pointer-events: none;
  color: var(--text-disabled);
  cursor: not-allowed;
}
</style>
