<template>
  <div
    :id="id"
    class="treemap-chart"
  ></div>
</template>

<script>
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { mapGetters } from "vuex";

import { white, darkGrey } from "@/scss/_variables.js";
import { setAMChartsLocale } from "@/utils/language";

export default {
  props: {
    data: { type: Array, default: () => [] },
    id: { type: String, default: "chart" },
    handleClick: { type: Function, default: () => null },
    handleZoomOutClick: { type: Function, default: () => null },
    labelFormat: { type: String, default: "{name}; {value}" },
    maxLevels: { type: Number, default: 1 }
  },
  data() {
    return {
      chart: null,
      levels: {},
      levelIndex: 0
    };
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    })
  },
  mounted() {
    this.createTreeMapChart();
  },
  destroyed() {
    if (this.chart !== null) {
      this.chart.dispose();
    }
  },
  methods: {
    getLevels(items) {
      items.every((item, index) => {
        if (index === 0) {
          const levelName = `level${this.levelIndex}`;
          const levelColumnName = `level${this.levelIndex}Column`;
          const levelBulletName = `level${this.levelIndex}Bullet`;
          this.levels[levelName] = this.chart.seriesTemplates.create(this.levelIndex.toString());
          this.levels[levelColumnName] = this.levels[levelName].columns.template;
          this.levels[levelColumnName].tooltipText = this.labelFormat;
          this.levels[levelBulletName] = this.levels[levelName].bullets.push(
            new am4charts.LabelBullet()
          );
          this.levels[levelBulletName].locationY = 0.5;
          this.levels[levelBulletName].locationX = 0.5;
          this.levels[levelBulletName].label.text = this.labelFormat;
          this.levels[levelBulletName].label.fill = am4core.color(white);
          this.levels[levelBulletName].label.fontSize = ".8rem";
          this.levels[levelBulletName].label.fontWeight = 500;
          if (this.theme === "dark") {
            this.levels[levelName].columns.template.stroke = am4core.color(darkGrey);
          }
          this.levels[levelName].columns.template.events.on("hit", (event) =>
            this.handleClick(event)
          );
        }
        if ("children" in item && Array.isArray(item.children) && item.children.length > 0) {
          this.levelIndex += 1;
          this.getLevels(item.children);
          return false;
        }
        return true;
      });
    },
    createTreeMapChart() {
      if (this.chart !== null) {
        this.chart.dispose();
        this.levels = {};
        this.levelIndex = 0;
      }
      am4core.useTheme(am4themes_animated);
      am4core.options.autoSetClassName = true;
      this.chart = am4core.create(this.id, am4charts.TreeMap);
      this.chart.data = this.data;
      this.chart.dataFields.value = "value";
      this.chart.dataFields.name = "name";
      this.chart.dataFields.color = "color";
      this.chart.dataFields.children = "children";
      this.chart.maxLevels = this.maxLevels;
      this.chart.zoomOutButton.events.on("hit", (event) => this.handleZoomOutClick(event));
      setAMChartsLocale(this.chart);
      this.getLevels(this.chart.data);
      //div was not sizing correctly until it resizes
      requestAnimationFrame(() => {
        document.getElementById(this.id).style.height = "0%";
        requestAnimationFrame(() => {
          document.getElementById(this.id).style.height = "100%";
        });
      });
    }
  },
  watch: {
    data() {
      this.createTreeMapChart();
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";

@supports (paint-order: stroke) {
  .treemap-chart {
    .amcharts-LabelBullet {
      text {
        font-weight: 700;
        letter-spacing: 1px;
      }
    }
  }
}
</style>
