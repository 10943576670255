import axios from "axios";
import { env } from "@/shared/env";

const apiUrl = `${env.apiBaseUrl}/audits/files/upload/`;

export const auditFileUploadApi = {
  uploadFile: (formData) =>
    axios
      .post(apiUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "application/json"
        }
      })
      .then((res) => res.data)
};
