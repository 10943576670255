<template>
  <div
    id="menu"
    v-click-outside="collapseAll"
    class="menu text--secondary d-flex"
  >
    <v-app-bar-nav-icon
      class="d-flex d-sm-none menu-sm-toggle-btn"
      :class="{ expanded: expanded || subMenuExpanded }"
      @click="mobileToggle"
    >
      <v-icon v-if="!isAllCollapsed">mdi-close</v-icon>
    </v-app-bar-nav-icon>
    <!-- #region Main Menu -->
    <div
      class="main-menu px-3 py-5 d-flex flex-column"
      :class="{ expanded }"
    >
      <div class="main-menu-items">
        <MenuLink
          v-for="item in items"
          :key="item.key"
          :tooltip-disabled="expanded || isSelected(item.key)"
          :show-label="expanded"
          :active="isSelected(item.key)"
          :to="item.to || '/'"
          :label="item.label"
          :icon="item.icon"
          :custom-icon="item.customIcon"
          :has-children="!!hasChildren(item)"
          @click="
            ({ event, navigate }) => {
              selectItem(item, event, navigate);
            }
          "
        />
      </div>

      <div class="d-inline-block d-sm-none">
        <v-divider class="mt-4 mb-8" />
        <view-switcher :label="$t('New system look')" />
        <div class="mb-10 mt-9 ml-1">
          <ThemeSwitcher show-default-label />
        </div>
      </div>

      <div
        class="menu-item d-flex cursor-pointer align-center rounded-lg mt-auto"
        :class="{ active: isSelected('user-profile') }"
        @click="selectItem({ key: 'user-profile' }, $event, null)"
      >
        <div class="color--text-white">
          <user-avatar
            :user="user"
            size="44"
          />
        </div>
        <div class="d-flex flex-grow-1 label py-3 pl-2">
          <div class="flex-grow-1">
            <div class="color--text-primary font-weight-medium">{{ user.fullname }}</div>
            <small class="d-block color--text-secondary text-body-2">{{ $t("Profile") }}</small>
          </div>
          <v-icon
            class="px-2 menu-item rounded-lg"
            :title="$t('Logout')"
            @click.stop="logout"
            >mdi-power</v-icon
          >
        </div>
      </div>
    </div>
    <!-- #endregion -->

    <!-- #region submenus -->
    <menu-machine
      :show="showSubMenu['group']"
      @select="expanded = false"
      @back="backSubMenu('group')"
    />
    <menu-asset
      :show="showSubMenu['aeyevision']"
      @select="expanded = false"
      @back="backSubMenu('aeyevision')"
    />
    <div
      v-for="item in subItems"
      v-show="showSubMenu[item.key]"
      :key="item.key"
      class="submenu pa-5"
    >
      <router-link
        v-for="subItem in item.subItems"
        :key="subItem.key"
        v-slot="{ href, navigate }"
        :to="subItem.to"
        :custom="true"
      >
        <a
          :href="href"
          class="menu-item d-flex align-center cursor-pointer rounded-lg mb-1"
          :class="{ active: isSelected(subItem.key) }"
          @click="selectItem(subItem, $event, navigate, subItem.parent)"
        >
          <v-icon class="pa-3">{{ subItem.icon }}</v-icon>
          <div class="label d-flex flex-grow-1 py-3">
            <span class="flex-grow-1">{{ subItem.label }}</span>
          </div>
        </a>
      </router-link>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script setup>
import { computed, getCurrentInstance, ref, watch, watchEffect } from "vue";
import { useRoute } from "vue-router/composables";
import { camelCase, startCase } from "lodash";
import { hasUserRole, userRoles } from "@/utils/user";
import { useIntl } from "@/shared/useIntl";
import MenuMachine from "@/ui/Menu/MenuMachine.vue";
import MenuAsset from "@/ui/Menu/MenuAsset.vue";
import UserAvatar from "@/ui/UserAvatar";
import ViewSwitcher from "@/ui/ViewSwitcher";

import { useSettingsDisabledRoutes } from "@/common/settings/useSettingsDisabledRoutes";
import MenuLink from "@/ui/Menu/MenuItemTooltip.vue";
import VListBoxOutline from "@/ui/icons/VListBoxOutline.vue";
import VCloudUploadOutline from "@/ui/icons/VCloudUploadOutline.vue";
import VAi from "@/ui/icons/VAi.vue";
import VMedicationOutline from "@/ui/icons/VMedicationOutline.vue";
import VLighthouse from "@/ui/icons/VLighthouse.vue";
import VMonitorDashboard from "@/ui/icons/VMonitorDashboard.vue";
import { useDevelopmentEdge } from "@/shared/useDevelopmentEdge";
import ThemeSwitcher from "@/ui/ThemeSwitcher/ThemeSwitcher.vue";

const { $t } = useIntl();
const route = useRoute();

const vm = getCurrentInstance().proxy;
const user = computed(() => vm.$store.getters["session/User"]);
const logout = () => vm.$store.dispatch("session/Logout");
const { devEdge } = useDevelopmentEdge();

const props = defineProps({
  collapsed: { type: Boolean, default: false }
});

const allItems = [
  { label: $t("Machine Groups"), icon: "mdi-file-tree-outline", key: "group", hasChildren: true },
  { label: $t("Audits"), customIcon: VListBoxOutline, key: "AuditAdmin", to: "/audits/types" },
  { label: $t("IoT"), customIcon: VCloudUploadOutline, key: "iot", to: "/iot/realtime" },
  { label: $t("Analytics"), icon: "mdi-chart-box-outline", key: "analytics", to: "/analytics" },
  { label: $t("Bax AEYE"), icon: "mdi-monitor-eye", key: "aeyevision", hasChildren: true },
  { label: $t("IRIS"), customIcon: VAi, key: "iris", to: "/iris", devEdge: true },
  {
    label: $t("Application Monitoring"),
    customIcon: VMedicationOutline,
    key: "application-admin",
    devEdge: true,
    subItems: [
      {
        label: $t("Issue Tickets"),
        icon: "mdi-ticket-outline",
        key: "issue-tickets",
        to: { name: "application-issue-tickets" }
      },
      {
        label: $t("Machine State"),
        icon: "mdi-alert-outline",
        key: "machine-state",
        to: { name: "machine-state" }
      }
    ]
  },
  {
    label: $t("Lighthouse"),
    customIcon: VLighthouse,
    key: "lighthouse",
    to: "/lighthouse",
    devEdge: true
  },
  {
    label: $t("Raw Data"),
    devEdge: true,
    icon: "mdi-database-search",
    key: "fast-response-board",
    to: "/raw-data"
  },
  {
    label: $t("Forms"),
    icon: "mdi-table",
    devEdge: true,
    key: "digital-forms",
    subItems: [
      {
        label: $t("My Forms"),
        icon: "mdi-file-document-multiple-outline",
        key: "my-forms",
        to: { name: "user-progressions" }
      },
      {
        label: $t("Templates"),
        icon: "mdi-file-cabinet",
        key: "templates",
        to: { name: "templates" }
      }
    ]
  },
  {
    label: $t("Digital Twin"),
    devEdge: true,
    customIcon: VMonitorDashboard,
    key: "DigitalTwin",
    to: "/digital-twin"
  },
  {
    label: $t("Upload CSV"),
    icon: "mdi-file-upload-outline",
    key: "upload-csv",
    to: { name: "config" }
  },
  { label: $t("Settings"), icon: "mdi-cog-outline", key: "settings", to: "/settings" }
];

const { disabledRoutes } = useSettingsDisabledRoutes(user.value.id);

const items = computed(() =>
  allItems.filter((item) => {
    const roleKey = startCase(camelCase(item.key)).replace(/ /g, "");
    const hasAccess = userRoles[roleKey] ? hasUserRole(user.value, userRoles[roleKey]) : true;
    const isEnabled = !disabledRoutes.value?.some((route) => route.routeName === item.key);

    if (item.devEdge && !devEdge.value) {
      return null;
    }

    return hasAccess && isEnabled;
  })
);

// #region Menu behavior
const selectedItem = ref(null);
function selectItem(item, event, navigate, parent) {
  if (item.to) {
    if (!parent && !expanded.value && hasChildren(item)) {
      event.preventDefault();
    } else {
      navigate(event);
    }

    return;
  } else if (event) {
    event.preventDefault();
  }

  if (hasChildren(item)) {
    flipSubMenus([item.key, parent]);
  }

  expanded.value = !expanded.value;
  selectedItem.value = parent || item.key;
}

const expanded = ref(false);
const subItems = ref([]);
const showSubMenu = ref({});

watchEffect(() => {
  const newSubMenu = items.value
    .filter((item) => !item.to)
    .reduce((acc, item) => {
      acc[item.key] = false;
      return acc;
    }, {});
  showSubMenu.value = newSubMenu;
});

watch(
  [items, selectedItem, showSubMenu],
  () => {
    subItems.value = items.value
      .filter((item) => item.subItems && showSubMenu.value[item.key])
      .map((item) => {
        item.subItems.forEach((subItem) => {
          subItem.parent = item.key;
        });
        return item;
      });
  },
  { immediate: true, deep: true }
);

function collapseAll() {
  expanded.value = false;
  flipSubMenus();
}

function flipSubMenus(showOnly = []) {
  if (Array.isArray(showOnly)) {
    Object.keys(showSubMenu.value)
      .filter((key) => !showOnly.includes(key))
      .forEach((key) => {
        showSubMenu.value[key] = false;
      });

    showOnly.forEach((key) => {
      if (Object.hasOwn(showSubMenu.value, key)) {
        showSubMenu.value[key] = true;
      }
    });
  }
}

function backSubMenu(key) {
  showSubMenu.value[key] = false;
  expanded.value = true;
}

function mobileToggle() {
  expanded.value = !expanded.value;
  flipSubMenus();
}

// #endregion

// #region state computed properties and actions
const isSelected = computed(() => (key) => selectedItem.value === key);
const subMenuExpanded = computed(() => Object.values(showSubMenu.value).some((val) => val));
const isAllCollapsed = computed(() => !expanded.value && !subMenuExpanded.value);
const hasChildren = (item) => item.hasChildren || item.subItems;

watch(
  [route, () => props.collapsed],
  () => {
    selectedItem.value = items.value.find((item) => route.path.startsWith(`/${item.key}`))?.key;
    collapseAll();
  },
  { immediate: true }
);

//#endregion
</script>

<style lang="scss" scoped>
@import "@/scss/variables";

.menu {
  position: fixed;
  background: var(--bg-main);
  height: 100dvh;
  z-index: 100000;
  left: 0;
  top: 0;

  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    height: calc(100% - #{$bannerHeight});
    margin-top: $bannerHeight;
  }
}

.main-menu-items {
  ::v-deep(i),
  ::v-deep(svg) {
    margin-left: rem(-2px);
  }
}

.main-menu {
  height: 100%;
  width: rem(70px);
  transition: width 0.1s ease-in-out;
  overflow-x: hidden;
  border-width: 1px 1px 0 0;
  border-style: solid;
  border-color: var(--border-secondary);

  .label {
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
    display: none !important;
  }

  transform: translateX(-100%);
  margin-left: rem(-68px);

  @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
    transform: translateX(0);
    margin-left: 0;
  }

  &.expanded {
    width: rem(290px);
    transform: translateX(0);
    margin-left: 0;

    .label {
      opacity: 1;
      display: flex !important;
    }
  }
}

:deep(.menu-toggle) {
  width: 100%;
}
:deep(.menu-toggle-item) {
  flex: 1;
}

.v-avatar {
  margin: 0 rem(3px);
}

:deep(.submenu) {
  position: relative;
  top: 0;
  border: 1px solid var(--border-secondary);
  background: var(--bg-card);
  height: 100%;
  width: rem(290px);
  overflow-y: auto;

  + .submenu {
    position: absolute;
    @media screen and (min-width: map-get($grid-breakpoints, "sm")) {
      position: relative;
    }
  }

  a {
    text-decoration: none;
    color: var(--text-secondary);

    &:hover,
    &:focus {
      color: var(--text-primary);
    }
  }
}

.menu-sm-toggle-btn {
  position: absolute;
  top: rem(10px);
  left: 100%;
  border-radius: 0;
  width: rem(52px) !important;
  height: rem(52px) !important;
  z-index: 100;
  background: var(--bg-main);

  &.expanded {
    color: var(--text-accent);
    border: 1px solid var(--border-secondary);
    margin-left: 0;
    border-left: 0;
    top: 0;
  }
}

::v-deep(.menu-nested) {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    position: absolute !important;
    left: 2px;
    top: 0;
    bottom: 0;
  }
}
</style>
