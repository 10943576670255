<template>
  <ModalDialog
    content-class="subscribe-dialog"
    :value="open"
    persistent
    full-screen-mobile
    max-width="576px"
    height="416px"
    @close="closeDialog"
  >
    <template #title> {{ $t("Edit ticket") }} </template>

    <template #body>
      <div v-if="isLoading">{{ $t("Loading...") }}</div>
      <div v-else>
        <p class="d-block text-body-1 color--text-secondary mb-2">
          {{ $t("Category") }}
        </p>
        <div
          v-for="(selectedItem, index) in selected"
          :key="`${selectedItem?.id + selectedItem?.treeId + selectedItem?.depth}`"
          class="mb-4"
        >
          <Fragment v-if="getItemsByDeep(index + 1, selectedItem).length !== 0">
            <p
              v-if="index === 1"
              class="d-block text-body-1 color--text-secondary mb-2"
            >
              {{ $t("Issue") }}
            </p>
            <Dropdown
              :ref="`${'tagDropdown' + index}`"
              v-model="selected[index + 1]"
              :items="getItemsByDeep(index + 1, selectedItem)"
              :multiple="false"
              item-text="name"
              :item-value="(item) => item"
              v-bind="$attrs"
              v-on="$listeners"
              @change="(item) => updateSelection(item, index)"
            />
          </Fragment>
        </div>
      </div>

      <div>
        <p class="d-block text-body-1 color--text-secondary mb-2">{{ $t("Details") }}</p>
        <v-textarea
          v-model="details"
          class="custom"
          filled
          hide-details
          clearable
        />
      </div>
    </template>

    <template #actions>
      <Btn
        class="btn"
        elevation="0"
        primary
        @click="closeDialog"
      >
        {{ $t("Cancel") }}
      </Btn>
      <Btn
        class="btn"
        elevation="0"
        variant="primary"
        :disabled="isSubmit || isDisabled"
        :loading="isSubmit"
        @click="saveTicket"
      >
        {{ $t("Save") }}
      </Btn>
    </template>
  </ModalDialog>
</template>

<script setup>
import { Fragment } from "vue-fragment";
import { computed, getCurrentInstance, nextTick, ref, toRefs, watch } from "vue";

import Btn from "@/ui/Btn";
import Dropdown from "@/ui/Dropdown";
import ModalDialog from "@/ui/ModalDialog";
import { useTicketDetailsEdit } from "../useTicketDetailsEdit";
import { useHelpCenterHelpTags } from "@/features/help-center/hooks/useHelpCenterHelpTags.js";

const props = defineProps({
  ticket: { type: Object, default: () => ({}) },
  open: { type: Boolean, default: false, required: true }
});

const emit = defineEmits(["close, success"]);

const { open, ticket } = toRefs(props);
const { mutate } = useTicketDetailsEdit();
const { data, isLoading } = useHelpCenterHelpTags(open);
const vm = getCurrentInstance();

const details = ref(ticket.value.details);
const isSubmit = ref(false);
const errors = ref(null);
const ids = computed(() => ticket.value.issues.map((item) => item.id));
const mappedTags = computed(() => data.value?.filter((tag) => ids.value?.includes(tag?.id)));
const selected = ref([null]);
const isDisabled = ref(false);

watch(
  selected,
  () => {
    nextTick(() => {
      isDisabled.value = Object.values(vm.proxy.$refs)
        .filter((item) => item[0]?.$el)
        .some((item) => !item[0]?.value);
    });
  },
  {
    deep: true
  }
);

watch(
  mappedTags,
  (val) => {
    if (!val) {
      return false;
    }
    selected.value.push(...val);
  },
  {
    immediate: true
  }
);

const getItemsByDeep = (depth, selectedItem) => {
  if (selectedItem && Number(selectedItem.rgt) - Number(selectedItem.lft) === 1) {
    return [];
  }
  return data.value?.filter(
    (item) => item.depth == depth && (!selectedItem?.treeId || item.treeId == selectedItem.treeId)
  );
};

const updateSelection = (item, index) => {
  let selectedTags = [];
  isDisabled.value = false;

  if (!item) {
    selectedTags = selected.value.slice(0, index + 1);
  } else {
    selectedTags = selected.value.slice(0, item.depth);
    selectedTags.push(item);
  }

  selected.value = selectedTags;
};

const closeDialog = () => {
  emit("close");
};

const saveTicket = () => {
  errors.value = null;
  const selectedItems = selected.value.slice(1);
  const variables = {
    globalId: ticket.value.id,
    helpData: {
      details: details.value,
      tagIds: selectedItems.map((item) => item.id),
      tagNames: selectedItems.map((item) => item.name)
    }
  };

  isSubmit.value = true;
  try {
    mutate(variables, {
      onSuccess: () => {
        isSubmit.value = false;
        emit("close");
      },
      onError: (error) => {
        console.error(error);
        isSubmit.value = false;
      }
    });
  } catch (error) {
    isSubmit.value = false;
  }
};

defineExpose({
  isLoading,
  isSubmit,
  isDisabled,
  selected,
  details,
  errors,
  getItemsByDeep,
  updateSelection,
  closeDialog,
  saveTicket
});
</script>

<style scoped lang="scss">
@import "@/scss/input";

.btn {
  min-width: 168px !important;

  @media #{map-get($display-breakpoints, 'xs-only')} {
    --padding: 6px;

    min-width: 0 !important;
    width: calc(50% - var(--padding));
    flex-grow: 1;

    &:first-of-type {
      margin-right: var(--padding) !important;
    }

    &:last-of-type {
      margin-left: var(--padding) !important;
    }
  }
}
</style>

<style lang="scss">
.subscribe-dialog {
  .body {
    padding-top: 16px !important;
    padding-bottom: 4px !important;
  }
  .footer {
    padding-top: 8px !important;
    padding-bottom: 12px !important;
  }
}
</style>
