import { debounce } from "lodash";
import { computed, ref, onMounted } from "vue";
import { MOBILE_WIDTH, TABLET_WIDTH } from "@/shared/constants";

export const useDeviceResolution = () => {
  const windowWidth = ref(window.innerWidth);

  const isMobile = computed(() => windowWidth.value <= MOBILE_WIDTH);
  /* TODO: should be created normal breakpoints */

  const isTablet = computed(() => windowWidth.value <= TABLET_WIDTH);

  const isSm = ref(false);
  const isMd = ref(false);
  const isLg = ref(false);
  const isXl = ref(false);

  const sm = 744;
  const md = 960;
  const lg = 1280 - 16;

  const updateSizes = () => {
    isSm.value = window.matchMedia(`(max-width: ${sm - 1}px)`).matches;
    isMd.value = window.matchMedia(`(min-width: ${sm + 1}px) and (max-width: ${md}px)`).matches;
    isLg.value = window.matchMedia(`(min-width: ${md + 1}px) and (max-width: ${lg}px)`).matches;
    isXl.value = window.matchMedia(`(min-width: ${lg + 1}px)`).matches;
  };

  const updateSizesDebounced = debounce(updateSizes, 400);

  updateSizes();

  onMounted(() => {
    window.addEventListener("resize", updateSizesDebounced);
  });

  return {
    isMobile,
    isTablet,
    isSm,
    isMd,
    isLg,
    isXl
  };
};
