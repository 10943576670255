import { onUnmounted, watch, onMounted } from "vue";
import ReconnectingWebSocket from "reconnecting-websocket";
import { env } from "@/shared/env";

export const useTicketWebsocket = (ticket_id, callback) => {
  let socket;

  const initSocket = () => {
    if (ticket_id.value) {
      let ws_scheme = window.location.protocol === "https:" ? "wss" : "ws";
      socket = new ReconnectingWebSocket(
        `${ws_scheme}://${env.wsBaseUrl}/socket/ticket/${ticket_id.value.id}/`,
        [],
        {
          maxRetries: 3
        }
      );

      socket.addEventListener("message", (event) => {
        const parsed = JSON.parse(event.data);
        const message = parsed?.message;
        const type = parsed?.type;
        callback(message, type);
      });
    }
  };

  onMounted(() => {
    initSocket();
  });

  watch(
    ticket_id,
    () => {
      if (socket) {
        socket.close();
      }
      initSocket();
    },
    {
      flush: "post"
    }
  );

  onUnmounted(() => {
    if (socket) {
      socket.close();
    }
  });
};
