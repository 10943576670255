<template>
  <div class="level2-table card">
    <v-data-table
      height="calc(100% - 4rem)"
      item-key="id"
      :items="level2Table.data"
      :headers="level2Table.headers"
      :sortBy.sync="level2Table.sortBy"
      :sortDesc.sync="level2Table.sortDesc"
      :page.sync="level2Table.page.current"
      :itemsPerPage.sync="level2Table.page.itemsPerPage"
      :serverItemsLength="level2Table.total"
      :loading="level2Table.loadingData"
      :footer-props="{ 'items-per-page-options': [10, 20, 50, 100] }"
      :loading-text="$t('Loading Machine Alarms and Events... Please wait')"
      fixed-header
    >
      <template v-slot:[`item.eventType`]="{ item }">
        <div
          class="eventType"
          :class="{ alarm: item.eventType == 'ALARM', event: item.eventType == 'EVENT' }"
        >
          <p>{{ item.eventType }}</p>
        </div>
      </template>
      <template v-slot:footer.prepend>
        <v-btn
          class="csv-download-button"
          color="primary"
          medium
          :disabled="downloadingCsv"
          :loading="downloadingCsv"
          @click="downloadCsv"
        >
          <v-icon size="16">mdi-file-download</v-icon> CSV
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";

import { TableData } from "../../../datatypes/table";
import createCSVFromRows from "../../../utils/createCSVFromRows";
import { seconds } from "../../../utils/filters";

export default {
  name: "Level2Table",
  props: ["filterController"],
  data() {
    return {
      csvRows: [],
      downloadingCsv: false,
      level2Codes: [],
      level2Table: new TableData([
        { text: this.$t("Machine"), value: "machineName", sortable: false },
        { text: this.$t("Part"), value: "partNumber", sortable: true },
        { text: this.$t("Factory Order"), value: "factoryOrder", sortable: true },
        { text: this.$t("Fault Code"), value: "statusCode", sortable: true },
        { text: this.$t("Description"), value: "eventDescription", sortable: false },
        { text: this.$t("Event Type"), value: "eventType", sortable: false },
        { text: this.$t("Start Time"), value: "startTime", sortable: true },
        { text: this.$t("End Time"), value: "endTime", sortable: true },
        { text: this.$t("Duration"), value: "eventDuration", sortable: true }
      ])
    };
  },
  async created() {
    this.level2Table.page.itemsPerPage = 20;
    await this.loadLevel2Info();
    this.loadTableData();
  },
  computed: {
    ...mapGetters({
      getMachineFromPk: "dbCache/machineFromPk"
    })
  },
  methods: {
    async loadLevel2Info() {
      const query = `query{
        level2Codes{
          machine{
            pk
          }
          part{
            pk
            name
          }
          code
          description
          eventType
        }
      }`;
      return this.$http
        .post("graphql/", { query })
        .then((res) => {
          this.level2Codes = res.data.data.level2Codes;
        })
        .catch((e) => {
          console.error(e);
        });
    },
    loadTableData(csv = false, lastNode = null) {
      this.level2Table.loadingData = true;
      const query = `query(
        $filters: GrapheneElasticFilterLevel2EventNodeConnectionBackendFilter!,
        $ordering: GrapheneElasticOrderingLevel2EventNodeConnectionBackendFilter!,
        $first: Int,
        $last: Int,
        $after: String,
        $before: String){
        level2Events(
          filter: $filters,
          ordering: $ordering,
          first: $first,
          last: $last,
          after: $after,
          before: $before,
          facets: [start_time]) {
          facets
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges{
            node{
              machineId,
              partNumber,
              factoryOrder,
              startTime,
              endTime,
              eventDuration,
              eventDescription,
              eventType,
              statusCode
            }
          }
        }
      }`;

      const page = csv ? { itemsPerPage: 10000 } : this.level2Table.page;

      let ordering = { startTime: "DESC" };
      if (this.level2Table.sortBy.length > 0) {
        ordering = {};
        this.level2Table.sortBy.forEach((sortBy, index) => {
          ordering[sortBy] = this.level2Table.sortDesc[index] ? "DESC" : "ASC";
        }, this);
      }
      const variables = {
        filters: {
          startTime: {
            range: {
              lower: { datetime: this.filterController.initialFilters.date_range.from_date },
              upper: { datetime: this.filterController.initialFilters.date_range.to_date }
            }
          }
        },
        ordering: ordering,
        before: false,
        after: false
      };
      const codeEventType = this.filterController.initialFilters.codeEventType;
      if (codeEventType) {
        variables["filters"]["eventType"] = {
          terms: [codeEventType, codeEventType.toLowerCase()]
        };
      }
      if (page.load) {
        Object.assign(variables, page.load);
      } else {
        variables["first"] = page.itemsPerPage;
      }
      if (this.filterController.hasFilter("machines")) {
        variables["filters"]["machineId"] = {
          terms: this.filterController.getFilterPks("machines")
        };
      }
      if (this.filterController.hasFilter("parts")) {
        variables["filters"]["partNumber"] = {
          terms: this.filterController.initialFilters.parts
        };
      }
      if (csv && lastNode) {
        variables.filters.startTime.range.upper.datetime = lastNode.startTime;
      }

      const res = this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          if (csv) {
            const hasNextPage = res.data.data.level2Events.pageInfo.hasNextPage;
            const edges = res.data.data.level2Events.edges;
            this.formatDataForCSV(edges, hasNextPage);
            if (hasNextPage) {
              this.loadTableData(csv, edges[edges.length - 1].node);
            }
          } else {
            this.level2Table.data = this.formatDataForTable(res.data.data.level2Events.edges);
            this.level2Table.total = res.data.data.level2Events.facets.start_time.doc_count;
            this.level2Table.page.next = res.data.data.level2Events.pageInfo.endCursor;
            this.level2Table.page.prev = res.data.data.level2Events.pageInfo.startCursor;
            this.level2Table.page.load = false;
            this.level2Table.loadingData = false;
          }
        })
        .catch((e) => {
          console.error(e);
          this.downloadingCsv = false;
          this.level2Table.loadingData = false;
        });
      return res;
    },
    formatDataForTable(data) {
      let tableData = [];
      data.forEach((doc) => {
        // let level2Code = this.getLevel2Code(doc)
        tableData.push({
          machineName: this.getMachineFromPk(doc.node.machineId).name,
          partNumber: doc.node.partNumber,
          factoryOrder: doc.node.factoryOrder,
          statusCode: doc.node.statusCode,
          eventDescription: doc.node.eventDescription
            ? doc.node.eventDescription
            : this.$t("Unknown"),
          eventType: doc.node.eventType ? doc.node.eventType : this.$t("Unknown"),
          startTime: moment(doc.node.startTime).format(this.$datetime.default),
          endTime: moment(doc.node.endTime).format(this.$datetime.default),
          eventDuration: seconds(doc.node.eventDuration, true)
        });
      });
      return tableData;
    },
    getLevel2Code(doc) {
      let codeMatch = {
        description: this.$t("Unknown"),
        eventType: this.$t("Unknown")
      };
      this.level2Codes.forEach((code) => {
        if (doc.node.statusCode == code.code && doc.node.machineId == code.machine.pk) {
          codeMatch = code;
        }
      });
      return codeMatch;
    },
    async downloadCsv() {
      this.downloadingCsv = true;
      this.csvRows = [];
      this.loadTableData(true);
    },
    formatDataForCSV(data, hasNextPage) {
      const headers = this.level2Table.headers.map((item) => item.text);
      headers.push("Duration (orig)"); // only for csv format
      this.csvRows.push(headers);
      data.forEach((doc) => {
        this.csvRows.push([
          this.getMachineFromPk(doc.node.machineId).name,
          doc.node.partNumber,
          doc.node.factoryOrder,
          doc.node.statusCode,
          doc.node.eventDescription,
          doc.node.eventType,
          moment(doc.node.startTime).format(this.$datetime.default),
          moment(doc.node.endTime).format(this.$datetime.default),
          seconds(doc.node.eventDuration, true),
          doc.node.eventDuration
        ]);
      });
      if (!hasNextPage) {
        createCSVFromRows(this.csvRows, `machine_alarms_and_events_${new Date().toISOString()}`);
        this.downloadingCsv = false;
        this.level2Table.loadingData = false;
      }
    }
  },
  watch: {
    "filterController.reload": function () {
      this.loadTableData();
    },
    "level2Table.sortBy": function () {
      this.loadTableData();
    },
    "level2Table.sortDesc": function () {
      this.loadTableData();
    },
    "level2Table.page.itemsPerPage": function () {
      this.loadTableData();
    },
    "level2Table.page.current": function (newPage, oldPage) {
      if (newPage > oldPage) {
        this.level2Table.page.load = {
          after: this.level2Table.page.next,
          before: false,
          first: this.level2Table.page.itemsPerPage
        };
        this.loadTableData();
      } else if (newPage < oldPage) {
        this.level2Table.page.load = {
          after: false,
          before: this.level2Table.page.prev,
          last: this.level2Table.page.itemsPerPage
        };
        this.loadTableData();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.level2-table {
  margin: 10px;
  height: calc(100% - 10px);
  overflow: hidden;
  flex-grow: 1;
  th {
    color: $blue;
    span {
      color: $blue;
    }
  }
  .eventType {
    color: $orange;
    p {
      font-weight: 600;
    }
  }
  .alarm {
    color: $red;
  }
  .event {
    color: $yellow;
  }
}
</style>
