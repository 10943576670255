<template>
  <v-dialog
    :content-class="`custom-dialog ${contentClass}`"
    :value="value"
    scrollable
    :max-width="maxWidth"
    v-bind="$attrs"
    @input="
      (val) => {
        $emit('input', val);
        if (!val) {
          $emit('close');
        }
      }
    "
    v-on="$listeners"
  >
    <v-card
      :max-height="height"
      :min-height="height"
      :class="['dialog-wrapper', fullScreenMobile && 'fullscreen']"
    >
      <DialogClose
        class="dialog-close"
        @click.native="closeDialog"
      />
      <v-card-title class="px-6 py-4 text-h6 dialog-title">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text class="pt-4 body-1 body">
        <slot name="body"></slot>
      </v-card-text>
      <v-card-actions class="flex justify-sm-end justify-center px-5 py-4 px-sm-6 footer">
        <slot name="actions">
          <Btn
            class="action-button"
            large
            @click.native="$emit('input', false)"
          >
            {{ $t("Cancel") }}
          </Btn>
          <Btn
            variant="primary"
            large
            :disabled="submitDisabled"
            :loading="submitLoading"
            class="action-button ml-3"
            @click="submitDialog"
          >
            <slot name="submitActionName">{{ $t("Submit") }}</slot>
          </Btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogClose from "@/ui/DialogClose/DialogClose.vue";
import Btn from "@/ui/Btn/Btn.vue";

export default {
  name: "ModalDialog",
  components: { DialogClose, Btn },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    submitLoading: {
      type: Boolean,
      default: false
    },
    fullScreenMobile: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ""
    },
    maxWidth: { type: String, default: "768px" },
    height: { type: String, default: "500px" }
  },
  emits: ["submit-action", "close"],
  setup(_, context) {
    const submitDialog = () => {
      context.emit("submit-action");
      context.emit("input", false);
    };

    const closeDialog = () => {
      context.emit("input", false);
      context.emit("close");
    };

    return {
      submitDialog,
      closeDialog
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/theme_tokens";
@import "~@/scss/dropdown.scss";
@import "~@/scss/_variables";

::v-deep .custom-dialog.v-dialog {
  margin: 20px !important;
}

.dialog-wrapper ::v-deep .v-label {
  color: var(--text-secondary) !important;
}

.dialog-wrapper {
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-secondary);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.dialog-close {
  top: 18px;
  right: 16px;

  ::v-deep {
    .close {
      @media #{map-get($display-breakpoints, 'xs-only')} {
        top: -4px;
      }
    }
  }
}

.dialog-title {
  border-bottom: 1px solid var(--border-secondary);
}

.action-button {
  min-width: 168px !important;
}

.body {
  overflow: auto;

  ::v-deep .reason button {
    border: none !important;
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .fullscreen {
    &.dialog-wrapper {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: var(--bg-card);
      border-radius: 0 !important;
      height: auto !important;
      max-height: unset !important;
      min-height: unset !important;
    }

    &.dialog-wrapper.theme--dark {
      background: $old-violet-100 !important;
    }

    .dialog-title {
      text-align: center;
      justify-content: center;
      border: none;
      width: 100%;
    }

    .dialog-close {
      top: 24px;
      right: 20px;
    }

    .v-card__text.body {
      max-height: 100% !important;
      height: 100% !important;
      padding: 20px;
    }
  }
}
</style>
