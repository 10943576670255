<template>
  <div class="comment-form rounded-lg">
    <v-textarea
      filled
      outlined
      hide-details
      rows="1"
      class="text-form"
      v-bind="$props"
      v-on="$listeners"
    >
      <template #append>
        <slot name="append"></slot>
      </template>

      <template #prepend>
        <slot name="prepend"></slot>
      </template>
    </v-textarea>

    <slot name="bottom"></slot>
  </div>
</template>

<script>
export default {
  name: "TextArea",

  props: {
    /* COPY PASTE FROM VUETIFY */
    /* eslint-disable vue/require-default-prop */
    appendOuterIcon: String,
    autofocus: Boolean,
    value: String,
    clearable: Boolean,
    clearIcon: {
      type: String,
      default: "$clear"
    },
    counter: [Boolean, Number, String],
    counterValue: Function,
    filled: Boolean,
    flat: Boolean,
    fullWidth: Boolean,
    label: String,
    outlined: Boolean,
    placeholder: String,
    prefix: String,
    prependInnerIcon: String,
    persistentPlaceholder: Boolean,
    reverse: Boolean,
    rounded: Boolean,
    shaped: Boolean,
    singleLine: Boolean,
    solo: Boolean,
    soloInverted: Boolean,
    suffix: String,
    type: {
      type: String,
      default: "text"
    },
    autoGrow: Boolean,
    noResize: Boolean,
    rowHeight: {
      type: [Number, String],
      default: 24,
      validator: (v) => !isNaN(parseFloat(v))
    },
    rows: {
      type: [Number, String],
      default: 5,
      validator: (v) => !isNaN(parseInt(v, 10))
    }
    /* eslint-enable vue/require-default-prop */
  }
};
</script>

<style scoped lang="scss">
@import "@/scss/variables";

.comment-form {
  border: 1px solid var(--border-primary);
  background-color: var(--bg-card-white);
  position: relative;

  &:hover {
    color: var(--text-primary);
    border-color: var(--border-input);
  }
}

.text-form {
  border: none;

  ::v-deep {
    .v-input__slot {
      background: none !important;
      padding: 8px 0 0 !important;
      min-height: 42px;
      box-shadow: unset !important;
    }

    .v-input__append-inner {
      margin-top: 4px !important;
    }

    fieldset {
      border: none !important;
      top: 0;
    }

    textarea {
      max-height: 145px;
      min-height: 42px;
      line-height: 24px;
      margin-top: 0 !important;
      padding: 8px 44px 8px 16px !important;
      caret-color: var(--text-primary);
      color: var(--text-primary);
      overflow: auto;

      @media #{map-get($display-breakpoints, 'xs-only')} {
        min-height: 52px;
      }
    }
  }
}
</style>
