import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { machineApi } from "../machineApi";
import { QUERY_LONG_LIVE_STALE_TIME } from "@/shared/constants";

export const useMachineQuery = (id) => {
  const query = useQuery({
    queryKey: ["machine", id],
    queryFn: () => machineApi.getMachine(id.value),
    keepPreviousData: true,
    staleTime: QUERY_LONG_LIVE_STALE_TIME
  });

  const machine = computed(() => query.data.value && query.data.value.data.machines[0]);

  return { ...query, machine };
};
