import { computed } from "vue";

import TicketDetailsHistoryActive from "./components/TicketDetailsHistoryActive.vue";
import TicketDetailsHistoryClosed from "./components/TicketDetailsHistoryClosed.vue";
import { useIntl } from "@/shared/useIntl";
import { TICKET_DETAILS_STATUS, TICKET_ACTIVITY_TYPE } from "./constants";

export const useTicketWorkFlowConfig = () => {
  const { $t } = useIntl();

  const getConfig = (ticket) => {
    const config = computed(() => ({
      [TICKET_DETAILS_STATUS.WAITING]: {
        action: $t("created a ticket"),
        date: ticket.value.ticketedDate,
        name: ticket.value.ticketedByName,
        type: TICKET_ACTIVITY_TYPE.HISTORY,
        color: "var(--bg-card)",
        titleColor: "color--text-yellow",
        icon: "mdi-update",
        iconColor: "var(--text-yellow)",
        title: $t("Waiting")
      },

      [TICKET_DETAILS_STATUS.ACTIVE]: {
        action: $t("assigned a ticket"),
        date: ticket.value.firstResponseDate,
        DemoComponent: TicketDetailsHistoryActive,
        name: ticket.value.firstResponseByName,
        type: TICKET_ACTIVITY_TYPE.HISTORY,
        color: "var(--bg-card)",
        titleColor: "color--text-accent",
        icon: "mdi-briefcase-outline",
        iconColor: "var(--text-accent)",
        title: $t("Active")
      },

      [TICKET_DETAILS_STATUS.RESOLVED]: {
        action: $t("resolved a ticket"),
        date: ticket.value.closedDate,
        DemoComponent: TicketDetailsHistoryClosed,
        name: ticket.value.closedByName,
        type: TICKET_ACTIVITY_TYPE.HISTORY,
        color: "var(--bg-card)",
        titleColor: "color--text-green",
        icon: "mdi-check-circle-outline",
        iconColor: "var(--text-green)",
        title: $t("Resolved")
      },

      [TICKET_DETAILS_STATUS.CREATED_BY_MISTAKE]: {
        action: $t("cancelled a ticket"),
        date: ticket.value.closedDate,
        DemoComponent: TicketDetailsHistoryClosed,
        name: ticket.value.closedByName,
        type: TICKET_ACTIVITY_TYPE.HISTORY,
        color: "var(--bg-card)",
        titleColor: "color--text-secondary",
        icon: "mdi-cancel",
        iconColor: "var(--text-secondary)",
        title: $t("Cancelled")
      },

      [TICKET_DETAILS_STATUS.CAN_NOT_BE_RESOLVED]: {
        action: $t("cancelled a ticket"),
        date: ticket.value.closedDate,
        DemoComponent: TicketDetailsHistoryClosed,
        name: ticket.value.closedByName,
        type: TICKET_ACTIVITY_TYPE.HISTORY,
        color: "var(--bg-card)",
        titleColor: "color--text-red",
        icon: "mdi-cancel",
        iconColor: "var(--text-red)",
        title: $t("Can’t be resolved")
      }
    }));

    return config;
  };

  const getHistoryItems = (ticket) => {
    let config = computed(() => getConfig(ticket));

    const historyItems = computed(() => {
      const res = [];

      if (ticket.value.closedDate) {
        res.push(config.value.value[ticket.value.solutionType]);
      }

      if (ticket.value.firstResponseDate) {
        res.push(config.value.value[TICKET_DETAILS_STATUS.ACTIVE]);
      }

      if (ticket.value.ticketedDate) {
        res.push(config.value.value[TICKET_DETAILS_STATUS.WAITING]);
      }

      return res;
    });

    return historyItems;
  };

  return {
    getConfig,
    getHistoryItems
  };
};
