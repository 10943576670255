<template>
  <div class="level2-filters card">
    <div class="views card">
      <v-radio-group v-model="filterController.view">
        <v-radio
          :label="this.$t('Data Table')"
          value="data_table"
        />
        <v-radio
          :label="this.$t('Timeline')"
          value="timeline"
        />
        <v-radio
          :label="this.$t('Analytics')"
          value="analytics"
        />
      </v-radio-group>
    </div>
    <div class="filter-sections">
      <div class="filter-section card">
        <date-range
          :filter-controller="filterController"
          :include-scale="filterController.view != 'timeline'"
        >
        </date-range>
      </div>
      <div class="filter-section card dropdown-section">
        <v-autocomplete
          v-if="filterController.view == 'timeline'"
          v-model="filterController.initialFilters.machine"
          :items="machines"
          item-text="name"
          :label="$t('Machine')"
          @change="filterController.triggerReload"
          return-object
          :menu-props="{ closeOnClick: true }"
          hide-details
        >
          <template v-slot:item="{ item }">
            {{ item.name }}
          </template>
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
        <v-autocomplete
          v-if="filterController.view == 'timeline' && machineCodes.length > 0"
          v-model="filterController.initialFilters.code"
          :items="machineCodes"
          item-text="code"
          :label="$t('Machine code')"
          :filter="filterMachineCodes"
          @change="filterController.triggerReload"
          return-object
          :menu-props="{ closeOnClick: true }"
          hide-details
          clearable
        >
          <template v-slot:item="{ item }"> {{ item.code }} - {{ item.description }} </template>
          <template v-slot:selection="{ item }">
            {{ item.code }} - {{ item.description }}
          </template>
        </v-autocomplete>
        <machine-group-selector
          v-if="filterController.view != 'timeline'"
          :filter-controller="filterController"
          :set-machine-groups="setMachineGroups"
          hide-machine-group-selector="true"
        >
        </machine-group-selector>
        <v-autocomplete
          v-model="filterController.initialFilters.codeEventType"
          :items="['ALARM', 'EVENT', 'UNKNOWN']"
          item-text="code-event-type"
          :label="$t('Event Type')"
          @change="filterController.triggerReload"
          return-object
          :menu-props="{ closeOnClick: true }"
          hide-details
          clearable
        >
          <template v-slot:item="{ item }">
            {{ $t(item) }}
          </template>
          <template v-slot:selection="{ item }">
            {{ $t(item) }}
          </template>
        </v-autocomplete>
        <part-selector
          v-if="filterController.view != 'timeline'"
          :filter-controller="filterController"
        >
        </part-selector>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { AnalyticsFilterController } from "@/components/analytics_filters/AnalyticsFilterController";
import DateRange from "@/components/analytics_filters/DateRange.vue";
import PartSelector from "@/components/analytics_filters/PartSelector.vue";
import MachineGroupSelector from "../production_report/MachineGroupSelector.vue";

export default {
  components: {
    DateRange,
    MachineGroupSelector,
    PartSelector
  },
  props: {
    filterController: AnalyticsFilterController
  },
  created() {},
  data: () => {
    return {};
  },
  computed: {
    ...mapGetters({
      machines: "dbCache/machines",
      machineCodes: "dbCache/machineCodes"
    })
  },
  methods: {
    filterMachineCodes(item, queryText, itemText) {
      const searchText = queryText.trim().toLowerCase();
      const code = item.code.toString().toLowerCase();
      const description = item.description.toLowerCase();
      return code.indexOf(searchText) > -1 || description.indexOf(searchText) > -1;
    },
    setMachineGroups(machineGroups, machines) {
      this.filterController.setFilter("machine_groups", machineGroups);
      this.filterController.setFilter("machines", machines);
    }
  }
};
</script>

<style lang="scss">
@import "@/scss/variables";

.level2-filters {
  height: calc(100% - 10px);
  width: 310px;
  min-width: 310px;
  max-width: 310px;
  padding: 10px;
  margin: 10px;

  .filter-sections {
  }

  .dropdown-section {
    display: grid;
    gap: 10px;
  }

  .views,
  .filter-section {
    padding: 10px;
    min-height: unset;
    margin-bottom: 10px;
    .filter-label {
      color: $blue;
    }
  }
}
</style>
