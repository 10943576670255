<script>
import moment from "moment/moment";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { seconds } from "@/utils/filters";

export default {
  name: "ProductionReportDataTable",
  components: { GenericTable },
  props: ["filters"],
  data() {
    return {
      rawData: [],
      tableData: [],
      scales: [null, "hour", "day", "week", "month", "year"]
    };
  },
  computed: {
    ...mapGetters({
      machineFromPk: "dbCache/machineFromPk"
    })
  },
  mounted() {
    this.fetchProductionReportData();
  },
  methods: {
    fetchProductionReportData() {
      this.rawData = [];
      this.tableData = [];
      this.loadProductionReportData()
        .then((result) => {
          this.rawData = result;
          this.tableData = this.transformDataForTable(result);
        })
        .catch((e) => {
          console.error(e);
        });
    },
    seconds,
    percent(val) {
      // if val is a number or a string that can be converted to a number
      if (typeof val === "number" && !isNaN(Number(val))) {
        return `${(val * 100).toFixed(2)}%`;
      }
      return "0.00%";
    },
    date(val) {
      // you can format the date here as needed
      if (this.filters.scale === 1) {
        return moment(val).format("D MMM hhA");
      }
      if (this.filters.scale === 2) {
        return moment(val).format("D MMM");
      }
      if (this.filters.scale === 3) {
        let start = moment(val);
        let end = moment(val).add(7, "d");
        if (start.month() !== end.month()) {
          return start.format("D MMM") + " - " + end.format("D MMM");
        } else {
          return start.format("D") + " - " + end.format("D MMM");
        }
      }
      if (this.filters.scale === 4) {
        return moment(val).format("MMM YYYY");
      }
      if (this.filters.scale === 5) {
        return moment(val).format("YYYY");
      }
      return new Date(val).toLocaleDateString();
    },
    loadProductionReportData() {
      return new Promise((resolve, reject) => {
        if (!this.filters) {
          reject("Filters not specified");
        }
        if (!this.filters.from_date || !this.filters.to_date) {
          reject("Dates not specified");
        }
        const params = this.constructParams();
        this.$http
          .get("metrics/production_report/", { params })
          .then((result) => {
            resolve(result.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    sumProperty(data, property) {
      return data.reduce((acc, curr) => acc + curr[property], 0);
    },
    transformDataForTable(data) {
      return data.buckets.map((bucket) => {
        let machineName = this.machineFromPk(bucket.key).name;
        if (
          bucket["last_record"] &&
          bucket["last_record"].hits &&
          bucket["last_record"].hits.hits[0] &&
          bucket["last_record"].hits.hits[0]._source
        ) {
          machineName = bucket["last_record"].hits.hits[0]._source.machine_name;
        }
        const nestedData = bucket.dates.buckets.map((dateBucket) => ({
          date: dateBucket.key_as_string,
          duration: dateBucket.duration.value,
          downtime_duration: dateBucket.downtime.duration.value,
          runtime: dateBucket.runtime.duration.value,
          asset_utilization: dateBucket.runtime.duration.value / dateBucket.duration.value,
          produced: dateBucket.produced.value,
          production_target: dateBucket.production_target.value,
          performance: dateBucket.produced.value / dateBucket.production_target.value,
          scrapped: dateBucket.scrapped.value,
          scrap_threshold: dateBucket.scrap_threshold.value,
          quality:
            (dateBucket.produced.value - dateBucket.scrapped.value) / dateBucket.produced.value,
          oee: dateBucket.oee.value
        }));
        return {
          machine_name: machineName,
          total_duration: this.sumProperty(nestedData, "duration"),
          downtime_duration: this.sumProperty(nestedData, "downtime_duration"),
          runtime: this.sumProperty(nestedData, "runtime"),
          produced: this.sumProperty(nestedData, "produced"),
          production_target: this.sumProperty(nestedData, "production_target"),
          scrapped: this.sumProperty(nestedData, "scrapped"),
          scrap_threshold: this.sumProperty(nestedData, "scrap_threshold"),
          _nested: nestedData
        };
      });
    },
    downloadCsv() {
      const formatDuration = (seconds) => {
        const duration = moment.duration(seconds, "seconds");
        const hours = Math.floor(duration.asHours());
        const minutes = duration.minutes();
        const secs = duration.seconds();
        return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
      };

      const rows = [
        [
          this.$t("Machine Name"),
          this.$t("Date"),
          this.$t("Total Duration (s)"),
          this.$t("Downtime Duration (s)"),
          this.$t("Runtime"),
          this.$t("Asset Utilization"),
          this.$t("Produced"),
          this.$t("Production Target"),
          this.$t("Performance"),
          this.$t("Scrapped"),
          this.$t("Scrap Threshold"),
          this.$t("Quality"),
          this.$t("OEE")
        ],
        ...this.tableData.flatMap((t) =>
          t._nested.map((n) => [
            t.machine_name,
            n.date,
            formatDuration(n.duration),
            formatDuration(n.downtime_duration),
            formatDuration(n.runtime),
            this.percent(n.asset_utilization),
            n.produced,
            n.production_target,
            this.percent(n.performance),
            n.scrapped,
            n.scrap_threshold,
            this.percent(n.quality),
            this.percent(n.oee)
          ])
        )
      ];
      createCSVFromRows(rows, `production_report_${new Date().toISOString()}`);
    },

    constructParams() {
      const params = {};
      if (this.filters.from_date) {
        params.from_date = this.filters.from_date;
      }
      if (this.filters.to_date) {
        params.to_date = this.filters.to_date;
      }
      if (this.filters.scale) {
        params.scale = this.scales[this.filters.scale];
      }
      if (this.filters.machineData.length > 0) {
        // const machinePKs = this.filters.machineData
        //   .filter((machine) => machine.name === this.filters.machine)
        //   .map((machine) => machine.pk);
        // params.machine_id = machinePKs.join(",");
        params.machine_id = this.filters.machine;
      }

      if (this.filters.part && this.filters.parts.length > 0) {
        params.part_numbers = this.filters.part;
      }
      if (this.filters.shift && this.filters.shift.length > 0) {
        params.shift_ids = this.filters.shift;
      }
      return params;
    },
    toLocaleStringFormat(value) {
      if (typeof value === "number" || !isNaN(Number(value))) {
        return parseInt(value).toLocaleString();
      }
      return value;
    }
  },
  watch: {
    filters: function () {
      this.fetchProductionReportData();
    }
  }
};
</script>
<template>
  <div class="production-report-data-table">
    <div
      class="card"
      style="height: 100%"
    >
      <div class="data-table-card card-content">
        <GenericTable
          :columns="[
            { title: '', key: 'machine_name' },
            {
              title: $t('Total Duration'),
              key: 'total_duration',
              format: seconds,
              sortable: true
            },
            {
              title: $t('Total Downtime'),
              key: 'downtime_duration',
              format: seconds,
              sortable: true
            },
            { title: $t('Runtime'), key: 'runtime', format: seconds },
            {
              title: $t('Asset Utilization'),
              key: 'asset_utilization'
            },
            {
              title: $t('Produced'),
              key: 'produced',
              format: toLocaleStringFormat,
              alignRight: true
            },
            {
              title: $t('Production Target'),
              key: 'production_target',
              format: toLocaleStringFormat,
              alignRight: true
            },
            { title: $t('Performance'), key: 'performance' },
            {
              title: $t('Scrapped'),
              key: 'scrapped',
              format: toLocaleStringFormat,
              alignRight: true
            },
            { title: $t('Scrap Threshold'), key: 'scrap_threshold' },
            { title: $t('Quality %'), key: 'quality' },
            { title: $t('OEE'), key: 'oee', sortable: true }
          ]"
          :nested_columns="[
            { key: 'date', format: date },
            { key: 'duration', title: $t('Duration'), format: seconds },
            {
              key: 'downtime_duration',
              title: $t('Downtime Duration'),
              format: seconds
            },
            { key: 'runtime', title: $t('Runtime'), format: seconds },
            {
              key: 'asset_utilization',
              title: $t('Asset Utilization'),
              format: percent
            },
            {
              key: 'produced',
              title: $t('Produced'),
              format: toLocaleStringFormat,
              class: 'numeric-cell',
              style: { display: null }
            },
            {
              key: 'production_target',
              title: $t('Production Target'),
              format: toLocaleStringFormat
            },
            { key: 'performance', title: $t('Performance'), format: percent },
            {
              key: 'scrapped',
              title: $t('Scrapped'),
              format: toLocaleStringFormat
            },
            { key: 'scrap_threshold', title: $t('Scrap Threshold') },
            { key: 'quality', title: $t('Quality %'), format: percent },
            { key: 'oee', title: $t('OEE'), format: percent }
          ]"
          :data="tableData"
        />
        <div class="footer">
          <v-btn
            color="primary"
            small
            class="ma-2"
            @click="downloadCsv"
            style="font-size: 12px"
          >
            <v-icon style="font-size: 12px">mdi-file-download</v-icon>
            {{ $t("CSV") }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.production-report-data-table {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 10px;
  overflow: hidden;

  .data-table-card {
    flex: 1;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
}

.footer {
  text-align: left;
  flex: unset;
}
</style>
