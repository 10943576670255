<template>
  <v-app>
    <v-theme-provider :dark="isDarkTheme">
      <div class="login">
        <div class="gifs"></div>
        <div class="login-card">
          <form @submit.prevent="submit">
            <img
              v-if="!isLoading"
              class="login-image"
              :src="logo || require('../assets/logos/icon.png')"
              :alt="$t('Logo')"
            />
            <div
              v-else
              class="login-image"
            ></div>

            <h4 class="font-weight-medium color--text-primary login-title">
              {{ $t("Welcome") }}
            </h4>
            <div
              v-if="!submitting"
              class="inputs"
            >
              <div class="text-subtitle-1 font-weight-medium">
                <p class="color--text-secondary">{{ $t("Username") }}</p>
                <v-text-field
                  v-model="username"
                  class="input-text-login"
                  outlined
                  dense
                  rounded
                  :hide-details="!error_message"
                  :error-messages="error_message"
                />
              </div>
              <div class="password-input text-subtitle-1 font-weight-medium">
                <p class="color--text-secondary">{{ $t("Password") }}</p>
                <v-text-field
                  v-model="password"
                  class="input-text-login"
                  :type="showPassword ? 'text' : 'password'"
                  :append-icon="showPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
                  outlined
                  dense
                  rounded
                  :hide-details="!error_message"
                  :error-messages="error_message"
                  @click:append="togglePasswordVisibility()"
                />
              </div>
              <Btn
                class="login-btn"
                variant="primary"
                large
                type="submit"
              >
                {{ $t("LOG IN") }}
              </Btn>
              <Btn
                v-if="hasOkta"
                class="okta-login-btn"
                variant="secondary"
                large
                @click.stop.prevent="oktaLogin"
              >
                {{ $t("LOG IN WITH SSO") }}
              </Btn>
            </div>
            <div
              v-else
              class="login-spinner"
            >
              <v-progress-circular
                indeterminate
                color="primary"
                size="72"
                width="6"
              />
            </div>
          </form>
        </div>
        <img
          v-if="!isLoading"
          class="login-banner"
          :src="logo || require('../assets/logos/icon.png')"
          :alt="$t('Logo Intelycx')"
        />
      </div>
    </v-theme-provider>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Btn from "@/ui/Btn";

export default {
  name: "LoginPage",
  components: {
    Btn
  },
  data() {
    return {
      username: null,
      password: null,
      submitting: false,
      success: false,
      error: false,
      error_message: false,
      sample_data: null,
      restartAnimation: false,
      logo: false,
      showPassword: false,
      isLoading: true
    };
  },
  computed: {
    ...mapGetters({
      profile: "session/User",
      theme: "app/Theme"
    }),
    isDarkTheme() {
      return this.theme === "dark";
    },
    hasOkta() {
      return this.$auth ? true : false;
    }
  },
  created() {
    this.logout();
  },
  mounted() {
    this.$http
      .get("graphql/", {
        params: {
          query: `{settings{
              bannerLogo
            }
          }`
        }
      })
      .then((res) => {
        if (res.data.data.settings.length > 0) {
          this.logo = res.data.data.settings[0].bannerLogo;
          this.isLoading = false;
        }
      });
    if (this.$route.query.login_with_okta === "true") {
      if (this.profile) {
        this.logout().then(() => {
          this.oktaLogin();
        });
      } else {
        this.oktaLogin();
      }
    }
  },
  methods: {
    ...mapActions({
      login: "session/Login",
      logout: "session/Logout"
    }),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    async oktaLogin() {
      await this.$auth.signInWithRedirect({
        state: { next: this.$route.query.next }
      });
    },
    submit() {
      this.restartAnimation = !this.restartAnimation;
      this.submitting = true;
      this.error_message = false;
      this.success = false;
      this.login({
        username: this.username,
        password: this.password
      }).then(
        () => {
          this.$http
            .get("graphql/", {
              params: {
                query: `{settings{
                  globalConfiguration
                }}`
              }
            })
            .then(
              (res) => {
                const next = this.$route.query.next;
                if (next) {
                  this.$router.push(next); // Redireciona para a URL do parâmetro 'next'
                } else if (
                  res.data.data.settings.length > 0 &&
                  res.data.data.settings[0].globalConfiguration
                ) {
                  setTimeout(() => {
                    this.success = true;
                    setTimeout(() => {
                      this.$router.push({ name: "new-map" });
                    });
                  });
                } else {
                  setTimeout(() => {
                    this.success = true;
                    setTimeout(() => {
                      this.$router.push({ name: "map" });
                    }, 2000);
                  }, 2500);
                }
              },
              () => {
                setTimeout(() => {
                  this.submitting = false;
                  this.error_message = this.$t("Incorrect Username/Password");
                }, 5000);
              }
            );
        },
        () => {
          setTimeout(() => {
            this.submitting = false;
            this.error_message = this.$t("Incorrect Username/Password");
          }, 5000);
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: radial-gradient(
      circle at 120% 80%,
      rgba(93, 86, 36, 0.8) 0%,
      rgba(93, 86, 36, 0.3) 25%,
      rgba(93, 86, 36, 0) 50%
    ),
    radial-gradient(
      circle at -20% 80%,
      rgba(54, 73, 88, 1) 0%,
      rgba(54, 73, 88, 1) 25%,
      rgba(54, 73, 88, 0) 50%
    ),
    radial-gradient(156% 104% at 50% -4.26%, #565269 0%, #423e51 19.44%, #1f1d26 100%);
  background-size: cover;
  background-position:
    bottom right,
    bottom left,
    top center;
  background-repeat: no-repeat;
}

.theme--light .login {
  background-image: radial-gradient(
      circle at 120% 80%,
      rgba(84, 221, 255, 0.8) 0%,
      rgba(84, 221, 255, 0.4) 25%,
      rgba(84, 221, 255, 0) 50%
    ),
    radial-gradient(
      circle at -20% 80%,
      rgba(255, 234, 94, 0.8) 0%,
      rgba(255, 234, 94, 0.4) 25%,
      rgba(255, 234, 94, 0) 50%
    ),
    linear-gradient(
      180deg,
      #fcfcfc 0%,
      rgba(215, 249, 255, 0.77) 68.5%,
      rgba(161, 238, 255, 0.7) 100%
    );
}

.login-card {
  z-index: 2;
  min-height: rem(544px);
  min-width: rem(400px);
  position: relative;
  border-radius: rem(8px);
  padding: rem(52px) rem(40px);
  background: var(--login-form-gradient) no-repeat padding-box;
  backdrop-filter: blur(25px);
  border: 1px solid #ffffff20;
  box-shadow: 0 rem(16px) rem(32px) rem(-4px) #0c0c0d0d;
}

.theme--light .login-card {
  border-color: rgba(0, 0, 0, 0.05);
  box-shadow: 0 rem(16px) rem(32px) #1157790f;
  backdrop-filter: blur(30px);
}

.login-image {
  max-height: rem(36px);
  width: auto;
}

.theme--dark {
  .login-banner,
  .login-image {
    filter: grayscale(100%) brightness(2);
    mix-blend-mode: lighten;
  }
}

.login-title {
  font-size: rem(34px);
  margin-top: rem(32px);
  line-height: rem(40px);
}

.inputs {
  margin-top: rem(32px);
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: column;

  ::v-deep {
    input {
      color: var(--text-primary);
      caret-color: var(--text-secondary);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:focus {
      transition:
        background-color 0s 600000s,
        color 0s 600000s !important;
    }

    .v-icon {
      color: var(--text-secondary) !important;
    }

    .v-text-field .v-input__slot {
      height: rem(44px);
      background: var(--bg-card-white);
      border-radius: rem(8px);
      padding: 0 rem(16px);
      display: flex;
      align-items: center;
    }

    .v-text-field.v-input--is-focused fieldset,
    .v-text-field .v-input__slot:hover fieldset {
      border: 1px solid var(--border-input);
    }

    .v-text-field fieldset {
      border-radius: rem(8px);
      border: 1px solid var(--border-primary);
    }

    .v-text-field.error--text fieldset {
      border: 1px solid var(--text-red);
    }

    .v-text-field .v-input__append-inner {
      margin-top: auto !important;
      margin-bottom: auto !important;
    }

    .v-text-field .v-text-field__details {
      margin-bottom: rem(2px);
      margin-top: rem(4px);
      padding: 0;
    }

    .v-text-field .v-messages__message {
      font-size: rem(14px);
      line-height: rem(20px);
      color: var(--text-red);
      font-weight: 400;
    }
  }
}

.password-input {
  margin-top: rem(20px);
}

.input-text-login {
  margin-top: rem(8px);
}

.login-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 30%;
}

.login-btn {
  margin-top: rem(24px);
  border-radius: rem(8px) !important;
}

.okta-login-btn {
  margin-top: rem(16px);
  border-radius: rem(8px) !important;
}

.login-banner {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 3.5%;
  left: 5%;
  width: 14%;
}

.gifs {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-image: url("../assets/img/3d-robotic-arm.gif"), url("../assets/img/3d-ML-for-AI.gif");
  background-size: 66%, 94%;
  background-position:
    top 10% left 5%,
    bottom -7.5% right -3vw;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
  transform: translateZ(0);
  mix-blend-mode: overlay;
}

.theme--light .gifs {
  background-image: radial-url("../assets/img/3d-robotic-arm.gif"),
    url("../assets/img/3d-ML-for-AI.gif");
  background-blend-mode: normal, overlay;
  mix-blend-mode: multiply;
}

@media screen and (max-width: 430px) {
  .login-card {
    min-height: rem(576px);
    min-width: rem(362px);
    padding: rem(52px) rem(24px);
  }

  .login {
    ::v-deep .v-text-field .v-input__slot,
    ::v-deep .v-btn:not(.v-btn--round) {
      display: flex;
      align-items: center;
      height: rem(52px);
    }
  }
}

@media screen and (min-width: 431px) and (max-width: 1200px) {
  .gifs {
    background-size: 37.5%, 62%;
    background-position:
      top 8% left 3.5%,
      bottom -17vh right -3.6vw;
  }

  .login-banner {
    bottom: 2%;
    left: 2%;
    width: 5.5%;
  }
}

@media screen and (max-height: 430px) and (orientation: landscape) {
  .login-card {
    --card-space: 20px;
    min-height: auto;
    max-height: calc(100% - var(--card-space) * 2);
    height: calc(100% - var(--card-space) * 2);
    overflow: auto;
    margin: var(--card-space);
  }
}

@media screen and (min-width: 431px) and (max-width: 1200px) and (orientation: portrait) {
  .gifs {
    background-size: 53%, 92%;
    background-position:
      top 4% left 8%,
      bottom -11vh right -5vw;
  }
}

@media screen and (min-width: 1200px) and (max-width: 3479px) {
  .gifs {
    background-size: 32%, 54%;
    background-position:
      top 7% left 3%,
      bottom -17vh right -3.5vw;
  }

  .login-banner {
    bottom: 1.5%;
    left: 1.8%;
    width: 4.5%;
  }
}

@media screen and (min-width: 3480px) {
  .gifs {
    background-size: 32%, 54%;
    background-position:
      top 7% left 3%,
      bottom -17vh right -3.5vw;
  }

  .login-banner {
    bottom: 1.8%;
    left: 1.4%;
    width: 4.5%;
  }

  .inputs ::v-deep .v-text-field .v-input__slot {
    padding-left: 38px;
    padding-right: 38px;
  }
}
</style>
