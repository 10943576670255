<template>
  <transition name="slide-fade">
    <div class="oee-chart metric-component">
      <div class="mobile-chart-title-container">
        <h2 class="card-title">{{ $t("OEE") }}</h2>
        <span class="mobile-gauge-container">
          <v-gauge
            v-if="oee_sum"
            gaugeValueClass="gaugeValue"
            :value="oee_sum"
            :donut="true"
            :options="gaugeOpts"
          />
        </span>
      </div>
      <v-progress-linear
        indeterminate
        v-if="!loaded"
      />
      <div
        class="charts"
        v-else-if="type === 'graph'"
      >
        <div class="gauge-container">
          <v-gauge
            v-if="oee_sum"
            gaugeValueClass="gaugeValue"
            :value="oee_sum"
            :donut="true"
            :options="gaugeOpts"
          />
        </div>
        <div class="charts_container">
          <div class="chart">
            <bar-chart
              v-if="chartData"
              chartName="productionChart"
              class="productionChart"
              :chartData="chartData"
              :commas="true"
              :chartOptions="chartOptions"
              uom="%"
            />
          </div>
        </div>
      </div>
      <div
        class="charts"
        v-else-if="type === 'table'"
      >
        <generic-table
          :columns="[
            { title: '', key: 'date' },
            { title: $t('OEE'), key: 'oee', format: (value) => `${value}%`, sortable: false },
            {
              title: $t('Performance'),
              key: 'performance',
              format: (value) => `${value}%`,
              sortable: false
            },
            {
              title: $t('Quality'),
              key: 'quality',
              format: (value) => `${value}%`,
              sortable: false
            },
            {
              title: $t('Availability'),
              key: 'availability',
              format: (value) => `${value}%`,
              sortable: false
            }
          ]"
          :footer="[{ type: 'csv', csv: generate_csv }, {}, {}, {}, {}]"
          :data="table_data"
        />
      </div>
    </div>
  </transition>
</template>

<script>
import moment from "moment";
import VGauge from "vgauge";
import { mapGetters } from "vuex";

import GenericTable from "@/components/GenericTable";
import BarChart from "@/components/charts/BarChart.js";
import createCSVFromRows from "@/utils/createCSVFromRows";
import { getDateFormatter } from "@/utils/getDateFormatter";

export default {
  name: "OEEHourByHour",
  components: {
    GenericTable,
    BarChart,
    VGauge
  },
  props: ["options", "interactiveFilters", "filters", "type"],
  data() {
    return {
      loaded: false,
      chartData: false,
      gaugeOpts: {
        angle: 0.49,
        lineWidth: 0.03,
        colorStop: "white",
        strokeColor: "rgba(255,255,255,.4)",
        highDpiSupport: true
      },
      production: null,
      oee_sum: false,
      table_data: []
    };
  },
  mounted() {
    this.load_data()
      .then((response) => {
        this.production = response;
        this.oee_sum = response.total.buckets[0].oee.value * 100;
        if (this.oee_sum < 0) {
          this.oee_sum = 0;
        }
        this.format_data();
      })
      .catch((e) => {
        this.production = null;
      });
  },
  methods: {
    getDateFormatter,
    createCSVFromRows,
    moment,
    generate_csv() {
      const rows = [];
      rows.push([
        this.$t("Time"),
        this.$t("OEE"),
        this.$t("Performance"),
        this.$t("Quality"),
        this.$t("Availability")
      ]);
      for (const p of this.table_data) {
        rows.push([
          p.date,
          `${p.oee}%`,
          `${p.performance}%`,
          `${p.quality}%`,
          `${p.availability}%`
        ]);
      }
      this.createCSVFromRows(rows, `oee_${new Date().toISOString()}`);
    },
    load_data() {
      return new Promise((resolve, reject) => {
        const { scale, from_date, to_date, zone, machine, part, shift } = this.filters;
        const { machines, parts } = this.interactiveFilters;

        let hasInteractiveFilters = false;
        if ((machines && machines.length > 0) || (parts && parts.length > 0)) {
          hasInteractiveFilters = true;
        }

        let { scaleString } = this.getDateFormatter(scale);
        let params = {
          scale: scaleString,
          from_date: from_date.toISOString(),
          to_date: to_date.toISOString()
        };

        if (hasInteractiveFilters) {
          if (machines && machines.length > 0) {
            params.machine_id = machines.join(",");
          }

          if (parts && parts.length > 0) {
            params.part_numbers = parts.join(",");
          }
        } else {
          if (machine && machine.length > 0) {
            params.machine_id = machine;
          }

          if (zone && zone.length > 0) {
            params.machine_group_id = zone;
          }

          if (part) {
            params.part_numbers = part;
          }

          if (shift) {
            params.shift_ids = shift;
          }
        }

        this.loaded = false;
        this.$http
          .get("metrics/oee_interval/", { params: params })
          .then((res) => {
            this.loaded = true;
            resolve(res.data);
          })
          .catch((e) => {
            this.loaded = true;
            reject(e);
          });
      });
    },
    format_data() {
      let production = [];
      let labels = [];
      let titles = [];
      let footers = [];
      let gradients = [];
      let table_data = [];

      if (this.production) {
        this.production.buckets.forEach((interval) => {
          production.push(interval.oee ? (interval.oee.value * 100).toFixed(2) : 0);
          gradients.push(this.$colors.chart.neutral_gradient);
          labels.push(this.options.labelFromString(interval.key_as_string));
          titles.push(this.options.labelFromToString(interval.key_as_string));

          table_data.push({
            date: this.options.labelFromString(interval.key_as_string),
            oee: interval.oee ? (interval.oee.value * 100).toFixed(2) : 0,
            performance: Math.round((interval.performance ? interval.performance.value : 0) * 100),
            quality: Math.round((interval.quality ? interval.quality.value : 0) * 100),
            availability: Math.round(
              (interval.availability ? interval.availability.value : 0) * 100
            )
          });
        });
      }

      this.chartData = {};
      this.table_data = table_data;

      //see if this fixes our rendering glitch
      requestAnimationFrame(() => {
        this.chartData = {
          datasets: [
            {
              data: production,
              gradients: gradients,
              footers: footers,
              titles: titles,
              label: this.$t("OEE")
            }
          ],
          labels: labels
        };
      });
    }
  },
  computed: {
    ...mapGetters({
      theme: "app/Theme"
    }),
    chartOptions() {
      let defaults = {
        scales: {
          scaleLineColor: "transparent",
          yAxes: [{ display: false }]
        }
      };
      if (this.options.settings) {
        return _.merge(defaults, this.options.settings);
      }
      return defaults;
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: "load_data"
    },
    interactiveFilters: function () {
      this.load_data()
        .then((response) => {
          this.production = response;
          this.oee_sum = response.total.buckets[0].oee.value * 100;
          if (this.oee_sum < 0) {
            this.oee_sum = 0;
          }
          this.format_data();
        })
        .catch((e) => {
          this.production = null;
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.oee-chart {
  padding-top: unset;
  padding-bottom: unset;
  width: 100%;
  height: 100%;
  min-height: 18vh;
  display: block;

  .charts {
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
  }
}
</style>
