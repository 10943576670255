<template>
  <div class="banner">
    <div class="banner-card">
      <div class="left">
        <MenuMain class="menu-main" />
        <router-link
          v-if="!isSm"
          :to="{
            name: 'welcome',
            params: urlParams
          }"
          class="d-none d-sm-flex"
        >
          <img
            v-if="logo"
            :src="logo"
            :alt="$t('Logo')"
            class="logo"
          />
          <div
            v-else
            class="logo"
          />
        </router-link>

        <v-btn
          v-if="favorites.length > 0"
          icon
          @click="openFavorites"
          class="favorite-btn mx-sm-8"
        >
          <v-icon
            size="20"
            class="fa fa-heart-o"
          />
        </v-btn>

        <ModalDialog
          content-class="favorites-dialog"
          v-model="favoritesOpen"
          full-screen-mobile
          persistent
          max-width="576px"
          height="416px"
          @click="favoritesOpen = false"
        >
          <template #title>{{ $t("Favorites") }}</template>
          <template #body>
            <a
              class="favorites"
              v-for="favorite in favorites"
              :href="favorite.url"
              @click.stop="close"
            >
              <Card
                class="favorite-card px-4 py-3"
                custom-padding
              >
                <h4 class="text-body-1 font-weight-medium">
                  {{ favorite.name ? favorite.name : favorite.url }}
                </h4>
              </Card>
            </a>
          </template>
          <template #actions><span></span></template>
        </ModalDialog>

        <div
          class="qualityWarning"
          v-if="showQualityWarning && !hideBanner"
        >
          <p>{{ $t("For information only.") }}</p>
          <p>{{ $t("Not for use in Quality decisions.") }}</p>
        </div>

        <div
          class="ml-3"
          v-if="!isSm"
        >
          <ThemeSwitcher />
        </div>

        <div class="ml-4 d-none d-sm-flex">
          <ViewSwitcher :label="$t('New system look')" />
        </div>
      </div>
      <div class="middle">
        <div class="mobile-title text-h6 text-center font-weight-medium align-center d-sm-none">
          <portal-target
            name="mobile-header-title"
            slim
          />
        </div>
        <div v-if="location.banner && vw >= 700">
          <h3 v-for="line in location.banner.split('/n').slice(0, 2)">{{ line }}</h3>
        </div>
      </div>

      <div class="right">
        <div class="rightOptions">
          <HelpCenter />

          <div
            v-if="titles && titles.title"
            class="title-container d-none d-sm-block"
            v-html="titles && titles.title ? $t(titles.title) : ''"
            @click.stop.prevent="titles.clickFunction"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, getCurrentInstance, ref } from "vue";
import { mapActions } from "vuex";
import { useRoute } from "vue-router/composables";

import Card from "@/ui/Card";
import DialogCloseButton from "@/components/DialogCloseButton";
import DevelopmentFeature from "./DevelopmentFeature.vue";
import HelpCenter from "../features/help-center/HelpCenter.vue";
import ModalDialog from "@/ui/ModalDialog/ModalDialog.vue";
import RadialFab from "@/components/RadialFab";
import ThemeSwitcher from "@/ui/ThemeSwitcher";
import ViewSwitcher from "@/ui/ViewSwitcher";
import { hasUserRole, userRoles } from "@/utils/user";
import { useDeviceResolution } from "@/shared/useDeviceResolution";
import { useViewSwitcher } from "@/shared/useViewSwitcher";
import { useGroupParams } from "@/features/group/useGroupParams";
import MenuMain from "@/ui/Menu/MenuMain.vue";

export default {
  components: {
    Card,
    DevelopmentFeature,
    DialogCloseButton,
    ModalDialog,
    HelpCenter,
    MenuMain,
    RadialFab,
    ThemeSwitcher,
    ViewSwitcher
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { isSm } = useDeviceResolution();
    const { updateRedirectToggle, isRedirectToggleEnabled } = useViewSwitcher();
    const { machine_group_level, machine_group_id, machine_group_pk } = useGroupParams();
    const route = useRoute();
    const logo = ref(false);

    const isMachineRoute = computed(() => route.name === "machine");
    const favorites = computed(() => vm.$store.getters["app/Favorites"]);
    const titles = computed(() => vm.$store.getters["app/Titles"]);
    const location = computed(() => vm.$store.getters["session/Location"]);
    const user = computed(() => vm.$store.getters["session/User"]);
    const vw = computed(() => vm.$store.getters["app/VW"]);
    const currentTheme = computed(() => (user.value.theme ? "dark" : "light"));
    const themeIcon = computed(() => {
      if (currentTheme.value === "dark") {
        return "fa fa-moon-o";
      }
      return "fa fa-sun-o";
    });
    const urlParams = computed(() =>
      machine_group_level?.value &&
      !isNaN(machine_group_level?.value) &&
      !isNaN(machine_group_id?.value) &&
      !isNaN(machine_group_pk?.value)
        ? {
            level: machine_group_level.value,
            machine_group_id: machine_group_id.value,
            machine_group_pk: machine_group_pk.value
          }
        : {}
    );
    const hideBanner = computed(
      () => route.path.startsWith("/iot") || route.path.startsWith("/digital-forms")
    );

    const favoritesOpen = ref(false);

    return {
      accessToConfig: false,
      currentTheme: currentTheme.value,
      favorites,
      favoritesOpen,
      hideBanner,
      isMachineRoute: isMachineRoute.value,
      isSm,
      location: location.value,
      logo,
      machine_group_id,
      machine_group_level,
      machine_group_pk,
      redirectToggle: isRedirectToggleEnabled().value,
      showQualityWarning: false,
      themeIcon: themeIcon.value,
      titles,
      updateRedirectToggle,
      urlParams: urlParams.value,
      user: user.value,
      vw: vw.value
    };
  },

  created() {
    this.$http
      .get("graphql/", {
        params: {
          query: `{settings{
        bannerLogo
        showQualityWarning
      }
    }`
        }
      })
      .then((res) => {
        if (res.data.data.settings.length > 0) {
          this.logo = res.data.data.settings[0].bannerLogo;
          this.showQualityWarning = res.data.data.settings[0].showQualityWarning;
        }
      });
    this.accessToConfig = hasUserRole(this.user, userRoles.ApplicationAdmin);
    this.loadFavorites();
  },
  methods: {
    ...mapActions({
      setRightPanel: "components/SetRightPanel",
      setHelpCenterVisibility: "components/SetHelpCenterVisibility",
      setTheme: "app/SetTheme",
      setFavorites: "app/SetFavorites",
      updateProfile: "session/UpdateProfile"
    }),
    openFavorites() {
      this.favoritesOpen = true;
    },
    loadFavorites() {
      const query = `query($userId:Int){
            favorites(userId:$userId){
              url,
              id,
              name,
        }
      }`;
      const variables = {
        userId: this.user.id
      };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.setFavorites(res.data.data.favorites);
        })
        .catch((res) => {
          this.errors = res.errors;
        });
    }
  },
  watch: {
    redirectToggle(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.location.reload();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/variables";
@import "@/scss/mq";

.favorites {
  display: block;
  text-decoration: none;

  & ~ .favorites {
    margin-top: 12px;
  }

  &:hover .favorite-card {
    background-color: var(--btn-secondary-hover) !important;
    transition: all 0.2s ease-in-out;
  }
}

.favorite-card {
  background-color: var(--bg-card-white);
}

.banner {
  .mobile-title:empty {
    display: none;
  }
  .banner-card {
    z-index: 99999;
    background-color: $darkGrey;
    height: $bannerHeight;
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      width: 40%;
    }
    .qualityWarning {
      background-color: $blue;
      border-radius: rem(5px);
      padding: rem(5px) rem(7px);
      min-width: rem(150px);
      p {
        margin: rem(0px);
        font-weight: 600;
        font-size: 0.85rem;
      }
    }
    .left {
      display: flex;
      align-items: center;
      cursor: pointer;
      > i {
        display: block;
        margin: 1rem;
        font-size: 1.5rem;
      }
      img {
        display: block;
        max-width: 15rem;
        max-height: rem(40px);
        margin: rem(15px) 1rem 1rem;
      }
      a {
        display: block;
        cursor: pointer;
        color: $white;
        text-decoration: none;
      }
    }
    .right {
      margin-right: 1rem;
      text-align: right;

      .rightOptions {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .title-container {
          padding: rem(10px);
        }
      }

      h3 {
        font-weight: 500;
      }
      .backBtn {
        background: $blue;
        border-radius: 0.2rem;
        padding: 0.25rem 0.5rem;
        width: 15rem;
        margin-left: auto;
        text-align: center;
        cursor: pointer;
        i {
          margin-right: 0.5rem;
        }
      }
    }
    .middle {
      flex: 1 1 100%;

      h3 {
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        &:first-child {
          font-size: 1.25rem;
        }
      }

      @media #{map-get($display-breakpoints, 'xs-only')} {
        min-width: rem(100px);
      }
    }
  }
}

:deep(.view-switcher-label) {
  white-space: nowrap;
}

.new-system-look {
  min-width: rem(170px);
}

.theme--light.v-application {
  .banner {
    .banner-card {
      background-color: $light-background;
      .left {
        a {
          color: $light-icon;
        }
      }
    }
  }
}

.fa-sun-o {
  right: -3.4rem;
}

.fa-moon-o {
  right: -2.1rem;
  color: $blue;
}

.logo {
  min-height: rem(40px);
  min-width: rem(40px);
  margin: rem(15px) rem(16px);
}

.favorite-btn {
  margin-left: rem(80px);
}

.menu-main {
  z-index: 1;
}

@include media_below(rem(1000px)) {
  .banner {
    position: relative;
    .qualityWarning {
      position: absolute;
      left: 0;
      display: flex;
      top: -0.2rem;
      p {
        margin: rem(0px);
        font-size: 0.85rem;
      }
    }
  }
}
@include media_below(rem(700px)) {
  .banner {
    position: relative;
    .qualityWarning {
      position: absolute;
      left: 0;
      display: flex;
      width: 100vw;
      top: -0.2rem;
      p {
        margin: rem(0px);
        font-size: 0.85rem;
      }
    }
    .banner-card {
      height: $bannerHeightMobile;
      padding-top: $bannerMobileTopPadding;
      .left {
        margin-left: 0.75rem;
      }
      .right {
        width: 60%;
        h3 {
          font-size: 0.75rem;
        }
      }
    }
  }
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .banner .banner-card {
    height: rem(72px);
  }
  .rightOptions {
    ::v-deep .v-btn.v-btn--icon {
      width: rem(36px);
      min-width: rem(36px);
      height: rem(36px);
    }
  }
  .banner-card {
    .middle {
      flex-grow: 1;
    }
    .left,
    .right {
      width: auto !important;
      flex-grow: 0 !important;
      flex-shrink: 0;
    }
  }
}
</style>

<style lang="scss">
.favorites-dialog {
  .body {
    padding-bottom: 12px !important;
  }
  .footer {
    display: none !important;
  }
}
</style>
