<template>
  <div class="downtime-events-by-machine">
    <div class="card">
      <div class="card-header">
        <h4>{{ $t("Downtime Events Trend (counts)") }}</h4>
      </div>
      <div class="card-content">
        <v-progress-linear
          indeterminate
          v-if="loading"
        />
        <BarChart
          class="chart-container"
          v-if="!loading"
          :chart-data="chartData"
          :click-function="(event, clickedElements) => handleBarChartClick(event, clickedElements)"
        />
        <div class="footer">
          <bar-chart-paginator
            v-if="!loading"
            :refresh="filterController.reload"
            :rawData="responseData"
            :sortMethod="sortFunc"
            default-sort="desc"
            :defaultQuantity="25"
            :buildChart="formatData"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { tiffanyBlue } from "@/scss/_variables.js";
import { handleBackgroundColorOpacity } from "@/utils/level2Utils";

import BarChartPaginator from "../../../../components/BarChartPaginator.vue";
import BarChart from "@/components/charts/BarChart";
import { getDateDisplay } from "../../../../utils/datetime";

export default {
  name: "DowntimeEventsTrend",
  props: ["filterController", "interactiveFilters"],
  async created() {
    await this.loadData();
  },
  components: {
    BarChart,
    BarChartPaginator
  },
  data() {
    return {
      loading: true,
      responseData: [],
      chartData: {},
      chartIds: [],
      trendIds: [],
      slicedData: []
    };
  },
  computed: {
    ...mapGetters({
      machineCodes: "dbCache/machineCodes"
    })
  },
  methods: {
    handleBarChartClick(event, clickedElements) {
      if (clickedElements.length === 0) {
        return;
      }
      const elementIndex = clickedElements[0]._index;
      this.setInteractiveFilter(elementIndex);
    },
    setInteractiveFilter(elementIndex) {
      const trendId = this.trendIds[elementIndex];
      const foundTrendIndex = this.interactiveFilters.trends.findIndex(
        (code) => code.id === trendId
      );
      if (foundTrendIndex >= 0) {
        this.interactiveFilters.trends.splice(foundTrendIndex, 1);
      } else {
        if (this.interactiveFilters.trends.length > 0) {
          this.interactiveFilters.trends.splice(0, 1);
        }
        this.interactiveFilters.trends.push({ id: trendId, index: elementIndex });
      }
    },
    updateBackgroundColors() {
      this.chartData.datasets[0].backgroundColor = handleBackgroundColorOpacity(
        this.slicedData,
        this.chartData,
        this.interactiveFilters
      );
      this.chartData = { ...this.chartData };
    },
    async loadData() {
      this.loading = true;
      const params = {
        from_date: this.filterController.initialFilters.date_range.from_date,
        to_date: this.filterController.initialFilters.date_range.to_date,
        interval: this.filterController.getElasticsearchInterval()
      };
      if (this.pageSize) {
        params.pageSize = this.pageSize;
      }
      if (
        this.filterController.hasFilter("machines") &&
        this.interactiveFilters.machines.length === 0
      ) {
        params["machine_ids"] = this.filterController.getFilterPks("machines");
      }
      if (
        this.filterController.hasFilter("machine_groups") &&
        this.interactiveFilters.machines.length === 0
      ) {
        params["machine_group_ids"] = this.filterController.getFilterPks("machine_groups");
      }
      if (this.interactiveFilters.machines.length > 0) {
        params.machine_ids = this.interactiveFilters.machines.map((machine) => machine.id);
      }
      if (this.interactiveFilters.codes.length > 0) {
        params.codes = this.interactiveFilters.codes.map((code) => code.id);
      }
      if (this.filterController.initialFilters.codeEventType) {
        params.event_type = this.filterController.initialFilters.codeEventType;
      }
      if (this.filterController.hasFilter("parts")) {
        params.part_numbers = this.filterController.initialFilters.parts;
      }
      try {
        const response = await this.$http.get("level2/events_trend/", { params });
        if (response && response.data) {
          this.responseData = response.data;
        }
      } catch (error) {
        console.error(error);
      }
      this.loading = false;
    },
    formatData(slicedData) {
      this.chartData = {};
      this.chartIds = [];

      this.chartData.datasets = [
        {
          data: [],
          label: [],
          backgroundColor: []
        }
      ];

      this.chartData.title = "eventsTrend";
      this.chartData.labels = [];
      this.chartData.titles = [];

      let index = 0;
      for (const s of slicedData || []) {
        this.chartData.datasets[0].data.push(s.doc_count);
        this.chartData.datasets[0].label = this.$t("Downtime Events Trend(count)");
        let name = getDateDisplay(s.key_as_string, this.filterController.initialFilters.scale);
        this.chartData.labels.push(name);
        this.chartData.labels.titles = name;
        let color = tiffanyBlue;
        this.chartData.datasets[0].backgroundColor.push([color, color]);
        index++;
        this.chartIds.push(s.id);
      }
      let colors = this.chartData.datasets[0].data.map((item) => "rgba(67, 167, 180, .9)");
      this.chartData.datasets[0].backgroundColor = colors;
      this.slicedData = slicedData;
      this.trendIds = slicedData.map((trend) => trend.key);
      this.updateBackgroundColors();
    },
    sortFunc(rawData, sortOrder) {
      rawData.sort((a, b) => {
        const aVal = a.key;
        const bVal = b.key;
        if (sortOrder == "asc") {
          return bVal - aVal;
        } else {
          return aVal - bVal;
        }
      });
      return rawData;
    }
  },
  watch: {
    "filterController.reload": function () {
      this.loadData();
    },
    "interactiveFilters.codes.length": function () {
      if (!this.loading) {
        this.loadData();
      }
    },
    "interactiveFilters.machines.length": function () {
      if (!this.loading) {
        this.loadData();
      }
    },
    "interactiveFilters.trends": function () {
      if (!this.loading) {
        this.updateBackgroundColors();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.downtime-events-by-machine {
  height: 100%;
  .card {
    height: 100%;
  }
}
</style>
