<template>
  <text-area
    :no-resize="true"
    :auto-grow="true"
    rows="1"
    name="input-7-4"
    v-bind="$props"
    v-on="$listeners"
  >
    <template #append>
      <FileUpload
        ref="fileUpload"
        class="file-upload mr-2"
        :acceptable-formats="accept"
        enabled-menu
        hide-files-list
        @select-file="$emit('change-files', [$event])"
      />
    </template>

    <template #bottom>
      <div
        v-if="files && files[0]"
        class="file-upload pa-4"
      >
        <FileCard
          :file="files[0]"
          @delete-file="$emit('change-files', [])"
        />
      </div>
    </template>
  </text-area>
</template>

<script>
import TextArea from "@/ui/TextArea/TextArea.vue";
import FileCard from "@/ui/FileCard/FileCard.vue";
import FileUpload from "@/ui/FileUpload/FileUpload.vue";

export default {
  name: "TextAreaControl",
  components: {
    FileCard,
    TextArea,
    FileUpload
  },
  props: {
    files: {
      type: Array,
      default: undefined
    },
    value: {
      type: String,
      default: ""
    },
    accept: {
      type: String,
      default: ""
    }
  },
  emits: ["change-files"]
};
</script>

<style lang="scss" scoped>
@import "@/scss/input.scss";
.file-upload {
  ::v-deep {
    .v-icon {
      color: var(--color-primary) !important;
    }
  }
}
</style>
