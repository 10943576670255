<template>
  <div class="auditForm">
    <div
      class="formCard card"
      v-if="auditVersion && auditType"
    >
      <div class="header card">
        <div class="left-group">
          <h1>
            <span>{{ $t("Name") }}</span> : {{ auditType.name }}
          </h1>
          <h1>
            <span>{{ $t("Revision") }}</span> : {{ auditType.currentVersion }}
          </h1>
        </div>
        <div class="right-group">
          <v-tooltip
            bottom
            v-if="selectedForm"
          >
            <template v-slot:activator="{ on }">
              <div
                class="idText"
                v-on="on"
              >
                <v-btn
                  color="red"
                  light
                  fab
                  x-small
                  @click="deselectForm()"
                >
                  <v-icon>fa-times</v-icon>
                </v-btn>
              </div>
            </template>
            {{ $t("Deselect Form") }}
          </v-tooltip>
          <h1 v-if="selectedForm">
            <span>{{ $t("ID") }}</span> : {{ selectedForm.id }}
          </h1>
          <h1 v-if="selectedForm && selectedForm.machine">
            <span>{{ $t("Machine") }}</span> : {{ selectedForm.machine.name }}
          </h1>
          <h1 v-if="selectedForm">
            <span>{{ $t("User") }}</span> : {{ selectedForm.startUser.fullName }}
          </h1>
          <h1 v-if="selectedForm">
            <span>{{ $t("Started") }}</span> : {{ time(selectedForm.startTime) }}
          </h1>
        </div>
      </div>
      <v-tabs
        v-model="tab"
        fixed-tabs
        show-arrows
        center-active
        @change="categoryChange"
      >
        <v-tab key="home">
          <v-icon>mdi-home</v-icon>
        </v-tab>
        <v-tab
          v-for="(category, category_index) in auditVersion.categories"
          :key="category.order"
          :disabled="!selectedForm"
        >
          {{ category.name }}
        </v-tab>
        <v-tab
          key="submit"
          :disabled="!selectedForm"
        >
          <v-icon>mdi-content-save</v-icon>
        </v-tab>

        <!-- Home Tab -->
        <v-tab-item key="home">
          <div
            class="audit-type"
            v-if="auditType"
          >
            <div class="description">
              <h3>{{ $t("Description") }}</h3>
              <p>{{ auditType.description }}</p>
            </div>
            <div class="modal-popup">
              <v-btn
                class="newBtn"
                color="primary"
                @click="startNewAudit"
              >
                {{ $t("Start New Audit") }}
              </v-btn>
              <v-dialog
                v-model="displayDialog"
                max-width="450"
              >
                <v-card>
                  <DialogCloseButton :onClick="() => (displayDialog = false)" />
                  <v-card-title class="text-h5">Please select a machine:</v-card-title>
                  <v-card-actions>
                    <v-col
                      cols="10"
                      sm="10"
                    >
                      <v-select
                        v-model="selectedMachine"
                        v-if="auditType.machineRequired"
                        :items="auditType.machines"
                        :menu-props="{ maxHeight: '400' }"
                        :label="$t('Select Machines')"
                        required
                        outlined
                      >
                        <template v-slot:item="{ item }">{{ item.name }}</template>
                        <template v-slot:selection="{ item }">{{ item.name }}</template>
                      </v-select>
                    </v-col>
                    <v-btn
                      color="primary"
                      text
                      @click="createResponseForm"
                      >{{ $t("OK") }}</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
            <v-data-table
              v-if="uniqueInProgressTableData"
              :headers="uniqueInProgressTableData.headers"
              item-key="id"
              :loading="uniqueInProgressTableData.loadingData"
              :items="uniqueInProgressTableData.data"
              :sortBy.sync="uniqueInProgressTableData.sortBy"
              :sortDesc.sync="uniqueInProgressTableData.sortDesc"
              :page.sync="uniqueInProgressTableData.page.current"
              :itemsPerPage.sync="uniqueInProgressTableData.page.itemsPerPage"
              :serverItemsLength="uniqueInProgressTableData.total"
              :footer-props="{ 'items-per-page-options': [5, 10, 25, 50] }"
              loading-text="Loading Responses... Please wait"
              disable-pagination
              fixed-header
              multi-sort
            >
              <template v-slot:[`item.name`]="{ item }">{{ item.version }}</template>
              <template v-slot:[`item.startTime`]="{ item }">{{ time(item.startTime) }}</template>
              <template v-slot:[`item.currentVersion`]="{ item }">{{
                item.currentVersion
              }}</template>
              <template v-slot:[`item.resume`]="{ item }">
                <v-btn
                  class="actionButton"
                  small
                  color="success"
                  fab
                  @click.stop.prevent="resumeAudit(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <template v-slot:[`item.machine.name`]="{ item }">
                {{ item.machine ? item.machine.name : "" }}
              </template>
            </v-data-table>
          </div>
        </v-tab-item>

        <!-- Category Tabs -->
        <v-tab-item
          v-for="(category, category_index) in auditVersion.categories"
          :key="category.order"
        >
          <v-form v-model="isFormValid">
            <!-- Display Files for Questions in This Category -->
            <div v-if="category.questions.some((question) => question.auditFiles?.length)">
              <div
                v-for="question in category.questions"
                :key="question.id"
              >
                <div v-if="question.auditFiles?.length">
                  <div
                    v-for="file in question.auditFiles"
                    :key="file.id"
                  >
                    <v-card
                      max-width="800"
                      class="aspect-16-9"
                    >
                      <v-responsive :aspect-ratio="16 / 9">
                        <!-- Vídeo -->
                        <div v-if="getExtension(file.file) === 'mp4'">
                          <video
                            class="video-player"
                            controls
                            :src="file.fileUrl"
                          ></video>
                        </div>

                        <!-- Imagem -->
                        <v-img
                          v-else-if="
                            ['jpg', 'jpeg', 'png', 'gif'].includes(getExtension(file.file))
                          "
                          :src="file.fileUrl"
                        />

                        <!-- PDF -->
                        <div
                          v-else-if="getExtension(file.file) === 'pdf'"
                          style="height: 100%"
                        >
                          <iframe
                            :src="file.fileUrl"
                            style="width: 100%; height: 100%; border: none"
                          ></iframe>
                        </div>

                        <div v-else>
                          <iframe>
                            <a
                              :href="file.fileUrl"
                              target="_blank"
                              >Download file</a
                            >
                          </iframe>
                        </div>
                      </v-responsive>
                    </v-card>
                  </div>
                </div>
              </div>
            </div>

            <div class="upload-section">
              <AttachAuditFiles
                allowMultiple
                acceptedFileTypes=".pdf,.doc,.docx,.txt,.svg,.png,.jpg,.jpeg,.mp4"
                :category-index="category_index"
                :question-index="questionIndex.current"
                @files-selected="handleFilesSelected"
                @file-removed="handleFileRemoved"
              />
            </div>

            <!-- Question Navigation and Display -->
            <div class="questionControl">
              <v-btn
                @click="changeQuestion('prev')"
                color="primary"
                small
                fab
              >
                <v-icon>fa-chevron-left</v-icon>
              </v-btn>
              <p>{{ questionIndex.current + 1 }}</p>
              <p>/</p>
              <p>{{ questionIndex.total }}</p>
              <v-btn
                @click="changeQuestion('next')"
                color="primary"
                small
                fab
              >
                <v-icon>fa-chevron-right</v-icon>
              </v-btn>
            </div>
            <div
              class="question row"
              v-if="currentQuestion"
            >
              <div class="col-xs-12 col-md-4">
                <div class="questionText section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Question") }}</h3>
                  </div>
                  <div class="sectionContent">
                    <p>{{ currentQuestion.text }}</p>
                  </div>
                </div>
                <div class="instructions section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Instructions") }}</h3>
                  </div>
                  <div class="sectionContent">
                    <p>{{ currentQuestion.instruction }}</p>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="response section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Response") }}</h3>
                  </div>
                  <div class="sectionContent">
                    <div
                      class="multipleChoice"
                      v-if="
                        currentQuestion.type == 'multiple_choice' ||
                        currentQuestion.type == 'true_false'
                      "
                    >
                      <v-radio-group
                        v-model="currentQuestion.response.text"
                        :id="currentQuestion.id"
                        :name="currentQuestion.id"
                      >
                        <v-radio
                          v-for="(answer, answerIndex) in currentQuestion.answers"
                          :key="answer.id"
                          :label="answer.text"
                          :value="answer.text"
                        ></v-radio>
                      </v-radio-group>
                    </div>
                    <div
                      class="multipleAnswer"
                      v-if="currentQuestion.type == 'multiple_answer'"
                    >
                      <v-checkbox
                        v-for="(answer, answerIndex) in currentQuestion.answers"
                        :key="answerIndex"
                        v-model="currentQuestion.response.text"
                        :label="answer.text"
                        :value="answer.text"
                      ></v-checkbox>
                    </div>
                    <div
                      class="text"
                      v-if="currentQuestion.type == 'text'"
                    >
                      <v-text-field
                        v-model="currentQuestion.response.text"
                        :placeholder="$t('Type Response Here')"
                        required
                      ></v-text-field>
                    </div>
                    <div
                      class="text"
                      v-if="currentQuestion.type == 'number' || currentQuestion.type == 'range'"
                    >
                      <number-input v-model="currentQuestion.response.text"></number-input>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-md-4">
                <div class="comment section">
                  <div class="sectionLabel">
                    <h3>{{ $t("Please add any additional comments or observations") }}</h3>
                  </div>
                  <div
                    class="sectionContent inputField"
                    v-if="currentQuestion"
                  >
                    <textarea
                      :key="currentQuestion.response.comment"
                      v-model="currentQuestion.response.comment"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </v-form>
        </v-tab-item>

        <!-- Submit Tab -->
        <v-tab-item key="submit">
          <div
            class="audit-type"
            v-if="auditType"
          >
            <v-expansion-panels accordion>
              <v-expansion-panel
                v-for="(category, c_index) in auditVersion.categories"
                :key="category.order"
              >
                <v-expansion-panel-header>
                  <div class="expansion-cat">
                    <h3>{{ category.name }}</h3>
                    <p>{{ $t("Progress:") }} {{ category.progress }}%</p>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    class="questionReview card"
                    v-for="(question, q_index) in category.questions"
                  >
                    <div>
                      {{ question.text }}
                      <div class="formButton">
                        <v-btn
                          small
                          color="warning"
                          fab
                          @click.stop.prevent="backToQuestion(q_index, c_index)"
                        >
                          <i
                            class="fa fa-edit"
                            aria-hidden="true"
                          ></i>
                        </v-btn>
                      </div>
                    </div>
                    <p
                      class="response"
                      v-if="question.response"
                    >
                      {{ question.response.text }}
                    </p>
                    <p
                      class="response"
                      v-if="question.response"
                    >
                      {{ question.response.comment }}
                    </p>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="buttons">
              <v-btn
                :disabled="disabledSubmit"
                color="primary"
                @click="submitAudit(true)"
              >
                {{ $t("Submit") }}
              </v-btn>
            </div>
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../scss/variables";

.aspect-16-9 {
  position: relative;
}

.video-player {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.image-preview {
  object-fit: contain;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.auditForm {
  height: calc(100% - 4rem);
  max-height: 100%;

  @media screen and(max-width: 1200px) {
    height: unset;
    max-height: unset;
    padding-bottom: 10px;
  }

  .modal-popup {
    @media screen and(max-width: 1200px) {
      padding-bottom: 20px;
    }
  }

  .v-data-table__mobile-row {
    @media screen and(max-width: 599px) {
      padding: 5px !important;
    }
  }

  .formCard {
    margin: 1rem 0;
    padding: 1rem;
    height: calc(100% - 1rem);
    display: grid;
    grid-template-rows: minmax(30px, min-content) auto;

    @media screen and(max-width: 1200px) {
      display: flex;
      flex-direction: column;
      height: unset;
      padding: 0;
    }
    .video-player {
      height: 250px;
      border-radius: 0.25rem;
      overflow: hidden;
      video {
        height: 225px;
      }
    }
    .header {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      flex-grow: 1;
      min-height: unset;

      .left-group,
      .right-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        .idText {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .v-icon {
            color: white;
          }
        }
      }
      h1 {
        font-size: 1.25rem;
        font-weight: 600;
        margin: 1rem;
        span {
          font-size: 1rem;
          font-weight: 400;
        }
      }
    }
    .v-tabs {
      display: grid;
      grid-template-rows: min-content;
      @media screen and(max-width: 1200px) {
        display: flex;
        flex-direction: column;
        height: unset;
      }
      .v-window__container {
        height: 100%;
        .v-window-item {
          height: 100%;
        }
      }
    }
    form {
      height: calc(100% - 3.5rem);
    }
    .audit-type {
      h3 {
        margin-top: 1rem;
        font-weight: 600;
      }
      .newBtn {
        margin-top: 1rem;
      }
    }
    .expansion-cat {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      h3 {
        margin: 0;
      }
    }
    .questionReview {
      margin: 1rem;
      padding: 1rem;
      min-height: 100px;
      .response {
        margin: 1rem;
      }
    }
    .buttons {
      padding: 1.25rem 0;
      .v-btn {
        margin-right: 1rem;
        width: 150px;
      }
    }
    .formButton {
      padding: 0 1.25rem 1.25rem 0;
      float: right;
    }
    .theme--dark.v-tabs-items {
      background-color: transparent;
    }
    .questionControl {
      display: flex;
      justify-content: center;
      align-items: center;
      .v-btn {
        font-size: 1rem;
        margin: 1rem 2rem;
      }
      p {
        margin: 0 1rem;
      }
    }
    .question {
      height: 100%;
      .section {
        position: relative;
        padding-top: 3rem;
        height: 50%;

        @media screen and(max-width: 1200px) {
          &.comment {
            display: flex;
            flex-direction: column;
            margin-top: 0;
            padding-top: 0;

            div {
              flex: 1;
              align-self: normal;
              justify-self: normal;
              position: relative;
            }
          }
        }

        .sectionLabel {
          position: absolute;
          top: 0;
          margin: 0.5rem;
        }
        .sectionContent {
          height: 100%;
          padding: 1rem;
          border: 1px solid transparent;
          border: 1px solid rgba(255, 255, 255, 0.8);
          border-radius: 5px;
          &.inputField {
            min-height: 200px;
            max-height: 100%;
            textarea {
              height: 100%;
              width: 100%;
              color: white;
              &:focus-visible {
                outline: none;
              }
            }
          }
        }
        &.response {
          height: 100%;
          .sectionContent {
            padding: 0rem 1rem;
          }
        }
      }
    }
  }
}

@include light_theme() {
  .auditForm {
    .theme--light.v-tabs-items {
      background-color: transparent;
    }
    .formCard {
      .question {
        .section {
          .sectionContent {
            border-color: black;
            &.inputField {
              textarea {
                color: black;
              }
            }
          }
        }
      }
      @media screen and(max-width: 600px) {
        background-color: rgba(0, 0, 0, 0);
        .header {
          background-color: rgba(0, 0, 0, 0);
        }
      }
    }
  }
}
</style>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";

import { AttachAuditFiles, useAuditFileUpload } from "./components/auditFiles";

import DialogCloseButton from "@/components/DialogCloseButton";
import NumberInput from "@/components/form/NumberInput";
import { Category, Response } from "@/datatypes/audits";
import { TableData } from "@/datatypes/table";
import { time } from "@/utils/filters";

export default {
  props: ["auditTypeId"],
  data() {
    return {
      displayDialog: false,
      lastOptions: {},
      isFormValid: false,
      fieldRules: [(v) => (v && v.length > 0) || "You must answer this question to continue."],
      tab: "home",
      auditType: null,
      auditVersion: null,
      selectedForm: null,
      responses: [],
      machines: [],
      savingAudit: false,
      selectedMachine: null,
      questionIndex: {
        prev: -1,
        next: null,
        current: 0,
        back: true
      },
      currentCategory: null,
      currentQuestion: null,
      errors: false,
      inProgressTableData: null,
      responseTable: new TableData([
        { text: this.$t("ID"), value: "id" },
        { text: this.$t("Start Time"), value: "startTime" },
        { text: this.$t("User"), value: "startUser.fullName" },
        { text: "Resume", value: "resume" }
      ]),
      responseTableWithMachine: new TableData([
        { text: this.$t("ID"), value: "id" },
        { text: this.$t("Start Time"), value: "startTime" },
        { text: this.$t("User"), value: "startUser.fullName" },
        { text: this.$t("Machine"), value: "machine.name" },
        { text: this.$t("Resume"), value: "resume" }
      ]),
      auditFiles: [],

      selectedFiles: [],
      filesByResponse: {}
    };
  },
  setup() {
    const { uploadFiles, uploading } = useAuditFileUpload();
    return { uploadFiles, uploading };
  },

  created() {
    this.lastOptions = this.auditTypeId;
    this.loadAudit(true);
  },
  components: {
    NumberInput,
    DialogCloseButton,
    AttachAuditFiles
  },
  computed: {
    ...mapGetters({
      user: "session/User"
    }),
    uniqueInProgressTableData() {
      const uniqueItems = [];
      const seenIds = new Set();
      for (const item of this.inProgressTableData.data) {
        if (!seenIds.has(item.id)) {
          seenIds.add(item.id);
          uniqueItems.push(item);
        }
      }
      return {
        ...this.inProgressTableData,
        data: uniqueItems
      };
    },
    disabledSubmit() {
      let invalid_response = false;
      this.auditVersion.categories.forEach((category) => {
        category.questions.forEach((question) => {
          if (!question.response) {
            invalid_response = true;
          }
        });
      });
      return invalid_response;
    }
  },
  methods: {
    moment,
    time,
    ...mapActions({}),
    openFile(url) {
      window.open(url, "_blank");
    },
    getExtension(fileName) {
      return fileName.split(".").pop().toLowerCase();
    },
    getFileIcon(fileName) {
      const extension = this.getExtension(fileName);
      const icons = {
        pdf: "mdi-file-pdf-box",
        doc: "mdi-file-word-box",
        docx: "mdi-file-word-box",
        xls: "mdi-file-excel-box",
        xlsx: "mdi-file-excel-box",
        ppt: "mdi-file-powerpoint-box",
        pptx: "mdi-file-powerpoint-box",
        jpg: "mdi-file-image",
        jpeg: "mdi-file-image",
        png: "mdi-file-image",
        gif: "mdi-file-image",
        txt: "mdi-file-document-outline",
        zip: "mdi-folder-zip",
        rar: "mdi-folder-zip",
        csv: "mdi-file-delimited",
        json: "mdi-code-json",
        xml: "mdi-file-xml-box",
        mp4: "mdi-file-video",
        mp3: "mdi-music-box"
      };

      return icons[extension] || "mdi-file";
    },

    handleFilesSelected(files, category_index, question_index) {
      const key = `category_${category_index}_question_${question_index}`;
      this.filesByResponse[key] = [...files];
    },

    handleFileRemoved(event) {
      const { categoryIndex, questionIndex, files } = event;
      const key = `category_${categoryIndex}_question_${questionIndex}`;
      if (files.length > 0) {
        this.filesByResponse[key] = [...files];
      } else {
        delete this.filesByResponse[key];
      }
    },

    loadAudit(initial = false) {
      this.responseTable.loading = true;
      const query = `query getByID($id: ID!){
        auditType(id: $id){
          id,
          ... on AuditTypeNode{
            id,
            name,
            currentVersion,
            description,
            machineRequired,
            machines{
              id,
              name
            }
            latestVersion{
              id
              categories {
                name,
                order
                questions{
                  id
                  text,
                  instruction,
                  type,
                  order,
                  estimatedTime,
                  answers{
                    id,
                    text,
                    order
                  }
                  auditFiles {
                  id
                  fileUrl
                  file
                  }
                }
              }
              inprogressAudits{
                id,
                startTime
                startUser{
                  fullName
                }
                machine{
                  id,
                  name,
                }
              }
            }
          }
        }
      }`;
      const variables = {
        id: this.auditTypeId
      };
      this.$http.post("graphql/", { query, variables }).then((res) => {
        this.tab = 0;
        this.auditType = res.data.data.auditType;
        this.auditVersion = this.auditType.latestVersion;

        this.auditVersion.categories.forEach((category) => {
          category.questions.forEach((question) => {
            question.auditFiles = question.auditFiles || [];
          });
        });

        if (this.auditType.machineRequired) {
          this.inProgressTableData = this.responseTableWithMachine;
        } else {
          this.inProgressTableData = this.responseTable;
        }
        this.machines = this.auditType.machines;
        if (initial && this.$route.params.machine_id) {
          this.machines.forEach((machine) => {
            if (machine.id == this.$route.params.machine_id) {
              this.selectedMachine = machine;
              this.displayDialog = true;
            }
          });
        }
        if (!this.auditVersion) {
          this.$router.push({
            name: "audit-form",
            params: { auditTypeId: item.form.type.id, responseFormId: item.id }
          });
        }
        let categories = [];
        this.auditVersion.categories.forEach((cat) => {
          categories.push(new Category(cat));
          cat.questions.forEach((question) => {
            question.response = new Response();
          });
        });
        this.auditVersion.categories = categories;
        this.inProgressTableData.data = this.auditVersion.inprogressAudits;
        this.inProgressTableData.total = this.auditVersion.inprogressAudits.length;
        this.inProgressTableData.loading = true;
      });
    },
    categoryChange(tab) {
      if (tab > 0) {
        if (tab == this.auditVersion.categories.length + 1) {
          this.currentCategory = null;
          this.currentQuestion = null;
        } else {
          if (!this.selectedForm) {
            this.tab = 0;
            return;
          }
          this.currentCategory = this.auditVersion.categories[tab - 1];
          this.questionIndex.total = this.currentCategory.questions.length;
          this.questionIndex.current = 0;
          this.questionIndex.prev = -1;
          if (this.questionIndex.total > 1) {
            this.questionIndex.next = 1;
          } else {
            this.questionIndex.next = null;
          }
        }
        this.setCurrentQuestion();
      } else {
        this.currentCategory = null;
        this.currentQuestion = null;
      }
    },
    changeQuestion(dir) {
      if (dir == "prev") {
        if (this.questionIndex.prev >= 0) {
          this.questionIndex.next = this.questionIndex.current;
          this.questionIndex.current = this.questionIndex.prev;
          if (this.questionIndex.current > 0) {
            this.questionIndex.prev = this.questionIndex.current - 1;
          } else {
            this.questionIndex.prev = -1;
          }
          this.currentQuestion = this.currentCategory.questions[this.questionIndex.current];
        } else {
          this.tab = this.tab - 1;
          this.categoryChange(this.tab);
        }
      } else if (dir == "next") {
        if (this.questionIndex.next) {
          this.questionIndex.prev = this.questionIndex.current;
          this.questionIndex.current = this.questionIndex.next;
          if (this.questionIndex.current < this.questionIndex.total - 1) {
            this.questionIndex.next = this.questionIndex.current + 1;
          } else {
            this.questionIndex.next = null;
          }
          this.currentQuestion = this.currentCategory.questions[this.questionIndex.current];
        } else {
          this.tab = this.tab + 1;
          this.categoryChange(this.tab);
        }
      }
    },

    backToQuestion(questionIndex, categoryIndex) {
      this.tab = categoryIndex + 1;
      if (this.questionIndex.back) {
        this.categoryChange(this.tab);
        this.questionIndex.current = questionIndex;
        this.setCurrentQuestion();
      }
    },

    setCurrentQuestion() {
      if (this.currentCategory) {
        this.currentQuestion = this.currentCategory.questions[this.questionIndex.current];
      }
    },
    startNewAudit() {
      if (this.auditType.machineRequired) {
        console.log("machine required");
        this.displayDialog = true;
      } else {
        console.log("machine is not required / already checked");
        this.createResponseForm();
      }
    },
    createResponseForm() {
      if (!this.savingAudit) {
        this.errors = false;
        this.savingAudit = true;
        const query = `mutation ($responseData: ResponseFormMutationInput!){
          responseForm(input: $responseData) {
            responseForm{
              id,
              startTime
              startUser{
                fullName
              }
              machine{
                id,
                name,
              }
            }
          }
        }`;

        let variables = {
          responseData: {
            formId: this.auditVersion.id
          }
        };
        if (this.auditType.machineRequired) {
          if (this.selectedMachine) {
            console.log("select machine");
            variables["responseData"]["machineId"] = this.selectedMachine.id;
            this.displayDialog = false;
            this.selectedMachine = null;
          } else {
            console.log("no select");
            this.$message("ALERT", {
              text: this.$t("Machine required to continue"),
              type: "error"
            });
            this.displayDialog = true;
            return;
          }
        }
        variables["responseData"]["clientMutationId"] = "responseForm";

        this.$http
          .post("graphql/", { query, variables })
          .then((res) => {
            var context = this;
            this.selectedForm = res.data.data.responseForm.responseForm;
            this.inProgressTableData.data.push(res.data.data.responseForm.responseForm);
            this.inProgressTableData.total++;
            this.responseTable.data.push(res.data.data.responseForm.responseForm);
            this.loadResponses();
            this.$message("ALERT", {
              text: this.$t("Created Audit"),
              type: "success",
              timeout: 1000
            });
            setTimeout(function () {
              context.savingAudit = false;
            }, 500);
          })
          .catch((res) => {
            this.errors = res.errors;
            this.$message("ALERT", { text: this.$t("Failed to create audit"), type: "error" });
            this.savingAudit = false;
          });
      }
    },
    resumeAudit(audit) {
      this.selectedForm = audit;
      this.loadResponses();
    },
    deselectForm() {
      this.selectedForm = null;
      this.tab = 0;
    },
    loadResponses() {
      this.errors = false;
      const query = `query ($responseForm: ID!){
        responses(responseForm: $responseForm) {
          id,
          question{
            id
          }
          text,
          comment
        }
      }`;

      let variables = { responseForm: this.selectedForm.id };
      this.$http
        .post("graphql/", { query, variables })
        .then((res) => {
          this.responses = res.data.data.responses;
          this.auditVersion.categories.forEach((category, i) => {
            category.questions.forEach((question) => {
              let resp = this.responses.find((r) => r.question.id == question.id);
              if (resp) {
                //              question.response = new Response({
                // id: resp.id,
                // text: resp.text,
                // comment: resp.comment
                // })
                question.response = new Response(resp);
              } else {
                question.response = new Response();
              }
            });
          });
          this.tab = 1;
          this.categoryChange(this.tab);
        })
        .catch((res) => {
          this.errors = res.errors;
          this.$message("ALERT", { text: "Failed to load Responses", type: "error" });
        });
    },

    updateResponse(question) {
      if (question && (question.response.text || question.response.comment)) {
        this.errors = false;
        const query = `mutation ($responseData: ResponseMutationInput!){
          updateResponse(input: $responseData) {
            response{
              id,
              pk,
              text,
              isCorrect,
              comment
            }
          }
        }`;
        let variables = {
          responseData: {
            id: question.response.id,
            responseFormId: this.selectedForm.id,
            questionId: question.id,
            text: question.response.text,
            comment: question.response.comment
          }
        };
        variables["responseData"]["clientMutationId"] = "submitResponseForm";
        this.$http
          .post("graphql/", { query, variables })
          .then((res) => {
            const pk = res.data.data.updateResponse.response.pk;

            const keys = Object.keys(this.filesByResponse);
            const categoryKey = keys.find((key) => key.startsWith("category_"));

            if (categoryKey) {
              this.filesByResponse[pk] = this.filesByResponse[categoryKey];
              delete this.filesByResponse[categoryKey];
              console.log(`File moved from ${categoryKey} to ${pk}`);
            } else {
              console.log("No temporary  key found to map");
            }

            this.$message("ALERT", { text: this.$t("Saved"), type: "success", timeout: 1000 });
          })
          .catch((res) => {
            this.errors = res.errors;
            this.$message("ALERT", { text: "Failed", type: "error" });
            this.savingAudit = false;
          });
      }
    },
    submitAudit(submit = false) {
      if (!this.savingAudit && this.selectedForm.id) {
        this.errors = false;
        this.savingAudit = true;
        const query = `mutation ($responseData: ResponseFormMutationInput!){
          responseForm(input: $responseData) {
            responseForm{
              id,
              submitTime,
              submitUser{
                fullName
              }
            }
          }
        }`;

        let variables = {
          responseData: {
            responseFormId: this.selectedForm.id,
            formId: this.auditVersion.id,
            submit: true
          }
        };
        variables["responseData"]["clientMutationId"] = "submitResponseForm";
        this.$http
          .post("graphql/", { query, variables })
          .then(async (res) => {
            var context = this;
            this.selectedForm = null;

            if (Object.keys(this.filesByResponse).length > 0) {
              console.log("Starting file upload...");
              console.log(this.filesByResponse);
              await this.uploadFiles(this.filesByResponse, "response");
            } else {
              console.log("No files to upload.");
            }

            this.filesByResponse = {};

            this.loadAudit();
            this.$message("ALERT", { text: this.$t("Audit Submitted"), type: "success" });

            setTimeout(function () {
              context.savingAudit = false;
            }, 500);
          })
          .catch((res) => {
            this.errors = res.errors;
            this.$message("ALERT", { text: "Failed", type: "error" });
            this.savingAudit = false;
          });
      }
    }
  },
  watch: {
    currentQuestion(nextQuestion, saveQuesetion) {
      this.updateResponse(saveQuesetion);
    }
  }
};
</script>
