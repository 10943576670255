<template>
  <div class="px-1 py-1">
    <p class="mx-2 mb-2">{{ $t("Machine Breakdown") }}</p>
    <bar-chart
      :chart-name="'my-chart'"
      :chart-data="chartData"
      :axis-type="axisType"
      :chart-options="chartOptions"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import BarChart, { AXISTYPE } from "@/components/charts/BarChart";

export default {
  name: "MachineBreakDownChart",
  components: { BarChart },
  props: {
    apiResponse: {
      type: Object,
      default: {},
      required: true
    },
    axisType: {
      type: String,
      default: AXISTYPE.SHORTEN
    }
  },
  computed: {
    ...mapGetters({ machineFromId: "dbCache/machineFromId" }),
    chartData() {
      if (!this.apiResponse.results) return null;
      const { results } = this.apiResponse;
      const datasets = [
        {
          label: `${this.$t("Target")}`,
          type: "scatter",
          borderColor: "rgba(68, 179, 243,1)",
          backgroundColor: "rgba(0, 0, 0, 0)",
          pointBackgroundColor: "rgba(68, 179, 243,1)",
          data: []
        },
        {
          label: `${this.$t("Actual")}}`,
          type: "bar",
          backgroundColor: "rgba(255, 200, 148, 1)",
          data: []
        }
      ];
      const xAxisLabels = [];
      results.forEach((result) => {
        xAxisLabels.push(`${this.machineFromId(result.machine_id).name}`);
        datasets[0].data.push(result.production_target);
        datasets[1].data.push(result.produced);
      });
      return {
        labels: xAxisLabels,
        datasets
      };
    },
    chartOptions() {
      return {
        tooltips: {
          titleFontSize: 14,
          bodyFontSize: 14,
          footerFontSize: 14,
          footerFontStyle: 300,
          callbacks: {
            label: (tooltipItem, data) => {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const label = dataset.label || "";
              const value = parseFloat(dataset.data[tooltipItem.index]);
              const formattedValue = value.toLocaleString();
              return `${label}: ${formattedValue}`;
            },
            footer: this.drawFooter
          }
        }
      };
    }
  }
};
</script>
