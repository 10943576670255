import { computed, getCurrentInstance } from "vue";

export const useDevelopmentEdge = () => {
  const vm = getCurrentInstance().proxy;
  const store = vm.$store;
  const devEdge = computed(() => store.getters["session/User"]?.useDevelopmentEdge);

  return {
    devEdge
  };
};
