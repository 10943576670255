const userRoles = {
  MachineDowntimeEntry: "Machine Downtime Entry",
  AuditAdmin: "Audit Admin",
  MachineAdmin: "Machine Admin",
  ApplicationAdmin: "Application Admin",
  HelpAdmin: "Help Admin",
  Supervisor: "Supervisor",
  Lighthouse: "Lighthouse",
  FormAdmin: "Form Admin",
  FormSupervisor: "Form Supervisor",
  DigitalTwin: "Digital Twin",
  FastResponseBoard: "Fast Response Board",
  GenAIPowerUser: "GenAI Power User",
  GenAIDocumentIngestion: "GenAI Document Ingestion",
  GenAITYKKnowledgeKeeper: "GenAI - TYK - Knowledge Keeper",
  GenAITYKKnowledgeReviewer: "GenAI - TYK - Knowledge Reviewer",
  GenAIGlobalSettings: "GenAI Global Settings",
  Kiosk: "Kiosk"
};

const hasUserRole = (user, userPermission) => {
  const filteredPermissions = user.roles.filter((role) => role.name === userPermission);

  return filteredPermissions.length > 0;
};

module.exports = { userRoles, hasUserRole };
