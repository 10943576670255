<template>
  <div class="upload-wrapper">
    <input
      ref="fileInput"
      type="file"
      :accept="fileInputParams.accept || acceptableFormats"
      :capture="fileInputParams.capture"
      class="d-none"
      @change="onFileChange"
    />

    <FileCard
      v-if="selectedFile && !hideFilesList"
      :file="selectedFile"
      @delete-file="deleteFile"
    />

    <v-menu
      v-if="enabledMenu"
      offset-y
      left
    >
      <template #activator="{ on, attrs }">
        <v-btn
          icon
          :disabled="disabled"
          v-bind="attrs"
          v-on="on"
        >
          <v-icon :class="[selectedFile ? 'color--text-primary' : 'color--text-tertiary']">
            mdi-paperclip
          </v-icon>
        </v-btn>
      </template>
      <v-list class="upload-menu rounded-lg">
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
          class="cursor-pointer"
          @click="handleItemClick(item)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>

        <FileUploadCameraBtn @input="onCameraFileChange" />
      </v-list>
    </v-menu>

    <v-btn
      v-else
      icon
      :disabled="disabled"
      @click="triggerFileInput"
    >
      <v-icon :class="[selectedFile ? 'color--text-primary' : 'color--text-secondary']">
        mdi-paperclip
      </v-icon>
    </v-btn>
  </div>
</template>

<script setup>
import { ref, nextTick } from "vue";
import { useIntl } from "@/shared/useIntl";
import FileUploadCameraBtn from "@/ui/FileUpload/FileUploadCameraBtn.vue";
import FileCard from "@/ui/FileCard/FileCard.vue";

defineProps({
  acceptableFormats: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  },
  enabledMenu: {
    type: Boolean,
    default: false
  },
  hideFilesList: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["select-file", "delete-file"]);

const fileInput = ref(null);
const selectedFile = ref(null);
const fileInputParams = ref({});
const { $t } = useIntl();

const triggerFileInput = () => {
  fileInput.value.click();
};

const onFileChange = (event) => {
  const file = event.target.files[0];
  if (file) {
    selectedFile.value = file;
    emit("select-file", file);
  }
};

const onCameraFileChange = (file) => {
  if (file) {
    selectedFile.value = file;
    emit("select-file", file);
  }
};

const clearFileInput = () => {
  selectedFile.value = null;
  fileInput.value.value = "";
};

const deleteFile = () => {
  clearFileInput();
  emit("delete-file");
};

const handleItemClick = (item) => {
  fileInputParams.value = item;
  nextTick(() => {
    triggerFileInput();
  });
};

const items = [
  {
    title: $t("Photos & videos"),
    capture: false,
    accept: "image/*,video/*"
  },
  {
    title: $t("Files"),
    capture: false,
    accept: "image/*,video/*,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf"
  }
];

defineExpose({
  deleteFile,
  clearFileInput
});
</script>

<style scoped lang="scss">
@import "@/scss/variables";

.upload-menu {
  min-width: rem(170px);
  border: 1px solid var(--border-secondary);
  background-color: var(--bg-secondary);
  box-shadow:
    0 4px 4px -4 var(--alpha-black-10),
    0 16px 32px -4 var(--alpha-black-20);
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .upload-wrapper {
    max-width: 80%;
  }
}
</style>
