import { useQuery } from "@tanstack/vue-query";
import { computed } from "vue";
import { assetApi } from "./assetApi";

export const useAssetQuery = (depth, treeId, lft, rgt) => {
  const query = useQuery({
    queryKey: ["asset_locations", depth, treeId, lft, rgt],
    queryFn: () => assetApi.getAssetLocations(depth.value, treeId.value, lft.value, rgt.value),
    keepPreviousData: true
  });

  const locations = computed(() => query.data.value?.data?.assetLocations);
  return { ...query, locations };
};
