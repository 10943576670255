<template>
  <v-switch
    v-model="currentTheme"
    inset
    class="switch-theme my-0 py-0 align-center"
    true-value="dark"
    false-value="light"
    hide-details
    @change="updateTheme(currentTheme)"
  >
    <template #prepend>
      <MoonFilled
        v-if="theme === 'dark'"
        class="switch-theme-icon"
      />
      <v-icon
        v-else
        class="switch-theme-icon"
        aria-hidden="true"
        >mdi-white-balance-sunny</v-icon
      >
    </template>
    <template #label>
      <span
        v-if="showDefaultLabel"
        class="text-subtitle-1 color--text-primary"
      >
        {{ currentTheme === "dark" ? $t("Theme: Dark mode") : $t("Theme: Light mode") }}
      </span>
      <span
        v-else-if="label"
        class="text-subtitle-1 color--text-primary"
      >
        {{ label }}
      </span>
    </template>
  </v-switch>
</template>

<script>
import { computed, getCurrentInstance, ref, watch } from "vue";
import { useThemeSwitcher } from "@/shared/useThemeSwitcher";
import MoonFilled from "@/components/icons/MoonFilled.vue";

export default {
  name: "ThemeSwitcher",
  components: { MoonFilled },
  props: {
    label: {
      type: String,
      default: ""
    },
    showDefaultLabel: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const store = vm.$store;
    const theme = computed(() => store.getters["app/Theme"]);
    const currentTheme = ref(theme.value);

    const { updateTheme } = useThemeSwitcher();

    watch(theme, (val) => {
      currentTheme.value = val;
    });

    return {
      theme,
      currentTheme,
      updateTheme
    };
  }
};
</script>

<style lang="scss" scoped>
@import "~@/scss/variables";

.navigation #sidenav .nav-item.nav-item-settings .switch-theme ::v-deep .v-label {
  margin-left: rem(8px) !important;
}

.switch-theme {
  position: relative;

  &::v-deep:not(.v-input--is-label-active) .v-input__prepend-outer {
    left: rem(23px);
  }

  ::v-deep {
    .v-input__prepend-outer {
      color: var(--bg-brand-contrast);
      position: absolute;
      left: rem(2px);
      margin: 0;

      svg,
      i {
        z-index: 1;
      }
    }
  }

  ::v-deep {
    .v-input--selection-controls__ripple {
      z-index: 2;
    }

    .v-input--switch__track {
      background-color: var(--bg-secondary) !important;
      opacity: 1;

      &.theme--light {
        background-color: var(--border-primary) !important;
      }
    }

    .v-input--switch__thumb {
      background-color: var(--text-white) !important;

      &.theme--light {
        background-color: var(--bg-primary) !important;
      }
    }
  }
}

.switch-theme-icon {
  color: var(--bg-brand-contrast) !important;
  margin: 0 !important;
  font-size: rem(18px) !important;

  &.theme--light {
    color: var(--status-yellow) !important;
  }
}
</style>
