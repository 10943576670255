<template>
  <div class="history">
    <div
      class="d-flex align-stretch align-sm-start justify-space-between flex-sm-row flex-column-reverse"
    >
      <div class="color--text-primary align-center">
        <span class="text-body-1 font-weight-medium">{{ name }}</span>
        {{ action }}
      </div>
      <time
        class="text-body-2 color--text-secondary font-weight-regular mb-2 mb-sm-0 flex-sm-shrink-0"
        >{{ moment(item.date).format(FULL_DATE_FORMAT) }}</time
      >
    </div>
    <component
      :is="item.DemoComponent"
      :item="item"
      class="mt-3"
    />
  </div>
</template>

<script setup>
import moment from "moment-timezone";
import { computed } from "vue";
import { FULL_DATE_FORMAT } from "../constants";

const props = defineProps({
  item: {
    type: Object,
    default: () => ({
      name: {
        type: String,
        default: ""
      },
      action: {
        type: String,
        default: ""
      },
      date: {
        type: String,
        default: ""
      },
      DemoComponent: {
        type: Object,
        default: () => {}
      }
    })
  }
});

const name = computed(() => props.item.name || props.item.userName);
const action = computed(() => props.item.action || props.item.body);

defineExpose({
  action,
  name
});
</script>

<style lang="scss" scoped>
.history {
  padding: 16px;
  background-color: var(--bg-card-white);
  border-radius: 8px;
}
</style>
