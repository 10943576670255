<template>
  <router-link
    v-slot="{ href, navigate }"
    :to="to || '/'"
    :custom="true"
    v-on="{}"
  >
    <v-tooltip
      right
      :disabled="tooltipDisabled"
      content-class="tooltip"
    >
      <template #activator="{ on }">
        <MenuItem
          :href="href"
          :active="active"
          class="mx-0 px-0"
          :icon-left="icon"
          :label="showLabel ? label : ''"
          :icon-right="hasChildren && showLabel ? 'mdi-chevron-right' : ''"
          v-on="{ ...on }"
          @click="$emit('click', { event: $event, navigate })"
        >
          <template
            v-if="customIcon"
            #prepend
          >
            <component
              :is="customIcon"
              :class="[showLabel && 'mr-2', 'menu-icon']"
            />
          </template>
        </MenuItem>
      </template>

      <span class="color--text-primary">
        {{ label }}
      </span>
    </v-tooltip>
  </router-link>
</template>

<script>
export default {
  name: "MenuItemTooltip",
  inheritAttrs: true
};
</script>
<script setup>
import MenuItem from "@/ui/Menu/MenuItem.vue";

defineProps({
  to: { type: [String, Object], default: undefined },
  label: { type: String, default: "" },
  icon: { type: String, default: "" },
  active: { type: Boolean, default: false },
  tooltipDisabled: { type: Boolean, default: false },
  showLabel: { type: Boolean, default: false },
  hasChildren: { type: Boolean, default: false },
  customIcon: { type: [Object, Function], default: undefined }
});
</script>

<style lang="scss" scoped>
@import "@/scss/variables";

.tooltip {
  border: 1px solid var(--border-primary) !important;
  background-color: var(--bg-main) !important;
  border-radius: rem(8px);
  opacity: 1;
}

.menu-icon {
  min-width: 24px;
}
</style>
