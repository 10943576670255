import { computed } from "vue";

const localStorageToggleName = "redirectToggle";

export const useViewSwitcher = () => {
  const isRedirectToggleEnabled = () => {
    if (!localStorage.getItem(localStorageToggleName)) {
      localStorage.setItem(localStorageToggleName, "true");
    }
    return computed(() => localStorage.getItem(localStorageToggleName) === "true");
  };

  const updateRedirectToggle = () => {
    const reversedToggle = !isRedirectToggleEnabled().value;
    localStorage.setItem(localStorageToggleName, reversedToggle.toString());
  };

  return {
    isRedirectToggleEnabled,
    updateRedirectToggle
  };
};
